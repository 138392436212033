import Vue from "vue";
import Vuex from "vuex";
//import user from './modules/user'
import auth from './modules/auth'
import tabs from './modules/tabs'
import gsm_gateways from './modules/gsm_gateways'
import groups from './modules/groups'
import sim_groups from './modules/sim_groups'
import dashboardGsmGatewayPortsCount from './modules/dashboardGsmGatewayPortsCount'
import dashboardDialOutGroupsCallStats from './modules/dashboardDialOutGroupsCallStats'
import dashboardGsmGatewaysCallStats from './modules/dashboardGsmGatewaysCallStats'
import dashboardSimCallStats from './modules/dashboardSimCallStats'
import dashboardCallStatsBars from './modules/dashboardCallStatsBars'
import getPortsStateActivity from './modules/getPortsStateActivity'
import originators from './modules/originators'
import sms_forward_by_http_rules from './modules/sms_forward_by_http_rules'
import telegram from './modules/telegram'
import daazweb from "./modules/daazweb";
import webcall from "./modules/webcall";
import phoneState from "@/store/modules/phoneState";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
   // user,
    auth,
    tabs,
    gsm_gateways,
    groups,
    sim_groups,
    dashboardGsmGatewayPortsCount,
    dashboardDialOutGroupsCallStats,
    dashboardGsmGatewaysCallStats,
    dashboardSimCallStats,
    dashboardCallStatsBars,
    getPortsStateActivity,
    originators,
    sms_forward_by_http_rules,
    telegram,
    daazweb,
    webcall,
    phoneState,
  },
});
