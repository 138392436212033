<template>
  <!-- @TODO need fix styles for prefix-icon="mdi-calendar-range" -->
  <el-date-picker
    v-model="datetime"
    type="datetimerange"
    :picker-options="pickerOptions"
    :range-separator="$t('text.Range_separator')"
    :start-placeholder="$t('text.Start_Date')"
    :end-placeholder="$t('text.End_Date')"
    :default-time="['00:00:00', '23:59:59']"
    align="right"
    first-day-of-week="1"
  />
</template>

<script>
    import dayjs from "dayjs";

    export default {
        name: "eDateTimePicker",
        props: {
            initialDatetime: {
                type: Array,
                required: true
            },
        },
        data: function () {
            return {
                restore: this.initialDatetime
            }
        },
        computed: {
          datetime: {
            get() {
              return this.initialDatetime;
            },
            set(v) {
              if (v === null) { // "Clean" button click handling
                v = this.restore
              }
              this.$emit('e-datetime-changed', v);
            }
          },
          pickerOptions() {
                return {
                    shortcuts: [{
                        text: this.$t('text.Last_hour'),
                        onClick(picker) {
                            const start = dayjs().startOf('hour');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_3_hours'),
                        onClick(picker) {
                            const start = dayjs().subtract(2, 'hour').startOf('hour');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_8_hours'),
                        onClick(picker) {
                            const start = dayjs().subtract(7, 'hour').startOf('hour');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_day'),
                        onClick(picker) {
                            const start = dayjs().startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_2_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(1, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_3_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(2, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_4_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(3, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_5_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(4, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_6_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(5, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_7_days'),
                        onClick(picker) {
                            const start = dayjs().subtract(6, 'day').startOf('day');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_week'),
                        onClick(picker) {
                            const start = dayjs().startOf('week');  // @TODO берётся с воскресения (что не правильно для RU), что бы это исправить, надо настроить локализаю dayjs
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_month'),
                        onClick(picker) {
                            const start = dayjs().startOf('month');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('text.Last_3_months'),
                        onClick(picker) {
                            const start = dayjs().subtract(2, 'month').startOf('month');
                            const end = dayjs();
                            picker.$emit('pick', [start, end]);
                        }
                    }],
                };
            },
        },
    }
</script>

<style scoped>

</style>