<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-select
          v-model="selectedPort"
          :items="ports"
          item-text="port"
          :label="$t('text.Port')"
          return-object
          @focus="getGsmGatewayPorts(selectedGateway)"
          @change="onPortSelected(selectedPort)"
        >
          // HTML that describe how select should render selected items
          <template 
            v-slot:selection="data"
          >
            <v-chip
              :color="setPortStatusColor(data.item['port_gsm_status'], data.item['sim_balance'])"
              dark
            >
              {{ data.item.port }}. {{ preparePortDescription(data.item['sim_num'], data.item['sim_operator_name'], data.item['sim_balance'], data.item['sim_balance_currency']) }}
            </v-chip>
          </template>
          // HTML that describe how select should render items when the select is open
          <template
            v-slot:item="data"
          >
            <v-chip
              :color="setPortStatusColor(data.item['port_gsm_status'], data.item['sim_balance'])"
              dark
            >
              {{ data.item.port }}. {{ preparePortDescription(data.item['sim_num'], data.item['sim_operator_name'], data.item['sim_balance'], data.item['sim_balance_currency']) }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>  
</template>

<script>
  import mGsmGateways from "../mixins/customer/mGsmGateways";

export default {
  name: 'GetGsmGatewayPorts',
  mixins: [ mGsmGateways ],
  data: function () {
    return {
      ports: [],
      selectedPort: null,
      selectedSim: null,
      selectedGateway: null,
      noSim: this.$t('text.SIM_card_not_installed'),
      porLogin: "LOGIN"
    }
  },
  mounted () {
    this.$bus.on('selected-gateway', this.onGatewaySelected)
  },
  methods: {
    onPortSelected (port) {
      this.$bus.emit('selected-gateway-port', port['port'], port['sim_imsi']);
    },
    onGatewaySelected (gateway) {
      this.selectedPort = null;
      this.selectedSim = null;
      this.selectedGateway = gateway;
      this.getGsmGatewayPorts(gateway)
    },
    getGsmGatewayPorts (gateway) {
      this.getCustomerGsmGatewayPorts(gateway)
      .then(ports => {
        this.ports = ports
      }).catch(e => {
        this.ports =  []
      });
    },
    preparePortDescription (simNumber, brand, balance, currency) {
      let description = this.noSim;

      if (brand !== undefined && brand !== "")
        description = brand;

      if (simNumber !== undefined && simNumber !== "")
        description += ": " + simNumber;

      if (balance !== undefined && balance !== "" && currency !== undefined && currency !== "")
          description += " (" + balance + " " + currency + ")";
      return description
    },
    setPortStatusColor (gsm_status, balance) {
      if (gsm_status === this.porLogin)
        if (balance !== undefined && balance !== "")
          if (balance < 0.5) return 'red';
          else if (balance <= 1) return 'orange';
          else return 'green';
        else return 'green';
      else return 'gray' // port status LOGOUT
    }
  }
}
</script>

<style scoped>

</style>
