<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-select
          v-model="selectedGateway"
          :items="gateways"
          :item-text="itemText"
          item-value="username"
          :label="$t('text.GSM_Gateway')"
          @change="onGatewaySelected(selectedGateway)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import mGsmGateways from "../mixins/customer/mGsmGateways";

export default {
  name: 'GetGsmGateways',
  mixins: [ mGsmGateways ],
  data: function () {
    return {
      gateways: [],
      selectedGateway: null
    }
  },
  mounted () {
    this.getCustomerGsmGateways()
    .then(gateways => {
      this.gateways = gateways;
      if (gateways.length > 0) {
        this.selectedGateway = gateways[0]['username'];
        this.onGatewaySelected(this.selectedGateway)
      }
    }).catch(e => {
      this.gateways = [];
      this.selectedGateway = null;
    });
  },
  methods: {
    onGatewaySelected: function (value) {
      // this.$emit('selected-gateway', value)
      this.$bus.emit('selected-gateway', value)
      // console.log('GetGoipGatewaysWitchActivePorts selected-gateway: ', value)
    },
    itemText(item) {
      return item.username + " (" + item.alias + ")"
    }
  }
}
</script>

<style scoped>

</style>
