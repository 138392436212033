
import { apiCall } from '../../components/api/v1/calls';

const state = {
  status: 'loading',
  data: [],
};

const getters = {
};

const actions = {
  load: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/getGsmGatewayPortsCount', method: 'GET', data: null})
        .then(resp => {
          commit('status', 'success');
          console.log('getGsmGatewayPortsCount - load() - resp:', resp);
          commit('data', resp.data.result);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
};

const mutations = {
  reset: (state) => {
    state.status = 'loading';
    state.data = [];
  },
  status: (state, payload) => {
    state.status = payload;
  },
  data: (state, payload) => {
    state.data = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
