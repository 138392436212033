
import { apiCall } from '../../components/api/v1/calls';

const DATETIME = [ new Date().setHours(0,0,0,0), new Date() ];

const state = {
  status: 'loading',
  datetime: DATETIME,
  data: [],
};

const getters = {
};

const actions = {
  load: function ({state, commit}, gateways) {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      // @TODO check correct timezone
      const start_datetime = this._vm.$dayjs(state.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
      const end_datetime = this._vm.$dayjs(state.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');

      apiCall({
        endpoint: '/customer/getCallStats', method: 'POST', data: {
          direction: 'outgoing',
          start_datetime,
          end_datetime,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          gsm_gateways: gateways,
        },
      })
        .then(resp => {
          commit('status', 'success');
          console.log('getCallStats - load() - resp:', resp);
          commit('data', resp.data.result);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
};

const mutations = {
  reset: (state) => {
    state.status = 'loading';
    state.datetime = DATETIME;
    state.data = [];
  },
  status: (state, payload) => {
    state.status = payload;
  },
  setDatetime: (state, payload) => {
    state.datetime = payload;
  },
  data: (state, payload) => {
    state.data = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
