import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMaterial from 'vue-material';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';

import './plugins/dayjs';
import './plugins/tippy';
import {i18n} from "./plugins/i18n";

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/element-ui.css';
import './assets/styles/custom.css';

// for notifications
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";// Import the CSS

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false;

Vue.use(Toast, {
  closeOnClick: false,
  draggable: false,
  rtl: false,
  pauseOnFocusLoss: true,
}); // https://github.com/Maronato/vue-toastification, good demo here https://maronato.github.io/vue-toastification/

Vue.use(Vuetify);
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(VueMaterial);

export const bus = new Vue({
  router,
  store,
  i18n: i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
