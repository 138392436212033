<template>
  <span>
    <v-icon
        :small="small"
        :large="large"
        :color="setSignalColor(signal, sim_imsi)"
        :size="size">
      {{ setSignalIcon(setSignalColor(signal, sim_imsi)) }}
    </v-icon>
  </span>
</template>

<script>
    export default {
        name: "GsmPortSignal",
        props: {
            signal: Number,
            sim_imsi: String,
            small: Boolean,
            large: Boolean,
            size: Number,
        },
        methods: {
            setSignalColor(signal, imsi) {
                let color = 'gray';
                if (imsi) {
                  if (signal > 12 && signal <= 98) {
                    color = 'green'
                  } else if (signal > 9 && signal <= 12) {
                    color = 'orange'
                  } else if ((signal >= 1 && signal <= 9) || (signal === 0 || signal === 99)) {   // signal can be 0 or 99 when port contains sim
                    color = 'red'
                  }
                }
                return color
            },
            setSignalIcon(color) {
                if (color === 'green') {
                    return 'mdi-signal-cellular-3'
                } else if (color === 'orange') {
                    return 'mdi-signal-cellular-2'
                } else if (color === 'red') {
                    return 'mdi-signal-cellular-1'
                }
                return 'mdi-signal-cellular-outline'
          },
        }
    }
</script>

<style scoped>

</style>