<template>
  <v-menu v-if="selectedLocale" bottom left close-on-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon :key="selectedLocale.flag">{{ selectedLocale.flag }}</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item
          v-for="(item, i) in languages"
          :key="i"
          @click="setLocale(item.language)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.flag }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { ENGLISH_DATEPICKER_LOCALE } from '../../translations/datepickerLocales/en';
  import { RUSSIAN_DATEPICKER_LOCALE } from '../../translations/datepickerLocales/ru';
  import { mapGetters } from 'vuex';

  export default {
    name: 'AppBarLang',
    data: () => ({
      languages: [
        { flag: '$en', language: 'en', title: 'English' },
        { flag: '$ru', language: 'ru', title: 'Русский' }
      ],
      selectedLocale: null,
    }),
    mounted () {
      this.setDefaultLocale()
    },
    // eslint-disable-next-line vue/order-in-components
    computed: {
      ...mapGetters(['isAuthenticated']),
    },
    methods: {
      setDefaultLocale() {
        let locale = localStorage.getItem("locale");
        // set default locale if localStorage locale invalid or not set
        if (!this.languages.find(obj => obj.language === locale)) {
          locale = "en"
        }

        this.$root.$i18n.locale = locale;
        if (locale === "en") {
          this.$material.locale = ENGLISH_DATEPICKER_LOCALE;
        } else if (locale === "ru") {
          this.$material.locale = RUSSIAN_DATEPICKER_LOCALE;
        }
        // returns the first value in the array, if an element in the array satisfies the provided testing function. Otherwise undefined is returned.
        this.selectedLocale = this.languages.find(obj => obj.language === locale);
        localStorage.setItem("locale", locale);
      },
      setLocale(locale) {
        this.$root.$i18n.locale = locale;
        if (locale === "en") {
          this.$material.locale = ENGLISH_DATEPICKER_LOCALE;
        } else if (locale === "ru") {
          this.$material.locale = RUSSIAN_DATEPICKER_LOCALE;
        }
        this.selectedLocale = this.languages.find(obj => obj.language === locale);
        localStorage.setItem("locale", locale);
      },
    }
  }
</script>
