<template>
  <v-layout
    row
    wrap
    align-center
  >
    <v-card
      class="mb-3"
      width="100%"
    >
      <v-card-text :style="{ padding: `16px`, paddingBottom: `0px` }">
        <v-row>
          <v-icon
            x-large
            :style="{ alignItems: `end`, paddingLeft: `16px`}"
          >
            mdi-access-point-network
          </v-icon>
          <v-col
            cols="6"
            sm="6"
            :style="{ padding: `16px`, paddingBottom: `0px`, paddingTop: `0px` }"
          >
            <v-select
              v-model="selected"
              :label="$t('text.GSM_Gateways_Select')"
              :items="data"
              item-text="gateway.alias"
              return-object
              multiple
              chips
              deletable-chips
              clearable
              open-on-clear
              hide-details
              success
              item-color="green"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="hideGsmLogoutPorts"
              color="success"
              hide-details
              :label="$t('text.psa_hide_gsm_logout_ports')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col>
            <div
              v-for="(g, index) in selected"
              :key="g.gateway.username + index"
            >
              <div class="subtitle-1 mb-2">
                <v-icon medium>
                  mdi-router-wireless-settings
                </v-icon >
                <span :style="{ verticalAlign: `middle` }">
                  <span :style="{ fontWeight: `bold` }">
                    {{ g.gateway.alias }}
                  </span>
                   ({{ g.gateway.username}})
                </span>


                <!-- @TODO доделать
                    1) сделать галочку - по умолчанию показывать только активные шлюзы (настройку можно хранить в куках),
                    2) Выводить цифрами общее количество шлюзов и количество не активных шлюзов (где-нибудь на верху)
                    3) добавить тултипы индикаторам SIM VOIP SMS SMB
                -->
                <!-- GSM Gateway network status -->
                <span class="gsm-gateway-indicators-icon">
                  <GwNetStatusIndicator v-bind="{ netStatus: g.gateway['net_status'] }" />
                </span>
                <!-- GSM Gateway VOIP registration status -->
                <span class="gsm-gateway-indicators-icon">
                  <GwVoipRegStatusIndicator v-bind="{ netStatus: g.gateway['net_status'], voipRegStatus: g.gateway['voip_reg_status'] }" />
                </span>
                <!-- GSM Gateway VOIP ping status -->
                <span class="gsm-gateway-indicators-icon">
                  <GwVoipPingStatusIndicator v-bind="{ voipRegStatus: g.gateway['voip_reg_status'], voipPingStatus: g.gateway['voip_ping_status'] }" />
                </span>
              </div>
              <transition-group
                v-if="g.ports && Array.isArray(g.ports) && g.ports.length > 0 && g.gateway['net_status'] === 'REACHABLE'"
                class="b-items mb-4"
                name="b-animate-group"
                tag="div"
              >
                <div
                  v-for="(item) in g.ports"
                  :key="g.gateway.username + item.port_index"
                  class="b-item"
                >
                  <GsmPort
                    :item="item"
                    show-voip-state
                  />
                </div>
              </transition-group>
              <div
                v-else
                class="overline mb-2"
              >
                {{ $t('text.NO_AVAILABLE_PORTS') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions} from 'vuex';
  import GsmPort from './elements/GsmPort';
  import GwNetStatusIndicator from './elements/GsmGateways/NetStatusIndicator';
  import GwVoipRegStatusIndicator from './elements/GsmGateways/VoipRegStatusIndicator';
  import GwVoipPingStatusIndicator from './elements/GsmGateways/VoipPingStatusIndicator';

  export default {
    name: 'PortsStateActivity',
    components: {GsmPort, GwNetStatusIndicator, GwVoipRegStatusIndicator, GwVoipPingStatusIndicator},
    props: {isVisible: Boolean},
    data: () => ({
      open: true,
      timer: null,
      selected: [],
      data: [],
      headers: [
        {
          text: 'GSM Gateway',
          value: 'gateway.username',
          sortable: false,
        },
        {
          text: 'VOIP REG',
          value: 'gateway.voip_reg_status',
          sortable: false,
        },
        {
          text: 'VOIP PING',
          value: 'gateway.voip_ping_status',
          sortable: false,
        },
        {
          text: 'NET STATUS',
          value: 'gateway.net_status',
          sortable: false,
        },
      ],
      tableOptions: {
        sortBy: 'date',
        sortDesc: true,
      },
      hideGsmLogoutPorts: false,
    }),
    watch: {
      isVisible() {
        this.init();
      },
    },
    created() {
      this.init();
    },
    beforeDestroy() {
      this.clear();
    },
    methods: {
      ...mapActions('getPortsStateActivity', [
        'load',
      ]),
      async init() {
        this.clear();

        if (!this.isVisible) {
          return false;
        }

        const response = await this.load();
        this.$set(this, 'data', response.data.result);

        this.selected = this.data;
        this.timer = setInterval(async () => {
          const response = await this.load();
          let data = response.data.result
              .filter(g => {
                for (const s of this.selected) {
                  if (s.gateway.username === g.gateway.username) {
                    // filter by port gsm status
                    if (this.hideGsmLogoutPorts) {
                      g.ports = g.ports.filter(
                          p => {
                            if (p.port_gsm_status === 'LOGIN' || !this.hideGsmLogoutPorts) {
                              return true;
                            }
                          }
                      )
                    }
                    return true;
                  }
                }
              });
          this.$set(this, 'selected', data);
        }, 2000);
      },
      clear() {
        clearInterval(this.timer);
      },
    },
  };
</script>

<style>
  .gsm-gateway-indicators-icon {
    padding: 4px;
  }
</style>
