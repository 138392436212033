// for localization
import Vue from "vue";
import VueI18n from 'vue-i18n';
import { ENGLISH_TRANSLATIONS } from '../translations/en';
import { RUSSIAN_TRANSLATIONS } from '../translations/ru';
import ElementEn from "element-ui/lib/locale/lang/en";
import ElementRu from "element-ui/lib/locale/lang/ru-RU";

Vue.use(VueI18n);

const TRANSLATIONS = {
    en: {
        ...ENGLISH_TRANSLATIONS,
        ...ElementEn,
    },
    ru: {
        ...RUSSIAN_TRANSLATIONS,
        ...ElementRu,
    }
};

// Create VueI18n instance with options
export const i18n = new VueI18n({
    locale: 'en',
    messages: TRANSLATIONS,
});