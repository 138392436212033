
const state = {
  sim: {
    ICCID: '',
    Name: '',
  },
  showPhone: false,
};

const getters = {
  getSimId: state => {
    return state.sim.ICCID
  },
  getSimName: state => {
    return state.sim.Name
  },
  getShowPhone: state => {
    return state.showPhone
  }
};

const actions = {
  chooseSim (context, item) {
    const payload = {ICCID: item.iccid, Name: item.number}
      context.commit("setSim", payload);
  },
  switchShowPhone (context) {
      context.commit("switchShowPhone");
  },
};

const mutations = {
  setSim: (state, sim) => {
    state.sim = sim;
  },
  switchShowPhone: (state) => {
    state.showPhone = !state.showPhone;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
