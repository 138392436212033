export const RUSSIAN_DATEPICKER_LOCALE = {
    // range for datepicker
    startYear: 1900,
    endYear: 2099,
// date format for date picker
    dateFormat: "yyyy-MM-dd",
// i18n strings
    days: [
        "Суббота",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Воскресение"
    ],
    shortDays: ["Сбт.", "Пон.", "Втр.", "Ср.", "Чтв.", "Пт.", "Вскр."],
    shorterDays: ["Сб.", "Пн.", "Вт.", "Ср.", "Чт.", "Пт.", "Вс."],
    months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сетябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
    ],
    shortMonths: [
        "Янв.",
        "Фев.",
        "Мрт.",
        "Апр.",
        "Май.",
        "Июн",
        "Июл",
        "Авг.",
        "Сен.",
        "Окт.",
        "Нояб.",
        "Дек."
    ],
    shorterMonths: [
        "Янв.",
        "Фев.",
        "Мрт.",
        "Апр.",
        "Май.",
        "Июн",
        "Июл",
        "Авг.",
        "Сен.",
        "Окт.",
        "Нояб.",
        "Дек."
    ],
// `0` stand for Sunday, `1` stand for Monday
    firstDayOfAWeek: 1
};