<template>
    <v-layout
       row
       wrap
    >
        <v-card
                class="mb-3"
                width="100%"
        >
            <v-data-table
                    :headers="columns"
                    :items="groups"
                    item-key="name"
                    :items-per-page="14"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                            color="white"
                    >
                        <v-toolbar-title>
                            {{ $t('text.sim_groups') }}
                        </v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        />
                        <v-spacer/>
                        <v-dialog
                                v-model="dialogNew"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-on="on"
                                >
                                    {{ $t('text.New_group') }}
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title>
                  <span class="headline">
                    {{ $t('text.New_group') }}
                  </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                        :label="$t('text.Description')"
                                                        v-model="newDescription"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeNew"
                                    >
                                        {{ $t('text.Cancel') }}
                                    </v-btn>

                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveNew"
                                    >
                                        {{ $t('text.Send') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    {{ simGroupStatus(item['status']) }}
                </template>

                <template v-slot:[`item.schedule_type`]="{ item }">
                    <template v-if="item['schedule_type'] === 'daily' && item['schedule_daily_period'] !== undefined && item['schedule_daily_period'] !== ''">
                        {{ simGroupScheduleMode(item['schedule_type']) }} ({{ simGroupDailyPeriod(item['schedule_daily_period']) }})
                    </template>
                    <template v-else>
                        {{ simGroupScheduleMode(item['schedule_type']) }}
                    </template>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                            icon
                            class="mr-2"
                            @click="showItem(item)"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                            icon
                            @click="showDialogDelete(item)"
                    >
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
                :value="dialogGroup"
                @input="setDialogGroup"
                fullscreen
                transition="dialog-bottom-transition"
        >
            <GsmGroup/>
        </v-dialog>

        <v-dialog
                v-model="dialogDelete"
                max-width="500px"
        >
            <v-card v-if="deleteObject">
                <v-card-title>
          <span class="headline">
            {{ $t('text.Delete') }} "{{deleteObject.description}}"?
          </span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogDelete = false"
                    >
                        {{ $t('text.Cancel') }}
                    </v-btn>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(deleteObject.code)"
                    >
                        {{ $t('text.Delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: "SimGroups",
        data: () => ({
            dialogNew: false,
            newDescription: null,
            dialogDelete: false,
            deleteObject: null,
        }),
        async created() {
            await this.getSimGroups();
        },
        computed: {
            ...mapState('sim_groups', {
                groups: state => Array.isArray(state.sim_groups) ? state.sim_groups : [],
                dialogGroup: state => state.dialogGroup,
            }),
            columns() {
                return [
                    {text: this.$t('text.sim_group_name'), value: 'name', align: 'left', sortable: true},
                    {text: this.$t('text.sim_group_status'), value: 'status', align: 'center', sortable: false},
                    {text: this.$t('text.sim_group_schedule_mode'), value: 'schedule_type', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_schedule_daily_period'), value: 'schedule_daily_period', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_work_interval'), value: 'work_interval', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_sleep_interval'), value: 'sleep_interval', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_imei_type'), value: 'imei_type', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_imei_random'), value: 'imei_random', align: 'center', sortable: false},
                    // {text: this.$t('text.sim_group_next_time'), value: 'next_time', align: 'center', sortable: false},
                    {text: this.$t('text.Actions'), value: 'actions', align: 'center', width: "10%", sortable: false},
                ];
            },
        },
        methods: {
            ...mapActions('sim_groups', [
                'getSimGroups',
                'newSimGroup',
                'setSimGroup',
                'delSimGroup',
            ]),
            ...mapMutations('sim_groups', [
                'setDialogGroup',
            ]),
            closeNew() {
                this.dialogNew = false;
                this.newDescription = null;
            },
            async saveNew() {
                await this.addGroup({
                    description: this.newDescription,
                });
                this.closeNew();
                await this.getGroups();
            },
            showItem(item) {
                this.setSelectedGroup(item.code);
                this.setDialogGroup(true);
            },
            showDialogDelete(item) {
                this.dialogDelete = true;
                this.deleteObject = item;
            },
            simGroupStatus(status) {
                if (status === "working") {
                    return this.$t('text.sim_group_active');
                }
                return this.$t('text.sim_group_inactive');
            },
            simGroupScheduleMode(mode) {
                if (mode === "cycle" || mode === "daily") {
                    return this.$t('text.' + mode);
                } else if (mode === "period_chaos") {
                    return this.$t('text.schedule')
                }
                return this.$t('text.Unknown');
            },
            simGroupDailyPeriod(period) {
                return period.replace(";", "; ") + " UTC"
            },
            async deleteItem(code) {
                await this.deleteGroup({
                    code,
                });
                this.dialogDelete = false;
                this.deleteObject = null;
                await this.getGroups();
            },
        },
    };
</script>
