<template>
  <v-card
      width="100%"
      outlined
  >
    <v-card-subtitle>
      GsmGatewaysCallStats

      <v-row>
        <v-col cols="12" md="auto">
          <div>
            <el-date-picker
                v-model="datetime"
                type="datetimerange"
                :picker-options="pickerOptions"
                :range-separator="$t('text.Range_separator')"
                :start-placeholder="$t('text.Start_Date')"
                :end-placeholder="$t('text.End_Date')"
                align="left"
                firstDayOfWeek="1"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        :loading="status === 'loading'"
        dense
    >
      <template v-slot:[`item.code`]="{ item }">
        <div class="_no-wrap">{{item.code}} ({{item.description}})</div>
      </template>
      <template v-slot:[`item.acd`]="{ item }">
        <div class="_no-wrap">{{item.call_stats.asr}}/{{item.call_stats.acd}}/{{item.call_stats.audio_quality}}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    data: () => ({
      headers: [
        {
          text: 'GSM Gateway',
          value: 'username',
          sortable: false,
        },
        {
          text: 'ASR/ACD/AQ',
          value: 'acd',
          sortable: false,
        },
        {
          text: 'Total calls',
          value: 'call_stats.calls_total',
          sortable: false,
        },
        {
          text: 'Answered calls',
          value: 'call_stats.calls_answered',
          sortable: false,
        },
        {
          text: 'Billmin',
          value: 'call_stats.billmin',
          sortable: false,
        },
      ],
    }),
    created() {
      this.reset();
      this.load();
    },
    computed: {
      ...mapState('dashboardGsmGatewaysCallStats', {
        status: state => state.status,
        _datetime: state => state.datetime,
        data: state => state.data,
      }),
      pickerOptions() {
        return {
          shortcuts: [{
            text: this.$t('text.Last_hour'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_3_hours'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 3);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_8_hours'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 8);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_12_hours'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 12);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_day'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_2_days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_3_days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_4_days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 4);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_5_days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 5);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_6_days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_week'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_month'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30); // @TODO нет гарантий что в месяце 30 дней, должно быть исправлено
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('text.Last_3_months'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90); // @TODO нет гарантий что в месяце 30 дней, должно быть исправлено
              picker.$emit('pick', [start, end]);
            }
          }],
        };
      },
      datetime: {
        get: function () {
          return this._datetime;
        },
        set: function (newValue) {
          this.setDatetime(newValue);
          this.load();
        }
      },
    },
    methods: {
      ...mapMutations('dashboardGsmGatewaysCallStats', [
        'reset',
        'setDatetime',
      ]),
      ...mapActions('dashboardGsmGatewaysCallStats', [
        'load',
      ]),
    }
  };
</script>
