<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
    :style="containerHeightStyle"
  >

      <div class="dt-right-top-picker">
        <v-row>
          <v-col cols="12">
            <div class="b-date-picker-outer">
              <eDateTimePicker
                :initial-datetime="datetime"
                @e-datetime-changed="changeDatetime"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider />

      <v-card
        class="pt-4"
        width="100%"
        outlined
      >
        <!--
        <v-card-title>
          {{ $t('text.Outgoing_calls_analytics') }}
        </v-card-title>
        -->
        <v-row>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="410"
              :options="chartOptions1"
              :series="chartSeries1"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="410"
              :options="chartOptions2"
              :series="chartSeries2"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="360"
              type="line"
              :options="chartOptions3"
              :series="chartSeries3"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="360"
              type="line"
              :options="chartOptions4"
              :series="chartSeries4"
            />
          </v-col>
        </v-row>
      </v-card>

  </v-layout>
</template>

<script>
    import dayjs from "dayjs"; // @TODO понять как использовать dayjs в computed property без импорта в данном компоненте
    import height from '../../mixins/height';
    import eDateTimePicker from '../filters/eDateTimePicker';
    import mCallsStats from "../../mixins/stats/mCallsStats";

    export default {
        name: "SimCharts",
        components: { eDateTimePicker },
        mixins: [ height, mCallsStats ],
        data: vm => ({
            loading: false,
            chartSeries1: [
                {
                    data: [],
                    type: 'column',
                    name: vm.$t('text.answered_calls'),
                },
                {
                    data: [],
                    type: 'column',
                    name: vm.$t('text.unanswered_calls'),
                },
            ],
            chartSeries2: [
                {
                    data: [],
                    type: 'column',
                    name: vm.$t('text.minutes_billed'),
                },
            ],
            chartSeries3: [],
            chartSeries4: [],
            chartOptions1: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 0,
                    },
                    offsetX: 0,
                    offsetY: 20,
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#00BF32', '#A2ABA8'],
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom',
                        },
                    },
                },
                subtitle: {
                    text: vm.$t('text.total_calls_grouped_by_sim_cards'),
                    align: 'center',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 15,
                },
                xaxis: {
                    type: 'category',
                    categories: [],
                    crosshairs: {
                        show: false,
                        width: 0,
                    }
                },
                legend: {
                    position: 'bottom',
                    onItemClick: {
                        toggleDataSeries: true
                    },
                },
                fill: {
                    opacity: 1
                },
                grid: {
                    padding: {
                        top: 0,
                        right: 14,
                        bottom: 0,
                        left: 0
                    }
                },
                noData: {
                    text: vm.$t('text.Please_wait'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  followCursor: true,
                  x: {
                    //  format: 'yyyy-MM-dd' @TODO should contains SIM info
                  }
                }
            },
            chartOptions2: {
                chart: {
                    type: 'bar',
                    stacked: false,
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 0,
                    },
                    offsetX: 0,
                    offsetY: 20,
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#4C59D8'],
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        horizontal: true,
                        dataLabels: {
                            position: 'center',
                        },
                    },
                },
                subtitle: {
                    text: vm.$t('text.total_billed_minutes_grouped_sim_cards'),
                    align: 'center',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 15,
                },
                xaxis: {
                    type: 'category',
                    categories: [],
                    crosshairs: {
                        show: false,
                        width: 0,
                    }
                },
                legend: {
                    position: 'bottom',
                    showForSingleSeries: true,
                    onItemClick: {
                        toggleDataSeries: true
                    },
                },
                fill: {
                    opacity: 1
                },
                grid: {
                    padding: {
                        top: 0,
                        right: 20,
                        bottom: 0,
                        left: 0
                    }
                },
                noData: {
                    text: vm.$t('text.Please_wait'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  followCursor: true,
                  x: {
                    //  format: 'yyyy-MM-dd' @TODO should contains SIM info
                  }
                }
            },
            chartOptions3: {
              chart: {
                type: 'line',
                zoom: {
                  enabled: false
                },
                animations: {
                  enabled: false
                },
                toolbar: {
                  show: false,
                },
                offsetX: 0,
                offsetY: 20,
              },
              grid: {
                padding: {
                  top: 0,
                  right: 20,
                  bottom: 0,
                  left: 0
                }
              },
              stroke: {
                width: 2,
                curve: 'straight'
              },
              labels: [],
              subtitle: {
                text: vm.$t('text.dynamic_of_asr_on_sim_cards'),
                align: 'center',
                margin: 0,
                offsetX: 0,
                offsetY: 15,
              },
              xaxis: {
                labels: {
                  hideOverlappingLabels: true,
                  formatter: function (value, timestamp, opts) {
                    return dayjs(value).format('D MMM')
                  }
                }
              },
              yaxis: {
                max: 100
              },
              noData: {
                text: vm.$t('text.Please_wait'),
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: '14px',
                  fontFamily: undefined
                }
              },
            },
            chartOptions4: {
            chart: {
              type: 'line',
              zoom: {
                enabled: false
              },
              animations: {
                enabled: false
              },
              toolbar: {
                show: false,
              },
              offsetX: 0,
              offsetY: 20,
            },
            grid: {
                padding: {
                  top: 0,
                  right: 20,
                  bottom: 0,
                  left: 0
                }
            },
            stroke: {
              width: 2,
              curve: 'straight'
            },
            labels: [],
            subtitle: {
              text: vm.$t('text.dynamic_of_acd_on_sim_cards'),
              align: 'center',
              margin: 0,
              offsetX: 0,
              offsetY: 15,
            },
            xaxis: {
              labels: {
                hideOverlappingLabels: true,
                formatter: function (value, timestamp, opts) {
                  return dayjs(value).format('D MMM')
                }
              }
            },
            yaxis: {},
            noData: {
              text: vm.$t('text.Please_wait'),
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
          },
            filter: {
                searchByNumberOrImsi: '',
                operators: [],
                countries: [],
            },
            datetimeDefault: [ dayjs().subtract(6, 'day').startOf('day'), dayjs() ], // default is one week day from 00:00
            datetime: [ dayjs().subtract(6, 'day').startOf('day'), dayjs() ], // default is one week day from 00:00
        }),
        created() {
            this.getStats();
        },
        methods: {
            changeDatetime(dtime) {
                this.datetime = dtime;
                this.getStats()
            },
            getSimCardsAsrAcd(cards, interval) {
              // cleanup data in chart 3
              this.chartSeries3.length = 0;
              this.chartOptions3 = {
                ...this.chartOptions3,
                labels: []
              };
              // cleanup data in chart 4
              this.chartSeries4.length = 0;
              this.chartOptions4 = {
                ...this.chartOptions4,
                labels: []
              };

              for (const sim_imsi of cards) {
                // datetime from/to validation
                if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
                  this.datetime = this.datetimeDefault
                }
                const start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
                const end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');
                // console.log(`start time ${start_datetime} end time ${end_datetime}`)
                this.getCallsStatsGroupByInterval('outgoing', start_datetime, end_datetime, interval, false,
                        sim_imsi,
                        undefined,
                        undefined,
                        undefined,
                        undefined
                )
                .then(stats => {
                  if (Array.isArray(stats)) {
                      const dates = [];
                      const asr = [];
                      const acd = [];

                      for (const i of stats) {
                        dates.push(i.date);
                        if (i.calls_total > 0) {
                          asr.push(((i.calls_answered/i.calls_total)*100).toFixed(0))
                        } else {
                          asr.push(null)
                        }

                        if (i.calls_total > 0) {
                          acd.push((i.billsec/i.calls_answered).toFixed(0))
                        } else {
                          acd.push(null)
                        }
                      }

                      this.chartSeries3.push({
                        name: sim_imsi,
                        data: asr
                      });

                      this.chartSeries4.push({
                        name: sim_imsi,
                        data: acd
                      });

                      if (this.chartOptions3.labels.length === 0) {
                        this.chartOptions3 = {
                          ...this.chartOptions3,
                          labels: dates
                        };
                      }
                      if (this.chartOptions4.labels.length === 0) {
                        this.chartOptions4 = {
                          ...this.chartOptions4,
                          labels: dates
                        };
                      }
                    }
                })
                .catch(e => {
                  // console.log(e);
                })
              }
             },
            async getStats() {
                this.loading = true;
                // datetime from/to validation
                if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
                    this.datetime = this.datetimeDefault
                }
                const start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
                const end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');

                this.getCallStatsGroupBySim('outgoing', start_datetime, end_datetime, false, this.filter)
                .then(stats => {
                    if (Array.isArray(stats)) {

                        stats.sort(function(a, b){
                            return b.call_stats.calls_answered - a.call_stats.calls_answered;
                        });

                        const cards = [];
                        const answered = [];
                        const failed = [];
                        const billmin = [];

                        for (const i of stats) {
                            cards.push(i.imsi);
                            answered.push(i.call_stats.calls_answered);
                            failed.push(i.call_stats.calls_total - i.call_stats.calls_answered);
                            billmin.push(i.call_stats.billmin);
                        }

                        this.chartSeries1[0].data = answered;
                        this.chartSeries1[1].data = failed;
                        this.chartSeries2[0].data = billmin.sort(function(a, b){
                            return b - a;
                        });

                        this.chartOptions1 = {
                            ...this.chartOptions1,
                            xaxis: {
                                categories: cards
                            },
                        };
                        this.chartOptions2 = {
                            ...this.chartOptions2,
                            xaxis: {
                                categories: cards
                            },
                        };

                        this.getSimCardsAsrAcd(cards, 'day')
                    }
                })
                .catch(e => {
                    // nothings to do
                })
                .finally(() => this.loading = false);
            },
        }
    }
</script>

<style scoped>
  .b-graph {
    padding: 0px;
    padding-left: 14px;
    padding-right: 14px;
  }
</style>