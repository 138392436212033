import {apiCall} from "../components/api/v1/calls";

export default {
    name: "LockItem",
    methods: {
        lockItem (resource, state, sim_imsi, gsm_group, gsm_gateway, gsm_gateway_port) {
            let payload = {
                resource_type: resource,
                lock_state: state,
            };
            if (sim_imsi !== undefined && sim_imsi !== "") {
                payload.sim_imsi = sim_imsi
            } else if (gsm_group !== undefined && gsm_group !== 0 && gsm_group !== "") {
                if (typeof gsm_group === 'string') {
                    payload.gsm_group = parseInt(gsm_group, 10);
                } else {
                    payload.gsm_group = gsm_group;
                }
            } else if (gsm_gateway !== undefined && gsm_gateway !== "") {
                payload.gsm_gateway = gsm_gateway;
                if (gsm_gateway_port !== undefined && gsm_gateway_port !== 0 && gsm_gateway_port !== "") {
                    if (typeof gsm_gateway_port === 'string') {
                        payload.gsm_gateway_port = parseInt(gsm_gateway_port, 10);
                    } else {
                        payload.gsm_gateway_port = gsm_gateway_port;
                    }
                }
            }
            return apiCall({endpoint: '/customer/item/lock', method: 'POST', data: payload})
        }
    }
}