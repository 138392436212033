<template>
  <svg-icon-loader 
    :icon-path="loadIcon"
    :width="calcWidth"
    :height="calcHeight"
    :fill="fill"
    :stroke="stroke"
    :generate-ready-event="generateReadyEvent"
    @ready="$emit('svg-icon-ready')"
  />
</template>

<script>
    import {operators} from "../../data/operators";
    import SvgIconLoader from '../../components/svg/SvgIconLoader'

    export default {
        name: "MobileOperatorIcon",
        components: { SvgIconLoader },
        props: {
            // Имя файла (без расширения)
            iconName: {
                type: String,
                required: true
            },
            
            // Ширина
            width: {
                type: [Number, String],
                default: 32,
            },

            // Высота
            height: {
                type: [Number, String],
                default: 32,
            },

            // Цвет заливки
            fill: {
                type: String,
                default: 'currentColor'
            },

            // Цвет обводки
            stroke: {
                type: String,
                default: 'none'
            },

            // Генерировать ли событие ready иконки
            generateReadyEvent: {
                type: Boolean,
                default: false
            },

            // Использовать компактный вариант иконки
            compact: {
              type: Boolean,
              default: false
            }
        },
        computed: {
          loadIcon() {
            const operatorObject = operators[this.iconName.toLowerCase().replace(/[\s&]/g, '_')];
            if (!operatorObject) {
              return "unknown"; // unknown.svg
            }
            if (this.compact) {
              return operatorObject.icon + "_compact";
            }
            return operatorObject.icon
          },
          calcWidth() {
            if (!this.compact) {
              let icon = operators[this.iconName.toLowerCase().replace(/[\s&]/g, '_')];
              if (icon && icon.long) {
                return this.width * 1.2
              }
            }
            return this.width
          },
          calcHeight() {
            if (!this.compact) {
              let icon = operators[this.iconName.toLowerCase().replace(/[\s&]/g, '_')];
              if (icon && icon.long) {
                return this.height * 1.2
              }
            }
            return this.height
          }
      }
    }
</script>

<style scoped>

</style>