<template>
  <v-btn
    :loading="loading"
    @click="action"
    :disabled="disabled"
  >
    {{ context.text }}
  </v-btn>
</template>

<script>
import UssdCommands from '../../../mixins/UssdCommands';

export default {
  name: 'SimsUssdCommandButton',
  mixins: [UssdCommands],
  props: {
    sims: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
    },
    command: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    context() {
      let result = {};
      switch(this.command) {
        case 'balance':
          result = {
            text: this.$t('text.SIM_check_balance'),
            command_type: 'sim_balance',
            prop_name: 'balance',
          };
          break;
        case 'number':
          result = {
            text: this.$t('text.SIM_extract_number'),
            command_type: 'sim_number',
            prop_name: 'sim_number',
          };
          break;
      }
      return result;
    },
  },
  methods: {
    async action() {
      const sims = this.sims.filter((sim) => sim.gsm_login === 'LOGIN');
      const imsis = sims.map(s => s.imsi);
      this.$emit('ussd-command-lock', imsis);

      this.loading = true;
      const { command_type, prop_name } = this.context;
      const results = await Promise.all(sims.map(async (sim) => {
        let commands = [];
        let notify = {
          sim_imsi: sim.imsi,
          error(text) {
            this.text = text;
            this.failure = true;
          },
          success(text) {
            this.text = text;
          },
        };
        await this.getSimUssdCommands(sim['imsi'], command_type)
          .then(response => {
            console.log('response: ', response.data);

            if (response.data.result !== undefined && response.data.result.length > 0) {
              commands = response.data.result;
            } else {
              notify.error(this.$t('text.Operation_not_supported') + ' (' + this.$t('text.USSD_command_must_be_assigned') + ')');
            }
          })
          .catch(e => {
            notify.error(this.$t('text.Cannot_execute_ussd_command'));
          });

        if (commands.length > 0) {
          // commands[0] - берём самый первый элемент, потому что:
          // 1) Если customer переопределит ussd команду для данного типа запроса, то в commands[0] будет его команда
          // 2) Иначе, там будет ussd команда установленная сервисом по умолчанию
          await this.sendUssdCommand(sim['gsm_gateway'], sim['gsm_port'], sim['imsi'], commands[0], command_type)
            .then(response => {
              // console.log('response: ', response.data);
              // API вернёт массив строк (ответов на USSD-команды), т.к. в случае когда выполняется переход по USSD-меню,
              // на каждый такой переход мы получим USSD-ответ или ошибку выполнения USSD-команды
              if (response.data.result !== undefined && response.data.result['ussd_response'] !== undefined && Array.isArray(response.data.result['ussd_response'])) {
                if (response.data.result.error) {
                  notify.error(response.data.result['ussd_response']);
                } else {
                  if (response.data.result[prop_name]) {
                    notify[prop_name] = response.data.result[prop_name];
                  }
                  notify.success(response.data.result['ussd_response']);
                }
              } else {
                notify.error(this.$t('text.Cannot_get_ussd_command_response'));
              }
            })
            .catch(e => {
              notify.error(this.$t('text.Cannot_execute_ussd_command'));
            });
        }
        this.$emit('ussd-command-response', notify);
      }));

      this.$emit('ussd-command-unlock', imsis);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.extract-sim-balance-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}
</style>
