<template>
  <span v-if="!loading">
    <v-tooltip
      open-delay="800"
      close-delay="800"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :color="color"
          size="18"
          :small="small"
          v-bind="attrs"
          :large="large"
          v-on="on"
          @click="action(sim)"
        >
          {{ icon(sim['number']) }}
        </v-icon>
      </template>
      <span> {{ $t('text.SIM_extract_number') }} </span>
    </v-tooltip>
  </span>
  <span
    v-else
    :style="{'position': 'relative'}"
  >
    <v-progress-circular
      indeterminate
      color="green"
      size="17"
    />
  </span>
</template>

<script>
  import UssdCommands from "../../../mixins/UssdCommands";

    export default {
        name: "ExtractSimNumberButton",
        mixins: [ UssdCommands ],
        props: {
          sim: {
            type: Object,
            default: function () {
              return undefined
            }
          },
          small: {
            type: Boolean,
          },
          large: {
            type: Boolean,
          },
          locked: {
            type: Boolean
          }
        },
        data () {
          return {
            loading: false,
          }
        },
        computed: {
          color() {
           if (!this.locked) {
              return 'green'
            }
           return 'gray'
          }
       },
        methods: {
          icon(number) {
            if (number) {
              return "mdi-sync"
            }
            // @TODO icon size should be fixed
            // return "mdi-help-circle-outline"
            return "mdi-sync"
          },
          async action() {
            if (!this.locked) {
              this.$emit('ussd-command-lock', this.sim['imsi']);

              this.loading = true;
              let command_type = "sim_number";
              let commands = [];
              let notify = {
                sim_imsi: this.sim['imsi'],
                error(text) {
                  this.text = text;
                  this.failure = true
                },
                success(text) {
                  this.text = text;
                }
              };
              await this.getSimUssdCommands(this.sim['imsi'], command_type)
              .then(response => {
                //console.log('response: ', response.data);
                if (response.data.result !== undefined && response.data.result.length > 0) {
                  commands = response.data.result;
                } else {
                  notify.error(this.$t('text.Operation_not_supported') + ' (' + this.$t('text.USSD_command_must_be_assigned') + ')');
                }
              })
              .catch(e => {
                notify.error(this.$t('text.Cannot_execute_ussd_command'));
              });

              if (commands.length > 0) {
                // commands[0] - берём самый первый элемент, потому что:
                // 1) Если customer переопределит ussd команду для данного типа запроса, то в commands[0] будет его команда
                // 2) Иначе, там будет ussd команда установленная сервисом по умолчанию
                await this.sendUssdCommand(this.sim['gsm_gateway'], this.sim['gsm_port'], this.sim['imsi'], commands[0], command_type)
                .then(response => {
                  console.log('response: ', response.data);
                  // console.log('response: ', response.data);
                  // API вернёт массив строк (ответов на USSD-команды), т.к. в случае когда выполняется переход по USSD-меню,
                  // на каждый такой переход мы получим USSD-ответ или ошибку выполнения USSD-команды
                  if (response.data.result !== undefined && response.data.result['ussd_response'] !== undefined && Array.isArray(response.data.result['ussd_response'])) {
                    if (response.data.result.error) {
                      notify.error(response.data.result['ussd_response']);
                    } else {
                      if (response.data.result.sim_number) {
                        notify.sim_number = response.data.result.sim_number
                      }
                      notify.success(response.data.result['ussd_response']);
                    }
                  } else {
                    notify.error(this.$t('text.Cannot_get_ussd_command_response'));
                  }
                })
                .catch(e => {
                  notify.error(this.$t('text.Cannot_execute_ussd_command'));
                });
              }
              this.$emit('ussd-command-response', notify);
              this.$emit('ussd-command-unlock', this.sim['imsi']);
              this.loading = false;
            }
          }
        }
    }
</script>

<style scoped>
  .extract-sim-number-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
  }
</style>
