import {apiCall} from "../../components/api/v1/calls";
import directive from "element-ui/packages/popover/src/directive";

export default {
    name: "mCallsStats",
    methods: {
        getCallStats (call_direction, start_datetime, end_datetime, gsm_gateways, dial_out_groups) {
            return new Promise(function(resolve, reject) {
                let req = {
                    direction: call_direction,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    start_datetime: start_datetime,
                    end_datetime: end_datetime,
                };

                if (gsm_gateways !== undefined && Array.isArray(gsm_gateways) && gsm_gateways.length > 0) {
                    req.gsm_gateways = gsm_gateways
                }

                if (dial_out_groups !== undefined && Array.isArray(dial_out_groups) && dial_out_groups.length > 0) {
                    req.dial_out_groups = dial_out_groups
                }

                apiCall({endpoint: '/customer/geCallStats', method: 'POST', data: req})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot get calls stats: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot get calls stats: ", e);
                    reject(e)
                });
            })
        },
        getCallsStatsGroupByInterval (call_direction, start_datetime, end_datetime, interval, with_quality,
                                      filter_by_sim_imsi, filter_by_sim_group, filter_by_sim_mobile_operator, filter_by_gsm_gateway, filter_by_originator_prefix) {
            return new Promise(function(resolve, reject) {
                let req = {
                    direction: call_direction,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    start_datetime: start_datetime,
                    end_datetime: end_datetime,
                    interval: interval,
                    with_quality: with_quality,
                };
                if (filter_by_sim_imsi) {
                    req.filter_by_sim_imsi = filter_by_sim_imsi
                }
                if (filter_by_sim_group) {
                    req.filter_by_sim_group = filter_by_sim_group
                }
                if (filter_by_sim_mobile_operator) {
                    req.filter_by_sim_mobile_operator = filter_by_sim_mobile_operator
                }
                if (filter_by_gsm_gateway) {
                    req.filter_by_gsm_gateway = filter_by_gsm_gateway
                }

                if (filter_by_originator_prefix) {
                    req.filter_by_originator_prefix = filter_by_originator_prefix
                }

                apiCall({endpoint: '/customer/getCallStatsBars', method: 'POST', data: req})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot get sim calls stats for interval: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot get sim calls stats for interval: ", e);
                    reject(e)
                });
            })
        },
        getCallStatsGroupBySim (call_direction, start_datetime, end_datetime, hidden, filter) {
            return new Promise(function(resolve, reject) {
                let req = {
                    direction: call_direction,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    start_datetime: start_datetime,
                    end_datetime: end_datetime,
                    mobile_operators: [],
                    countries: [],
                    hidden: hidden, // filter sim cards by visibility
                };

                if (filter !== undefined) {
                    // filter by mobile operators
                    if (filter.operators !== undefined && Array.isArray(filter.operators) && filter.operators.length > 0) {
                        req.mobile_operators = filter.operators
                    }
                    // filter by countries
                    if (filter.countries !== undefined && Array.isArray(filter.countries) && filter.countries.length > 0) {
                        req.countries = filter.countries
                    }
                }
                apiCall({endpoint: '/customer/getSimCallStats', method: 'POST', data: req})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot get sim calls stats: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot get sim calls stats: ", e);
                    reject(e)
                });
            })
        },
    }
}