import { apiCall } from '../../components/api/v1/calls';

const state = {
    status: null,
    user: {},
};

const getters = {
    reqStatus: state => state.status,
    WebCallUser: state => { return state.user },
};

const actions = {
    apiGetWebCallUser: ({commit}) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            let url = '/customer/WebCallUser';

            apiCall({endpoint: url, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {
                    if (resp.data.result !== null) {
                        commit('SET_USER', resp.data.result);
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get webcall user: result is null");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot get webcall user: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot get webcall user");
                commit('status', 'error');
                reject(err)
            });
        })
    },

};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
