import { render, staticRenderFns } from "./GsmPort.vue?vue&type=template&id=bc63ba3a&scoped=true&"
import script from "./GsmPort.vue?vue&type=script&lang=js&"
export * from "./GsmPort.vue?vue&type=script&lang=js&"
import style0 from "./GsmPort.vue?vue&type=style&index=0&id=bc63ba3a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc63ba3a",
  null
  
)

export default component.exports