<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
    :style="containerHeightStyle"
  >

      <div class="dt-right-top-picker">
        <v-row>
          <v-col cols="12">
            <div class="b-date-picker-outer">
              <eDateTimePicker
                :initial-datetime="datetime"
                @e-datetime-changed="changeDatetime"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider />

      <v-card
        class="pt-4"
        width="100%"
        outlined
      >
        <!--
        <v-card-title>
          {{ $t('text.Outgoing_calls_analytics') }}
        </v-card-title>
        -->
        <v-row>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="380"
              :options="chartOptions1"
              :series="chartSeries1"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="380"
              :options="chartOptions2"
              :series="chartSeries2"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="390"
              :options="chartOptions3"
              :series="chartSeries3"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="390"
              :options="chartOptions4"
              :series="chartSeries4"
            />
          </v-col>
        </v-row>
      </v-card>

  </v-layout>
</template>

<script>
    import dayjs from "dayjs"; // @TODO понять как использовать dayjs в computed property без импорта в данном компоненте
    import height from '../../mixins/height';
    import eDateTimePicker from '../filters/eDateTimePicker';
    import mCallsStats from "../../mixins/stats/mCallsStats";

    export default {
        name: "ChartBars",
        components: { eDateTimePicker },
        mixins: [ height, mCallsStats ],
        data: vm => ({
          loading: false,
          chartSeries1: [
            {
              data: [],
              type: 'column',
              name: vm.$t('text.answered_calls'),
            },
            {
              data: [],
              type: 'column',
              name: vm.$t('text.unanswered_calls'),
            },
          ],
          chartSeries2: [
            {
              data: [],
              type: 'column',
              name: vm.$t('text.minutes_billed'),
            },
          ],
          chartSeries3: [
            {
              data: [],
              name: vm.$t('text.total_calls'),
            }
          ],
          chartSeries4: [
            {
              data: [],
              type: 'column',
              name: vm.$t('text.low_quality_calls'),
            },
            {
              data: [],
              type: 'column',
              name: vm.$t('text.medium_quality_calls'),
            },
            {
              data: [],
              type: 'column',
              name: vm.$t('text.high_quality_calls'),
            },
          ],
          /*
             Я много эксперементировал с форматом представления даты для series на грфиках потому что хотел привести их
             к виду 1 Окт, 2 Окт, 3 Окт, и я сталкнулся с ледующими проблемами:
             1) при использовании типа даты datetime в xaxis я столкнулся вот с этим багом
                https://github.com/apexcharts/apexcharts.js/issues/1378 Date display issue for 1st Jannuary on x-axis
                который якобы был исправлен в версии 3.17.1, но при этом воспроизводится на нашей текущей версии 3.21.0
             2) при использовании типа даты datetime в xaxis наблюдается баг с смещением тени столбца в право относительно
                самого столбца, это возникает на графиках типа bar

             Тогда я решил использовать тип даты category и привести series к нужному виду используя dayjs
             и xaxis->lables->formatter как-то так
                formatter: function(value, timestamp, opts) {
                  return dayjs(value).locale('en').format('D MMM')
                }
             Минусы этого решения:
             а) При выборках на интервале в 3 месяца series накладываются друг на дурга и становятся не читабельными,
                формат даты вида 2020-10-07 в таких случаях выглядит адекватнее (не кретично, спасает tooltip)
             б) 3й график - area с почасовой агрегацией вызовов в режиме zoom терят по часовое отображение series (спасает tooltip)
             @TODO доделать локализацию lables для series, использовать файлы локализации от dayjs https://day.js.org/docs/en/i18n/i18n
             @TODO 3й график - area с почасовой агрегацией вызовов, убрать второй tooltip который отображается на оси X
             @TODO 4й график - bar с агрегацией по месяцам отрабатывает не корректно в случае когда мы задаём интервал вручную
             потому что это идёт в обход datetimePicker shortcuts
             @TODO рабочая область графика (таблица) имеет смщение в лево, из-за этого же бага легенда графиков тоже имеет не корректное смешение
           */
          chartOptions1: {
            chart: {
              type: 'bar',
              stacked: true,
              animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
              },
              dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 0,
              },
              toolbar: {
                show: false,
              },
              offsetX: 0,
              offsetY: 20,
            },
            colors: ['#00BF32', '#A2ABA8'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            subtitle: {
              text: vm.$t('text.total_calls_count_grouped_by_days'),
              align: 'center',
              margin: 0,
              offsetX: 0,
              offsetY: 15,
            },
            xaxis: {
              type: 'category',
              categories: [],
              labels: {
                hideOverlappingLabels: true,
                formatter: function(value, timestamp, opts) {
                  return dayjs(value).format('D MMM')
                }
              },
              crosshairs: {
                show: false,
                width: 0,
              }
            },
            legend: {
              position: 'bottom',
              onItemClick: {
                toggleDataSeries: true
              },
            },
            fill: {
              opacity: 1
            },
            grid: {
              padding: {
                top: 0,
                right: 14,
                bottom: 0,
                left: 0
              }
            },
            noData: {
              text: vm.$t('text.Please_wait'),
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              followCursor: true,
              x: {
                format: 'yyyy-MM-dd'
              }
            }
          },
          chartOptions2: {
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false
              },
              animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
              },
              dropShadow: {
                enabled: true,
              },
              offsetX: 0,
              offsetY: 20,
            },
            colors: ['#4C59D8'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            subtitle: {
              text: vm.$t('text.total_billed_minutes_grouped_by_days'),
              align: 'center',
              margin: 0,
              offsetX: 0,
              offsetY: 15,
            },
            xaxis: {
              type: 'category',
              categories: [],
              labels: {
                hideOverlappingLabels: true,
                formatter: function(value, timestamp, opts) {
                  return dayjs(value).format('D MMM')
                }
              },
              crosshairs: {
                show: false,
                width: 0,
              }
            },
            legend: {
              position: 'bottom',
              showForSingleSeries: true,
              onItemClick: {
                toggleDataSeries: true,
              },
            },
            fill: {
              opacity: 1
            },
            grid: {
              padding: {
                top: 0,
                right: 14,
                bottom: 0,
                left: 0
              }
            },
            noData: {
              text: vm.$t('text.Please_wait'),
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              followCursor: true, // @TODO from browser console: crosshairs.width = "barWidth" is only supported in single series, not in a multi-series barChart.
              x: {
                format: 'yyyy-MM-dd'
              }
            }
          },
          chartOptions3: {
            chart: {
              type: 'area',
              dropShadow: {
                enabled: true,
                top: 0,
                opacity: 0.25
              },
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: -10,
                tools: {
                  download: false,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                },
              },
              offsetX: 0,
              offsetY: 20,
              zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {
              enabled: false
            },
            colors: ['#00a9ff'],
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,

              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            subtitle: {
              text: vm.$t('text.total_calls_grouped_by_hours'),
              align: 'center',
              margin: 0,
              offsetX: 0,
              offsetY: 15,
            },
            xaxis: {
              type: 'datetime',
              categories: [],
              labels: {
                hideOverlappingLabels: true,
                format: 'd MMM',
              }
            },
            legend: {
              position: 'bottom',
              onItemClick: {
                toggleDataSeries: true
              },
            },
            grid: {
              padding: {
                top: 0,
                right: 30,
                bottom: 0,
                left: 0
              }
            },
            noData: {
              text: vm.$t('text.Please_wait'),
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
            stroke: {
              curve: 'smooth',
              width: 3,
            },
            tooltip: {
              shared: true,
              intersect: false,
              x: {
                format: 'yyyy-MM-dd HH:00'
              }
            }
          },
          chartOptions4: {
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false
              },
              animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
              },
              dropShadow: {
                enabled: false,
              },
              offsetX: 0,
              offsetY: 20,
            },
            colors: ['#FF5C00', '#ffb840', '#37DD6F'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            subtitle: {
              text: vm.$t('text.audio_quality_of_calls_grouped_by_days'),
              align: 'center',
              margin: 0,
              offsetX: 0,
              offsetY: 15,
            },
            xaxis: {
              type: 'category',
              categories: [],
              labels: {
                hideOverlappingLabels: true,
                formatter: function(value, timestamp, opts) {
                  return dayjs(value).format('D MMM')
                }
                /* for the by month grouping
                hideOverlappingLabels: true,
                formatter: function(value, timestamp, opts) {
                  return dayjs(value).format('MMMM')
                }
                */
                /*
                formatter: function(value, timestamp, opts) {
                 return value.toString().split(' ')[0]
                }
                */
              },
              crosshairs: {
                show: false,
                width: 0,
              }
            },
            legend: {
              position: 'bottom',
              showForSingleSeries: true,
              onItemClick: {
                toggleDataSeries: true,
              },
              offsetX: 0,
              offsetY: 5,
            },
            fill: {
              opacity: 1
            },
            grid: {
              padding: {
                top: 0,
                right: 14,
                bottom: 0,
                left: 0
              }
            },
            noData: {
              text: vm.$t('text.Please_wait'),
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              followCursor: true, // @TODO from browser console: crosshairs.width = "barWidth" is only supported in single series, not in a multi-series barChart.
              x: {
                format: 'yyyy-MM'
              }
            }
          },
          datetimeDefault: [ dayjs().subtract(6, 'day').startOf('day'), dayjs() ], // default is one week day from 00:00
          datetime: [ dayjs().subtract(6, 'day').startOf('day'), dayjs() ], // default is one week day from 00:00
        }),
        created() {
           this.getStats();
        },
         methods: {
          changeDatetime(dtime) {
             this.datetime = dtime;
             this.getStats()
          },
          getStats() {
            this.getCallsStats('day');
            this.getCallsStats('hour');
            // this.getCallsStats('month');
          },
          getCallsStats(interval) {
            this.loading = true;
            // datetime from/to validation
            if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
              this.datetime = this.datetimeDefault
            }
            const start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
            const end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');
            // console.log(`start time ${start_datetime} end time ${end_datetime}`)
            this.getCallsStatsGroupByInterval('outgoing', start_datetime, end_datetime, interval, true,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined)
            .then(stats => {
              if (Array.isArray(stats)) {
                if (interval === 'day') {
                  const dates = [];
                  const answered = [];
                  const failed = [];
                  const billmin = [];
                  const calls_low_aq = [];
                  const calls_medium_aq = [];
                  const calls_high_aq = [];

                  for (const i of stats) {
                    dates.push(i.date);
                    answered.push(i.calls_answered);
                    failed.push(i.calls_total - i.calls_answered);
                    billmin.push(i.billmin);
                    calls_low_aq.push(i.low_quality_calls_count);
                    calls_medium_aq.push(i.medium_quality_calls_count);
                    calls_high_aq.push(i.calls_total - (i.low_quality_calls_count + i.medium_quality_calls_count));
                  }

                  this.chartSeries1[0].data = answered;
                  this.chartSeries1[1].data = failed;
                  this.chartSeries2[0].data = billmin;
                  this.chartSeries4[0].data = calls_low_aq;
                  this.chartSeries4[1].data = calls_medium_aq;
                  this.chartSeries4[2].data = calls_high_aq;

                  this.chartOptions1 = {
                    ...this.chartOptions1,
                    xaxis: {
                      categories: dates
                    },
                  };
                  this.chartOptions2 = {
                    ...this.chartOptions2,
                    xaxis: {
                      categories: dates
                    },
                  };
                  this.chartOptions4 = {
                    ...this.chartOptions4,
                    xaxis: {
                      categories: dates
                    },
                  };
                } else if (interval === 'hour' ) {
                  const dates = [];
                  const total = [];
                  const asr = [];
                  const acd = [];

                  for (const i of stats) {
                    dates.push(i.date);
                    total.push(i.calls_total);
                    asr.push(i.asr);
                    acd.push(i.acd);
                  }


                  this.chartSeries3[0].data = total;
                  //this.chartSeries3[1].data = asr;
                  //this.chartSeries3[2].data = acd;

                  this.chartOptions3 = {
                    ...this.chartOptions3,
                    xaxis: {
                      categories: dates
                    },
                  };
                /*
                } else if (interval === 'month' ) {
                  const dates = [];
                  const billmin = [];

                  for (const i of response.data.result) {
                    dates.push(i.date);
                    billmin.push(i.billmin);
                  }

                  this.chartSeries4[0].data = billmin;

                  this.chartOptions4 = {
                    ...this.chartOptions4,
                    xaxis: {
                      categories: dates
                    },
                  };
                 */
                } else {
                  console.log("cannot get stats: unsupported interval")
                }
              }
            })
            .catch(e => {
               // console.log(e);
            })
            .finally(() => this.loading = false);
          }
      },
    }
</script>

<style scoped>
  .b-graph {
    padding: 0px;
    padding-left: 14px;
    padding-right: 14px;
  }
</style>
