<template>
    <v-tooltip
            open-delay="800"
            close-delay="800"
            top
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    class="mr-2"
                    :color="color()"
                    :large="large"
                    :small="small"
                    :size="size"
                    v-bind="attrs"
                    v-on="on"
            >
                {{ icon() }}
            </v-icon>
        </template>
        <span> {{ tooltip() }} </span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "SimBankLoginIndicator",
        props: {
            login_status: {
                type: Number,
            },
            large: {
                type: Boolean
            },
            small: {
                type: Boolean
            },
            size: {
                type: Number,
            }
        },
        methods: {
            color() {
                if (this.login_status === 11) {
                    return "success"
                } else if (this.login_status === 12) {
                    return "gray"
                }
                return "gray"
            },
            icon() {
                if (this.login_status === 11) {
                    return "mdi-sim"
                }
                return "mdi-sim-off"
            },
            tooltip() {
                switch (this.login_status) {
                    case 11:
                        return this.$t('text.slot_status_online');
                    case 12:
                        return this.$t('text.slot_status_disabled');
                    case 13:
                        return this.$t('text.slot_status_idle');
                    case 14:
                        return this.$t('text.slot_status_busy');
                    default: //  0
                        return this.$t('text.slot_status_offline');
                }
            }
        }
    }
</script>

<style scoped>

</style>