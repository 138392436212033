import { getField, updateField } from 'vuex-map-fields';

export const state = {
  tab: null,
};

export const getters = {
  getField,
};

export const mutations = {
  updateField,
};

export const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
