<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
    :style="containerHeightStyle"
  >
    <div class="b-fixed-height">
      <CallDetailsRecordsFilters
        v-model="data"
        @start="loading = true"
        @end="loading = false"
      />
      <v-divider/>
      <!-- data table -->
      <div
        ref="fixHeight"
        class="b-fixed-height__table-outer"
      >
        <v-data-table
          :key="itemsPerPage"
          :headers="columns"
          :items="data"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            'items-per-page-options': [itemsPerPageOption, -1]
          }"
          :loading="loading"
          :loading-text="$t('text.Loading_Please_wait')"

          item-key="id"
          show-expand
          single-expand
          :expanded.sync="expandedItems"
          @item-expanded="setAudioFileUrl"
        >
          <template v-slot:[`expanded-item`]="{ headers, item }">
            <td :colspan="headers.length">
              <div class="row">
                <div :style="{'width': '100%'}">
                  <audio-player :file="item['audio_file_url']" color="success" flat downloadable autoPlay >

                  </audio-player>

                </div>
              </div>
            </td>
          </template>

          <template v-slot:[`header.caller_id_number`]="{ header }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ header['text'] }}
            </div>
          </template>
          <template v-slot:[`header.destination_number`]="{ header }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ header['text'] }}
            </div>
          </template>

          <template v-slot:[`header.hangup_disposition`]="{ header }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ header['text'] }}
            </div>
          </template>

          <template v-slot:[`header.gsm_gateway`]="{ header }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ header['text'] }}
            </div>
          </template>

          <template v-slot:[`item.start_stamp`]="{ item }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ item['start_stamp'] }}
            </div>
          </template>

          <template v-slot:[`item.gsm_gateway`]="{ item }">
            <span v-if="typeof item['gsm_gateway'] !== 'undefined' && item['gsm_gateway'] !== ''" :style="{ 'white-space': 'nowrap' }">
                {{ getGatewayAlias(item['gsm_gateway']) }} ({{ item['gsm_port'] }})
            </span>
          </template>

          <template v-slot:[`item.hangup_disposition`]="{ item }">
            <span :style="{ 'white-space': 'nowrap' }">
                {{ getHangupDisposition(item['hangup_disposition']) }}
            </span>
          </template>
          <!-- @TODO в фильтрах тоже придётся менять тогда
          <template v-slot:item.hangup_cause="{ item }">
            <span v-if="typeof item['hangup_cause'] !== 'undefined' && item['hangup_cause'] !== ''" :style="{ 'white-space': 'nowrap' }">
                {{ prepareCause(item['hangup_cause']) }}
            </span>
          </template>
          -->
          <template v-slot:[`tem.sim_imsi`]="{ item }">
            <div class="text-center">
              <v-chip
                color="blue"
                text-color="default"
                :style="{ paddingLeft: `4px` , paddingRight: `8px`}"
                pill
                outlined
              >
                <MobileOperatorIcon
                  v-show="isReady"
                  :icon-name="item['mobile_operator']"
                  :width="22"
                  :height="22"
                  compact
                  generate-ready-event
                  @svg-icon-ready="ready"
                />
                <span :style="{ paddingLeft: `4px`, paddingRight: `4px` }">
                  {{ item['sim_imsi'] }}
                </span>
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.sim_asr`]="{ item }">
            <span v-if="typeof item['sim_asr'] !== 'undefined' && item['sim_asr'] !== ''">
              <v-progress-circular
                :rotate="90"
                :size="48"
                :width="4"
                :value="item['sim_asr']"
                :color="getAsrColor(item['sim_asr'])"
              >
                {{ item['sim_asr'] }}
              </v-progress-circular>
            </span>
          </template>

          <template v-slot:[`item.audio_quality`]="{ item }">
            <span v-if="typeof item['audio_quality'] !== 'undefined' && item['audio_quality'] !== ''">
              <v-progress-circular
                :rotate="90"
                :size="48"
                :width="4"
                :value="item['audio_quality']"
                :color="getAQColor(item['audio_quality'])"
              >
                {{ item['audio_quality'] }}
              </v-progress-circular>
            </span>
          </template>

          <template v-slot:[`item.dialout_group_code`]="{ item }">
            <span v-if="typeof item['dialout_group_code'] !== 'undefined' && parseInt(item['dialout_group_code']) !== 0">
                {{ item['dialout_group_code'] }}
            </span>
          </template>

          <template v-slot:[`item.dialout_group_capacity`]="{ item }">
            <span v-if="typeof item['dialout_group_capacity'] !== 'undefined' && item['call_direction'] !== 'incoming'">
                {{ item['dialout_group_capacity'] }}
            </span>
          </template>
          <!-- Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '0px'}">
              <v-list-item-action :style="{ marginRight: '1px' }">
                <DownloadButton
                    v-bind="{ domain: item['domain_name'], filepath: item['record_filename'], size: 18 }"
                />
              </v-list-item-action>
            </v-list-item>
          </template>

        </v-data-table>
      </div>
    </div>
  </v-layout>
</template>

<script>
  import height from '../mixins/height';
  import { causes } from "../data/sipHangupCauses";
  import MobileOperatorIcon from './elements/MobileOperatorIcon';
  import CallDetailsRecordsFilters from './filters/CallDetailsRecordsFilters';
  import AudioPlayer from "./audio/AudioPlayer";
  import {mapActions, mapState} from "vuex";
  import DownloadButton from "./elements/buttons/DownloadButton";

  export default {
    name: "CallDetailsRecords",
    components: {
      AudioPlayer,
      CallDetailsRecordsFilters,
      MobileOperatorIcon,
      DownloadButton,
    },
    mixins: [
      height,
    ],
    data() {
      return {
        data: [],
        expandedItems: [],
        loading: true,
        isReady: false
      }
    },
    computed: {
      ...mapState("gsm_gateways", {
        gateways: state => state.gateways,
      }),
      columns() {
        return [
          // { text: this.$t('text.caller_id_name') + `:`, value: 'caller_id_name', align: 'center', sortable: true },
          { text: this.$t('text.Call_Direction') + `:`, value: 'call_direction', align: 'center', sortable: false },
          { text: this.$t('text.Caller_Number') + `:`, value: 'caller_id_number', align: 'center', sortable: false },
          { text: this.$t('text.Destination_Number') + `:`, value: 'destination_number', align: 'center', sortable: false },
          { text: this.$t('text.Start_Stamp') + `:`, value: 'start_stamp', align: 'center', sortable: false },
          // { text: this.$t('text.End_Stamp') + `:`, value: 'end_stamp', align: 'center', sortable: true },
          { text: this.$t('text.Duration') + `:`, value: 'duration', align: 'center', sortable: false },
          { text: this.$t('text.Billsec') + `:`, value: 'billsec', align: 'center', sortable: false },
          // { text: this.$t('text.Progress_Media_sec') + `:`, value: 'progress_mediasec', align: 'center', sortable: true },
          { text: this.$t('text.Call_Status') + `:`, value: 'hangup_cause', align: 'center', sortable: false },
          { text: this.$t('text.sip_bye_from') + `:`, value: 'hangup_disposition', align: 'center', sortable: false },
          { text: this.$t('text.GSM_Gateway_and_port') + `:`, value: 'gsm_gateway', align: 'center', sortable: false },
          // { text: this.$t('text.Port') + `:`, value: 'gsm_port', align: 'center', sortable: false },
          { text: this.$t('text.SIM') + `:`, value: 'sim_imsi', align: 'center', sortable: false },
          // { text: this.$t('text.sim_mobile_operator') + `:`, value: 'mobile_operator', align: 'center', sortable: false },
          { text: this.$t('text.ASR') + `:`, value: 'sim_asr', align: 'center', sortable: false },
          { text: this.$t('text.AQ') + `:`, value: 'audio_quality', align: 'center', sortable: false },
          { text: this.$t('text.SIM_Group') + `:`, value: 'dialout_group_code', align: 'center', sortable: false },
          { text: this.$t('text.SIM_Group_Capacity') + `:`, value: 'dialout_group_capacity', align: 'center', sortable: false },
          { text: this.$t('text.Actions'), value: 'actions', align: 'center', filterable: false, sortable: false }
        ]},
    },
    mounted () {
      this.getGsmGateways()
    },
    methods: {
      ...mapActions("gsm_gateways", ["getGsmGateways"]),
      getAsrColor (asr) {
        if (asr === 0) return 'gray';
        else if (asr > 0 && asr <= 30) return 'red';
        else if (asr > 30 && asr <= 50) return 'orange';
        else return 'green'
      },
      getAQColor (aq) {
        if (aq === 0) return 'gray';
        else if (aq > 0 && aq <= 65) return 'red';
        else if (aq > 65 && aq <= 79) return 'orange';
        else return 'green'
      },
      //prepareCause (cause) {
      //  let c = causes[cause];
      //  if (c) {
      //    return c.short
      //  }
      //  return cause
      //},
      getGatewayAlias(username) {
        let index = this.gateways.findIndex(gw => gw.username === username);
        if (index !== -1) {
          return this.gateways[index].alias
        }
        return username
      },
      getHangupDisposition(disposition) {
        /*
        if (disposition === "recv_bye") {
          return this.$t('text.B')
        } else if (disposition === "send_bye") {
          return this.$t('text.A')
        } else if (disposition === "recv_refuse") {
          return this.$t('text.B_Refuse')
        } else if (disposition === "send_refuse") {
          return this.$t('text.Refuse')
        } else if (disposition === "send_cancel") {
          return this.$t('text.A_Cancel')
        }
        */
        if (disposition === "recv_bye" || disposition === "recv_refuse") {
          return this.$t('text.B')
        } else if (disposition === "send_bye"|| disposition === "send_cancel") {
          return this.$t('text.A')
        } else if (disposition === "send_refuse") { // PBX?
          return this.$t('text.A')
        }
        return "n/a"
      },
      setAudioFileUrl({item}) {
        if (item['record_filename'] !== '') {
          this.$set(item, 'audio_file_url', process.env.VUE_APP_API_URL + '/audiorecords/' + item['domain_name'] + "/" + item['record_filename']);
          let s = item['record_filename'].split("/");
          this.$set(item, 'audio_file_name', s[s.length -1]); // set audio file name
        }
      },
      ready() {
        this.isReady = true
      }
    },
  }
</script>

<style scoped lang="scss">
  .v-application ul, .v-application ol {
    padding: 8px;
    padding-top: 0px;
  }
</style>
