<template>
    <v-layout
            :key="$root.$i18n.locale"
            row
            wrap
            align-center
    >
        <v-card width="100%">
            <v-tabs
                    v-model="tab"
            >
                <v-tab
                        v-for="item in items"
                        :key="item.tab"
                >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                        v-for="(item, index) in items"
                        :key="item.tab"
                >
                    <v-card flat>
                        <v-card-text class="pb-0">
                           <keep-alive>
                                <component :is="item.content" :isVisible="index === tab" />
                            </keep-alive>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-layout>
</template>

<script>
    import ActivityMonitor from "./ActivityMonitor";
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "GsmPortManager",
        components: { ActivityMonitor },
        computed: {
            ...mapFields('tabs', [
                'tab',
            ]),
            items() {
                return [
                    {tab: this.$t('text.Activity_Monitor'), content: 'ActivityMonitor'},
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        padding: 4px;
    }
</style>
