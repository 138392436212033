<template>
  <v-layout
    row
    wrap
    align-center
  >
    <v-card
      class="mb-3"
      width="100%"
    >
      <v-data-table
        :headers="usersTableHeader"
        :items="users"
        item-key="User"
        hide-default-footer
      >
        <template v-slot:top>
          <v-alert
            dense
            prominent
            type="info"
            outlined
            border="left"
          >
            <component v-if="$i18n.locale === 'ru'" :is="{template: tpl_h_ru }" />
            <component v-else :is="{template: tpl_h_en }" />
            <v-divider
              class="my-1 info"
              style="opacity: 0.22"
            />
            <v-row align="center">
              <v-col class="grow">
                <component v-if="$i18n.locale === 'ru'" :is="{template: tpl_b_ru }"/>
                <component v-else :is="{template: tpl_b_en }"/>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="primary"
                  class="mb-2"
                  @click="addUser"
                >
                  {{ $t("text.New_token") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </template>

        <template v-slot:[`item.tg_username`]="{ item }">
          <span v-if="item['tg_username']">
            @{{ item['tg_username'] }} ({{ item['tg_first_name'] }} {{ item['tg_last_name'] }})
          </span>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span v-if="item['created_at']">
            {{ parseDate(item['created_at']) }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            @click="deleteUser(item['uuid'])"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import {mapActions, mapState} from "vuex";

import Vue from "vue";

Vue.component('tg_users_head_en', {
  template:`<span>Please use this page for integration Telegram bot <strong>@goipAssistantBot</strong> to Telegram account.</br>
            This integration will allow to control GSM gateways and monitor the state of GSM gateways and its channels using a Telegram account</span>`
});

Vue.component('tg_users_body_en', {
  template:`<span>How to use:</br>
            <ul>
              <li>Find Telegram bot <strong>@goipAssistantBot</strong> using Telegram search string</li>
              <li>Create a token on this page and link your or any other Telegram account by sending this token to <strong>@goipAssistantBot</strong></li>
            </ul>
            </span>`
});

Vue.component('tg_users_head_ru', {
  template:`<span>Используйте эту страницу для интеграции Telegram бота <strong>@goipAssistantBot</strong> с вашим или каким либо другим Telegram аккаунтом.</br>
            Такая интеграция позволит осуществлять управление GSM оборудованием и контролировать состояние GSM оборудования и его каналов используя Telegram аккаунт.</span>`
});

Vue.component('tg_users_body_ru', {
  template:`<span>Как использовать:</br>
            <ul>
              <li>Найдите Telegram бота <strong>@goipAssistantBot</strong> используя Telegram поиск</li>
              <li>Создайте токен на этой странице и привяжите свой или какой либо другой Telegram аккаунт отправив этот токен боту <strong>@goipAssistantBot</strong></li>
            </ul>
            </span>`
});

export default {
  name: "TelegramUsers",
  data() {
    return {
      tpl_h_en: `<tg_users_head_en/>`,
      tpl_h_ru: `<tg_users_head_ru/>`,

      tpl_b_en: `<tg_users_body_en/>`,
      tpl_b_ru: `<tg_users_body_ru/>`,
    }
  },
  computed: {
    ...mapState("telegram", {
      users: state => state.users,
    }),
    usersTableHeader() {
      return [
        {text: this.$t('text.Token'), value: 'uuid', align: 'left', sortable: true},
        {text: this.$t('text.ID'), value: 'tg_username', align: 'left', sortable: true},
        {text: this.$t('text.Created_at'), value: 'created_at', align: 'left', sortable: true},
        {text: this.$t('text.Actions'), value: 'actions', align: 'right', sortable: false},
      ];

    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    ...mapActions("telegram", ["setUser", "getUsers", "delUser"]),
    async addUser() {
      await this.setUser();
      await this.getUsers();
    },
    async deleteUser(token) {
      await this.delUser({
        token,
      });
      await this.getUsers();
    },
    parseDate(datetime) {
      return this.$dayjs(datetime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
    }
  }
};
</script>
