<template>
  <v-tooltip
    open-delay="800"
    close-delay="800"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mr-2"
        :small="small"
        :large="large"
        :size="size"
        v-bind="attrs"
        v-on="on"
        @click="action(sim)"
      >
        {{ icon(sim['hidden']) }}
      </v-icon>
    </template>
    <span> {{ tooltip(sim['hidden']) }} </span>
  </v-tooltip>
</template>

<script>
    import {apiCall} from "../../api/v1/calls";

    export default {
        name: "HideSimCardButton",
        props: {
          sim: {
            type: Object,
            default: function () {
              return undefined
             }
          },
          small: {
            type: Boolean,
          },
          large: {
            type: Boolean,
          },
          size: {
            type: Number,
          }
        },
        methods: {
          color(status) {
            if (status === 'LOGIN') {
              return "green"
            }
            return "gray"
          },
          action(sim) {
            apiCall({endpoint: '/customer/setSimHideStatus?sim_imsi=' + sim['imsi'] + '&hidden=' + !sim['hidden'], method: 'POST', data: null})
            .then(response => {
              if (response.status === 200) {
                this.$emit('switch-sim-card-hiding', sim['imsi']);
              }
            })
            .catch(e => {
              console.log(e)
            })
          },
          icon(hidden){
            if (hidden === false) {
              return "mdi-eye-off"
            }
            return "mdi-eye"
          },
          tooltip(hidden) {
            if (hidden === true){
              return this.$t('text.Remove_sim_from_archive')
            }
            return this.$t('text.Remove_sim_to_archive')
          }
        }
    }
</script>

<style scoped>

</style>