import { apiCall } from '../../components/api/v1/calls';

const state = {
    status: null,
    gateways: [],
    ports: [],
};

const getters = {
    authStatus: state => state.status,
    gsmGateways: state => {
        return state.gateways},
};

const actions = {
    getGsmGateways: ({commit}, gateway) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            let url = '/customer/GsmGateways';
            if (gateway) {
                url = url + '/' + gateway
            }

            apiCall({endpoint: url, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {

                    if (resp.data.result !== null && Array.isArray(resp.data.result)) {
                        resp.data.result.forEach(function(item, i, arr) {
                            arr[i]["showPassword"] = false;
                        });

                        if (resp.data.result.length === 1 && gateway) {
                            commit('ADD_GATEWAY', resp.data.result[0]);
                        } else {
                            commit('ADD_GATEWAYS', resp.data.result);
                        }
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get gsm gateways: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot get gsm gateways: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot get gsm gateways");
                commit('status', 'error');
                reject(err)
            });
        })
    },

    newGsmGateway: ({commit}, gateway) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            console.log("new: ", gateway);
            apiCall({endpoint: '/customer/newGsmGateway', method: 'POST', data: gateway})
            .then(resp => {
                commit('ADD_GATEWAY', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },

    setGsmGateway: ({commit}, gateway) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: '/customer/GsmGateway', method: 'POST', data: gateway})
            .then(resp => {
                commit('UPDATE_GATEWAY', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },

    delGsmGateway: ({commit}, gateway) => {
        return new Promise((resolve, reject) => {
            console.log("del: ", gateway);
            commit('status', 'loading');
            apiCall({endpoint: '/customer/GsmGateway/' + gateway.username, method: 'DELETE', data: null})
            .then(resp => {
                commit('REMOVE_GATEWAY', gateway.username);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    ADD_GATEWAYS: (state, gateways) => {
        state.gateways = gateways;
    },
    ADD_GATEWAY: (state, gateway) => {
        let index = state.gateways.findIndex(gw => gw.username === gateway.username);
        if (index !== -1) {
            state.gateways[index] = gateway;
            return
        }
        state.gateways.push(gateway)
    },
    GET_GATEWAY(state, username) {
        let index = state.gateways.findIndex(gw => gw.username === username);
        if (index !== -1) {
            return state.gateways[index];
        }
    },
    REMOVE_GATEWAY(state, username) {
        let index = state.gateways.findIndex(gw => gw.username === username);
        if (index !== -1) {
            state.gateways.splice(index, 1);
        }
    },
    UPDATE_GATEWAY(state, gateway) {
        let index = state.gateways.findIndex(gw => gw.username === gateway.username);
        state.gateways[index] = gateway;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
