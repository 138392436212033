import { apiCall } from '../../components/api/v1/calls';

const endpoint = '/customer/SmsHttpForwarderRules';

const state = {
    rules: [],
};

const getters = {
    rules: state => {
        return state.rules},
};

const actions = {
    getRules: ({commit}) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {
                    if (resp.data.result !== null && Array.isArray(resp.data.result)) {
                        console.log(resp.data.result);
                        commit('ADD_RULES', resp.data.result);
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get http forwarder rules: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot http forwarder rules: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot http forwarder rules");
                commit('status', 'error');
                reject(err)
            });
        })
    },
    // @TODO need implement
    newRule: ({commit}, rule) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            console.log("new: ", rule);
            apiCall({endpoint: endpoint, method: 'PUT', data: rule})
            .then(resp => {
                commit('ADD_RULE', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    setRule: ({commit}, rule) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'POST', data: rule})
            .then(resp => {
                commit('UPDATE_RULE', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    delRule: ({commit}, rule) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint + "/" + rule.id, method: 'DELETE', data: null})
            .then(resp => {
                commit('DELETE_RULE', rule.id);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    ADD_RULES: (state, rules) => {
        state.rules = rules;
    },
    ADD_RULE: (state, rule) => {
        let index = state.rule.findIndex(r => r.id === rule.id);
        if (index !== -1) {
            state.rules[index] = rule;
            return
        }
        state.rules.push(rule)
    },
    GET_RULE(state, id) {
        let index = state.rules.findIndex(r => r.id === id);
        if (index !== -1) {
            return state.rules[index];
        }
    },
    DELETE_RULE(state, id) {
        let index = state.rules.findIndex(r => r.id === id);
        if (index !== -1) {
            state.rules.splice(index, 1);
        }
    },
    UPDATE_RULE(state, rule) {
        let index = state.rules.findIndex(r => r.id === rule.id);
        state.rules[index] = rule;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
