import { apiCall } from '../../components/api/v1/calls';

const endpoint = '/customer/SimGroups';

const state = {
    sim_groups: [],
};

const getters = {
    simGroups: state => {
        return state.sim_groups},
};

const actions = {
    getSimGroups: ({commit}) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {
                    if (resp.data.result !== null && Array.isArray(resp.data.result)) {
                        console.log(resp.data.result);
                        commit('ADD_SIM_GROUPS', resp.data.result);
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get sim groups: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot get sim groups: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot get sim groups");
                commit('status', 'error');
                reject(err)
            });
        })
    },
    // @TODO not implemented on backend
    newSimGroup: ({commit}, group) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            console.log("new: ", group);
            apiCall({endpoint: endpoint, method: 'PUT', data: group})
            .then(resp => {
                commit('ADD_SIM_GROUP', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    // @TODO not implemented on backend
    setSimGroup: ({commit}, group) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'POST', data: group})
            .then(resp => {
                commit('UPDATE_SIM_GROUP', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    // @TODO not implemented on backend
    delSimGroup: ({commit}, group) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint + "/" + group.name, method: 'DELETE', data: null})
            .then(resp => {
                commit('DELETE_SIM_GROUP', group.name);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    ADD_SIM_GROUPS: (state, groups) => {
        state.sim_groups = groups;
    },
    ADD_SIM_GROUP: (state, group) => {
        let index = state.sim_groups.findIndex(g => g.name === group.name);
        if (index !== -1) {
            state.sim_groups[index] = group;
            return
        }
        state.sim_groups.push(group)
    },
    GET_SIM_GROUP(state, name) {
        let index = state.sim_groups.findIndex(g => g.name === name);
        if (index !== -1) {
            return state.sim_groups[index];
        }
    },
    DELETE_SIM_GROUP(state, name) {
        let index = state.sim_groups.findIndex(g => g.name === name);
        if (index !== -1) {
            state.sim_groups.splice(index, 1);
        }
    },
    UPDATE_SIM_GROUP(state, group) {
        let index = state.sim_groups.findIndex(g => g.name === group.name);
        state.sim_groups[index] = group;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
