export const operators = {
  // Russia
  beeline: {
    label: 'Beeline',
    color: 'orange',
    icon: './operators/beeline',
  },
  megafon: {
    label: 'Megafon',
    color: 'green',
    icon: './operators/megafon',
    long: true
  },
  motiv: {
    label: 'Motiv',
    color: 'orange',
    icon: './operators/motiv',
  },
  mts: {
    label: 'MTS',
    color: 'red',
    icon: './operators/mts',
  },
  rostelecom: {
    label: 'Rostelecom',
    color: 'blue',
    icon: './operators/rostelecom',
    long: true
  },
  tele2: {
    label: 'Tele2',
    color: 'black',
    icon: './operators/tele2',
  },
  yota: {
    label: 'Yota',
    color: 'blue',
    icon: './operators/yota',
  },
  // Myanmar
  mpt: {
    label: 'MPT',
    color: 'blue',
    icon: './operators/mpt',
  },
  ooredoo: {
    label: 'Ooredoo',
    color: 'red',
    icon: './operators/ooredoo',
    long: true
  },
  telenor: {
    label: 'Telenor',
    color: 'blue',
    icon: './operators/telenor',
  },
  // Cambodia
  cellcard: {
    label: 'Сellcard',
    color: 'orange',
    icon: './operators/cellcard',
    long: true
  },
  metfone: {
    label: 'Metfone',
    color: 'blue',
    icon: './operators/metfone',
  },
  seatel: {
    label: 'Seatel',
    color: 'blue',
    icon: './operators/seatel',
  },
  smart_mobile: {
    label: 'Smart Mobile',
    color: 'green',
    icon: './operators/smart_mobile',
  },
  qb: {
    label: 'QB',
    color: 'black',
    icon: './operators/qb',
  },
  // Thailand
  dtac: {
    label: 'dTac',
    color: 'blue',
    icon: './operators/dtac',
  },
  true_move: {
    label: 'True Move',
    color: 'black',
    icon: './operators/true_move',
    long: true
  },
  // Indonesia
  axis: {
    label: 'Axis',
    color: 'magenta',
    icon: './operators/axis',
  },
  esia: {
    label: 'Esia',
    color: 'green',
    icon: './operators/esia',
  },
  xl_axiata: {
    label: 'XL Axiata',
    color: 'blue',
    icon: './operators/xl_axiata',
    long: true
  },
  indosat: {
    label: 'Indosat',
    color: 'red',
    icon: './operators/indosat',
    long: true
  },
  psn: {
    label: 'PSN',
    color: 'blue',
    icon: './operators/psn',
  },
  sti: {
    label: 'STI',
    color: 'black',
    icon: './operators/sti',
  },
  smartfren: {
    label: 'Smartfren',
    color: 'black',
    icon: './operators/smartfren',
    long: true
  },
  telkomsel: {
    label: 'Telkomsel',
    color: 'red',
    icon: './operators/telkomsel',
    long: true
  },
  three_3: {
    label: 'Three 3',
    color: 'red',
    icon: './operators/three_3',
  },
  // Ukraine
  golden_telecom: {
    label: 'Golden Telecom',
    color: 'orange',
    icon: './operators/golden_telecom',
    long: true
  },
  kyivstar: {
    label: 'KyivStar',
    color: 'blue',
    icon: './operators/kyivstar',
  },
  lifecell: {
    label: 'LifeCell',
    color: 'blue',
    icon: './operators/lifecell',
  },
  trimob: {
    label: 'TriMob',
    color: 'green',
    icon: './operators/3mob',
  },
  // Belarus
  belcel: {
    label: 'BelCel',
    color: 'black',
    icon: './operators/belcel',
  },
  best: {
    label: 'BeST',
    color: 'black',
    icon: './operators/best',
  },
  velcom: {
    label: 'Velcom',
    color: 'black',
    icon: './operators/mdc_velcom',
  },
  // Georgia
  geocell: {
    label: 'Geocell',
    color: 'purple',
    icon: './operators/geocell',
  },
  silknet: {
    label: 'Silknet',
    color: 'blue',
    icon: './operators/silknet',
  },
  att: {
    label: 'AT&T',
    color: 'red',
    icon: './operators/att',
  },
  globe_telecom: {
    label: 'Globe Telecom',
    color: 'dark-blue',
    icon: './operators/globe_telecom',
  },
  movistar: {
    label: 'Movistar',
    color: 'blue',
    icon: './operators/movistar',
  },
  smart: {
    label: 'Smart ',
    color: 'green',
    icon: './operators/smart ',
  },
  sun: {
    label: 'Sun',
    color: 'orange',
    icon: './operators/sun',
  },
  telcel: {
    label: 'TelCel',
    color: 'dark-blue',
    icon: './operators/telcel',
  },
};
