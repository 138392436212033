export const indicators = {
    acd: {
        label: 'acd',
        icon: './indicators/acd',
    },
    asr: {
        label: 'asr',
        icon: './indicators/asr',
    },
    aq: {
        label: 'aq',
        icon: './indicators/aq',
    },
    gprs: {
        label: 'gprs',
        icon: './indicators/gprs',
    },
    gsm: {
        label: 'gsm',
        icon: './indicators/gsm',
    },
    pdd: {
        label: 'pdd',
        icon: './indicators/pdd',
    },
    sim: {
        label: 'sim',
        icon: './indicators/sim',
    },
    sip: {
        label: 'sip',
        icon: './indicators/sip',
    },
    smb: {
        label: 'smb',
        icon: './indicators/smb',
    },
    sms: {
        label: 'sms',
        icon: './indicators/sms',
    },
    ussd: {
        label: 'ussd',
        icon: './indicators/ussd',
    },
    voip: {
        label: 'voip',
        icon: './indicators/voip',
    },
};