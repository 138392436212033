import { apiCall } from '../../components/api/v1/calls';

const state = {
  status: null,
  users: [],
  chats: [],
};

const getters = {
  authStatus: state => state.status,
  tgChats: state => {
      return state.chats},
};

const actions = {
  getUsers: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/tgAccount', method: 'GET', data: null})
        .then(resp => {
          let users = Array.isArray(resp.data.result) ? resp.data.result : null;
          commit('ADD_USERS', users);
          commit('status', 'success');
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },

  setUser: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/tgAccount', method: 'POST', data: null})
          .then(resp => {
            commit('UPDATE_USER', resp);
            commit('status', 'success');
            resolve(resp);
          })
          .catch(err => {
            commit('status', 'error');
            reject(err);
          });
    });
  },

  delUser: ({commit}, {token}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/tgAccount/' + token, method: 'DELETE', data: null})
          .then(resp => {
            commit('REMOVE_USER', token)
            commit('status', 'success');
            resolve(resp);
          })
          .catch(err => {
            commit('status', 'error');
            reject(err);
          });
    });
  },

    getChats: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: '/customer/tgChat', method: 'GET', data: null})
                .then(resp => {
                    let chats = Array.isArray(resp.data.result) ? resp.data.result : null;
                    // console.log("loaded tg chats",chats);
                    commit('ADD_CHATS', chats);
                    commit('status', 'success');
                    resolve(resp);
                })
                .catch(err => {
                    commit('status', 'error');
                    reject(err);
                });
        });
    },

    setChat: ({commit}, {chat}) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: '/customer/tgChat', method: 'POST', data: chat})
            .then(resp => {
                if (chat.uuid !== "") {
                    commit('UPDATE_CHAT', chat);
                } else {
                    commit('ADD_CHAT', resp.data.result);
                }
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },

    delChat: ({commit}, {token}) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: '/customer/tgChat/' + token, method: 'DELETE', data: null})
                .then(resp => {
                    commit('REMOVE_CHAT', token);
                    commit('status', 'success');
                    resolve(resp);
                })
                .catch(err => {
                    commit('status', 'error');
                    reject(err);
                });
        });
    },
};

const mutations = {
  status: (state, payload) => {
    state.status = payload;
  },
  ADD_USERS: (state, payload) => {
    state.users = payload;
  },
  REMOVE_USER(state, uuid) {
      let index = state.users.findIndex(user => user.uuid === uuid);
      if (index !== -1) {
          state.users.splice(index, 1);
      }
  },
  UPDATE_USER(state, payload) {
      let index = state.users.findIndex(user => user.uuid === payload.uuid);
      state.users[index] = payload;
  },
  ADD_CHATS: (state, payload) => {
    state.chats = payload;
  },
  GET_CHAT(state, uuid) {
      let index = state.chats.findIndex(chat => chat.uuid === uuid);
      if (index !== -1) {
          return state.chats[index];
      }
  },
  ADD_CHAT(state, chat) {
    state.chats.push(chat);
  },
  UPDATE_CHAT(state, payload) {
      let index = state.chats.findIndex(chat => chat.uuid === payload.uuid);
      state.chats[index] = payload;
  },
  REMOVE_CHAT(state, uuid) {
      let index = state.chats.findIndex(chat => chat.uuid === uuid);
      if (index !== -1) {
          state.chats.splice(index, 1);
      }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
