import { apiCall } from '../../components/api/v1/calls';

const endpoint = '/customer/DaazwebTokens';

const state = {
    tokens: [],
};

const getters = {
    tokens: state => {
        return state.tokens},
};

const actions = {
    getTokens: ({commit}) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {
                    if (resp.data.result !== null && Array.isArray(resp.data.result)) {
                        commit('ADD_TOKENS', resp.data.result);
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get daazweb tokens: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot get daazweb tokens: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot get daazweb tokens");
                commit('status', 'error');
                reject(err)
            });
        })
    },
    getToken: ({commit}, token_id) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            apiCall({endpoint: endpoint + "/" + token_id, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {
                    if (resp.data.result !== null) {
                        commit('ADD_TOKEN', resp.data.result);
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get daazweb tokens: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else if (resp.status === 404) {
                    commit('DELETE_TOKEN', token_id);
                    commit('status', 'success');
                } else {
                    commit('status', 'success');
                }
             })
            .catch(err => {
                console.debug("cannot get daazweb tokens");
                commit('status', 'error');
                reject(err)
            });
        })
    },
    newToken: ({commit}, token) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'POST', data: token})
            .then(resp => {
                commit('ADD_TOKEN', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    setToken: ({commit}, token) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint, method: 'POST', data: token})
            .then(resp => {
                commit('UPDATE_TOKEN', resp.data.result);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
    delToken: ({commit}, token) => {
        return new Promise((resolve, reject) => {
            commit('status', 'loading');
            apiCall({endpoint: endpoint + "/" + token.id, method: 'DELETE', data: null})
            .then(resp => {
                commit('DELETE_TOKEN', token.id);
                commit('status', 'success');
                resolve(resp);
            })
            .catch(err => {
                commit('status', 'error');
                reject(err);
            });
        });
    },
};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    ADD_TOKENS: (state, tokens) => {
        state.tokens = tokens;
    },
    ADD_TOKEN: (state, token) => {
        let index = state.tokens.findIndex(t => t.id === token.id);
        if (index !== -1) {
            state.tokens[index] = token;
            return
        }
        state.tokens.push(token)
    },
    GET_TOKEN(state, token_id) {
        let index = state.tokens.findIndex(t => t.id === token_id);
        if (index !== -1) {
            return state.tokens[index];
        }
    },
    DELETE_TOKEN(state, token_id) {
        let index = state.tokens.findIndex(t => t.id === token_id);
        if (index !== -1) {
            state.tokens.splice(index, 1);
        }
    },
    UPDATE_TOKEN(state, token) {
        let index = state.tokens.findIndex(t => t.id === token.id);
        state.tokens[index] = token;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
