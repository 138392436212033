<template>
  <v-layout
      row
      wrap
      align-center
  >
    <v-card
        class="mb-3"
        width="100%"
    >
      <v-data-table
          :headers="columns"
          :items="groups"
          item-key="code"
          :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              color="white"
          >
            <v-toolbar-title>
              {{ $t('text.GSM_Groups') }}
            </v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            />
            <v-spacer/>
            <v-dialog
                v-model="dialogNew"
                max-width="500px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-on="on"
                >
                  {{ $t('text.New_group') }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">
                    {{ $t('text.New_group') }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                            :label="$t('text.Description')"
                            v-model="newDescription"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="closeNew"
                  >
                    {{ $t('text.Cancel') }}
                  </v-btn>

                  <v-btn
                      color="blue darken-1"
                      text
                      @click="saveNew"
                  >
                    {{ $t('text.Send') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              icon
              class="mr-2"
              @click="showItem(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
              icon
              @click="showDialogDelete(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
        :value="dialogGroup"
        @input="setDialogGroup"
        fullscreen
        transition="dialog-bottom-transition"
    >
      <GsmGroup/>
    </v-dialog>

    <v-dialog
        v-model="dialogDelete"
        max-width="500px"
    >
      <v-card v-if="deleteObject">
        <v-card-title>
          <span class="headline">
            {{ $t('text.Delete') }} "{{deleteObject.description}}"?
          </span>
        </v-card-title>

        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogDelete = false"
          >
            {{ $t('text.Cancel') }}
          </v-btn>

          <v-btn
              color="blue darken-1"
              text
              @click="deleteItem(deleteObject.code)"
          >
            {{ $t('text.Delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import GsmGroup from "./elements/GsmGroup";

  export default {
    name: "GsmGroups",
    components: {
      GsmGroup,
    },
    props: {isVisible: Boolean},
    data: () => ({
      dialogNew: false,
      newDescription: null,
      dialogDelete: false,
      deleteObject: null,
    }),
    async created() {
      await this.getGroups();
      await this.getGateways();
    },
    computed: {
      ...mapState('groups', {
        groups: state => Array.isArray(state.groups) ? state.groups : [],
        dialogGroup: state => state.dialogGroup,
      }),
      columns() {
        return [
          {text: this.$t('text.Group_code'), value: 'code', align: 'left', sortable: true},
          {text: this.$t('text.Group_name'), value: 'description', align: 'left', sortable: true},
          {text: this.$t('text.Actions'), value: 'actions', align: 'center', width: "10%", sortable: false},
        ];
      },
    },
    methods: {
      ...mapActions('groups', [
        'getGroups',
        'getGateways',
        'setSelectedGroup',
        'addGroup',
        'deleteGroup',
      ]),
      ...mapMutations('groups', [
        'setDialogGroup',
      ]),
      closeNew() {
        this.dialogNew = false;
        this.newDescription = null;
      },
      async saveNew() {
        await this.addGroup({
          description: this.newDescription,
        });
        this.closeNew();
        await this.getGroups();
      },
      showItem(item) {
        this.setSelectedGroup(item.code);
        this.setDialogGroup(true);
      },
      showDialogDelete(item) {
        this.dialogDelete = true;
        this.deleteObject = item;
      },
      async deleteItem(code) {
        await this.deleteGroup({
          code,
        });
        this.dialogDelete = false;
        this.deleteObject = null;
        await this.getGroups();
      },
    },
  };
</script>
