import { apiCall } from '../../components/api/v1/calls';

const state = {
    status: null,
    originators: [],
};

const getters = {
};

const actions = {
    getOriginators: ({commit}, originator) => {
        return new Promise(function (resolve, reject) {
            commit('status', 'loading');
            let url = '/customer/originators';
            if (originator) {
                url = url + '/' + originator
            }

            apiCall({endpoint: url, method: 'GET', data: null})
            .then(resp => {
                if (resp.status === 200) {

                    if (resp.data.result !== null && Array.isArray(resp.data.result)) {
                        if (resp.data.result.length === 1 && originator) {
                            commit('ADD_ORIGINATOR', resp.data.result[0]);
                        } else {
                            commit('ADD_ORIGINATORS', resp.data.result);
                        }
                        commit('status', 'success');
                        resolve(resp);
                    } else {
                        const err = new Error("cannot get originators: result is not array");
                        console.debug(err);
                        reject(err)
                    }
                } else {
                    const err = new Error("cannot get originators: non 200 response code received");
                    console.debug(err);
                    reject(err)
                }
            })
            .catch(err => {
                console.debug("cannot get originators");
                commit('status', 'error');
                reject(err)
            });
        })
    },
};

const mutations = {
    status: (state, payload) => {
        state.status = payload;
    },
    ADD_ORIGINATORS: (state, originators) => {
        state.originators = originators;
    },
    ADD_ORIGINATOR: (state, originator) => {
        let index = state.originators.findIndex(o => o.origination_prefix === originator.origination_prefix);
        if (index !== -1) {
            state.originators[index] = originator;
            return
        }
        state.originators.push(originator)
    },
    GET_ORIGINATOR(state, origination_prefix) {
        let index = state.originators.findIndex(o => o.origination_prefix === origination_prefix);
        if (index !== -1) {
            return state.originators[index];
        }
    },
    REMOVE_ORIGINATOR(state, origination_prefix) {
        let index = state.originators.findIndex(o => o.origination_prefix === origination_prefix);
        if (index !== -1) {
            state.originators.splice(index, 1);
        }
    },
    UPDATE_ORIGINATOR(state, originator) {
        let index = state.originators.findIndex(o => o.origination_prefix === originator.origination_prefix);
        state.originators[index] = originator;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};