<template>
  <v-card width="100%">
    <v-toolbar
        dark
        color="primary"
    >
      <v-toolbar-title>
        {{ $t('text.GSM_Group') }}: {{selectedGroup}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            icon
            dark
            @click="setDialogGroup(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col
            md="4"
        >
          <v-select
              v-if="gateways"
              :items="gateways"
              :label="$t('text.GSM_Gateway')"
              item-text="username"
              item-value="sn"
              :value="selectedGateway"
              @input="setSelectedGateway"
          ></v-select>
        </v-col>
      </v-row>

      <div class="subtitle-1 mb-2">
        <v-icon medium>mdi-apps</v-icon>
        {{ $t('text.AvailablePorts') }}
      </div>
      <div
          v-for="(g) in ports.availablePorts"
          :key="'availablePorts_' + g.gateway"
      >
        <div
            v-if="!selectedGateway"
            class="subtitle-2 mb-1"
        >
          {{$t('text.GSM_Gateway')}}: {{getAlias(g.gateway)}} ({{g.gateway}})
        </div>
        <transition-group
            v-if="g.ports && Array.isArray(g.ports) && g.ports.length > 0"
            class="b-items mb-4"
            name="b-animate-group"
            tag="div"
        >
          <div
              v-for="(item) in g.ports"
              :key="g.gateway + item.port_index"
              class="b-item"
          >
            <GsmPort
                :item="item"
                show-move-btn
                show-voip-state
            />
          </div>
        </transition-group>
        <div
            v-else
            class="overline mb-2"
        >
          {{$t('text.No_ports')}}
        </div>
      </div>

      <v-divider class="mb-4"></v-divider>

      <div class="subtitle-1 mb-2">
        <v-icon medium>mdi-check</v-icon>
        {{ $t('text.ActivePorts') }}
      </div>
      <div
          v-for="(g) in ports.activePorts"
          :key="'activePorts_' + g.gateway"
      >
        <div
            v-if="!selectedGateway"
            class="subtitle-2 mb-1"
        >
          {{$t('text.GSM_Gateway')}}: {{getAlias(g.gateway)}} ({{g.gateway}})
        </div>
        <transition-group
            v-if="g.ports && Array.isArray(g.ports) && g.ports.length > 0"
            class="b-items mb-4"
            name="b-animate-group"
            tag="div"
        >
          <div
              v-for="(item) in g.ports"
              :key="g.gateway + item.port_index"
              md="2"
              class="b-item"
          >
            <GsmPort
                :item="item"
                active
                show-move-btn
                show-voip-state
            />
          </div>
        </transition-group>
        <div
            v-else
            class="overline mb-2"
        >
          {{$t('text.No_ports')}}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import GsmPort from './GsmPort';
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    components: {
      GsmPort,
    },
    async created() {
      await this.getPorts();
    },
    computed: {
      ...mapState('groups', {
        gateways(state) {
          const first = {username: this.$t('text.All'), sn: null};
          const gateways = Array.isArray(state.gateways) ? state.gateways : [];
          return [first, ...gateways];
        },
        selectedGroup: state => {
          const o = state.groups.find(i => i.code === state.selectedGroup);
          return typeof o !== 'undefined'
              ? o.description
              : null;
        },
        selectedGateway: state => state.selectedGateway,
        ports: state => state.ports,
      }),
    },
    methods: {
      ...mapActions('groups', [
        'getPorts',
        'setSelectedGateway',
      ]),
      ...mapMutations('groups', [
        'setDialogGroup',
        'resetPorts',
      ]),
      getAlias (username) {
        let index = this.gateways.findIndex(gw => gw.username === username, 1);
        if (index !== -1) {
          return this.gateways[index].alias;
        }
        return ""
      },
    },
  };
</script>

<style lang="scss">
  .b-items {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .b-item {
    flex: 0 0 calc(100% / 8 - 16px);
    margin: 8px;

    .b-item__button {
      display: none;
    }

    &:hover {
      .b-item__button {
        display: block;
      }
    }
  }
</style>
