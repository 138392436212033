<template>
  <v-layout
    row
    wrap
  >
    <v-card
      class="mb-3"
      width="100%"
    >
      <v-data-table
        :headers="chatsTableHeader"
        :items="tgChats"
        :sort-by="['settings.chat_id']"
        sort-desc
        hide-default-footer
      >
        <template v-slot:top>
          <v-alert
            dense
            prominent
            type="info"
            outlined
            border="left"
          >
            <component
              :is="{template: tpl_h_ru }"
              v-if="$i18n.locale === 'ru'"
            />
            <component
              :is="{template: tpl_h_en }"
              v-else
            />
            <v-divider
              class="my-1 info"
              style="opacity: 0.22"
            />
            <v-row align="center">
              <v-col class="grow">
                <component
                  :is="{template: tpl_b_ru }"
                  v-if="$i18n.locale === 'ru'"
                />
                <component
                  :is="{template: tpl_b_en }"
                  v-else
                />
              </v-col>
              <v-col class="shrink">
                <v-dialog
                  v-model="dialogEdit"
                  max-width="800px"
                  :retain-focus="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="addChat"
                    >
                      {{ $t("text.New_token") }}
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}:</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container fluid>
                        <v-row no-gutters>
                          <v-col>
                            <v-switch
                              v-model="editedChat.settings['gsm_gateway_state_notification']"
                              flat
                              :label="$t('text.tg_notify_about_gsm_gateway_status')"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-switch
                              v-model="editedChat.settings['gsm_gateway_ports_state_notification']"
                              flat
                              :label="$t('text.tg_notify_about_gsm_port_state')"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-switch
                                v-model="editedChat.settings['sim_replacement_notification']"
                                flat
                                :label="$t('text.tg_notify_about_sim_replacement')"
                                hide-details
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-switch
                              v-model="editedChat.settings['sms_incoming_notification']"
                              flat
                              hide-details
                              :label="$t('text.tg_forward_incoming_sms')"
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col
                            sm="1"
                            align-self="center"
                            class="text-center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="color(editedChat.settings['sms_incoming_notification'])"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                              </template>
                              <component
                                :is="{template: tpl_sf_d_ru }"
                                v-if="$i18n.locale === 'ru'"
                              />
                              <component
                                :is="{template: tpl_sf_d_en }"
                                v-else
                              />
                            </v-tooltip>
                          </v-col>
                          <v-col>
                            <v-textarea
                              v-model="editedChat.settings['sms_incoming_filter_by_sender']"
                              :disabled="!editedChat.settings['sms_incoming_notification']"
                              :label="$t('text.tg_incoming_sms_filter_by_sender_text_prompt')"
                              counter
                              maxlength="120"
                              clearable
                              clear-icon="mdi-close-circle"
                              auto-grow
                              rows="1"
                              row-height="15"
                            />
                          </v-col>
                          <v-col
                            sm="3"
                          >
                            <v-checkbox
                              v-model="editedChat.settings['sms_incoming_filter_by_sender_is_blacklist']"
                              :disabled="!editedChat.settings['sms_incoming_notification']"
                              color="black"
                              hide-details
                              :label="$t('text.tg_filter_is_blacklist')"
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col
                              sm="1"
                              align-self="center"
                              class="text-center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    :color="color(editedChat.settings['sms_incoming_notification'])"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                              </template>
                              <component
                                  :is="{template: tpl_cf_d_ru }"
                                  v-if="$i18n.locale === 'ru'"
                              />
                              <component
                                  :is="{template: tpl_cf_d_en }"
                                  v-else
                              />
                            </v-tooltip>
                          </v-col>
                          <v-col>
                            <v-textarea
                                v-model="editedChat.settings['sms_incoming_filter_by_content']"
                                :disabled="!editedChat.settings['sms_incoming_notification']"
                                :label="$t('text.tg_incoming_sms_filter_by_content_text_prompt')"
                                counter
                                maxlength="120"
                                clearable
                                clear-icon="mdi-close-circle"
                                auto-grow
                                rows="1"
                                row-height="15"
                            />
                          </v-col>
                          <v-col
                              sm="3"
                          >
                            <v-checkbox
                                v-model="editedChat.settings['sms_incoming_filter_by_content_is_blacklist']"
                                :disabled="!editedChat.settings['sms_incoming_notification']"
                                color="black"
                                hide-details
                                :label="$t('text.tg_filter_is_blacklist')"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                      >
                        {{ $t('text.Cancel') }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="saveChat(editedChat)"
                      >
                        {{ $t('text.Save') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="600px">
                  <v-card>
                    <v-card-title class="headline">{{ $t('text.tg_delete_chat_dialog') }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('text.No') }}</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteChat(editedChat.uuid)">{{ $t('text.Yes') }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-alert>
        </template>

        <template v-slot:[`item.tg_username`]="{ item }">
          <span v-if="item['tg_username']">
            @{{ item['username'] }} ({{ item['first_name'] }} {{ item['last_name'] }})
          </span>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span v-if="item['created_at']">
            {{ parseDate(item['created_at']) }}
          </span>
        </template>
        
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editChatDialog(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteChatDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Vue from "vue";

Vue.component('tg_chats_head_en', {
  template:`<span>
                Please use this page for integration Telegram bot <strong>@goipAssistantBot</strong> to Telegram chat.</br>
                Such integration will allow all chat participants to receive notifications about following events: incoming SMS, GSM gateway availability, GSM channels status changes, and others.
            </span>`
});

Vue.component('tg_chats_head_ru', {
  template:`<span>
                Используйте эту страницу для интеграции Telegram бота <strong>@goipAssistantBot</strong> c Telegram группой.</br>
                Такая интеграция позволит получать уведомления всем участникам группы о таких событиях как: входящие SMS, доступность GSM-шлюза, изменение статуса GSM-порта и прочие.
            </span>`
});

Vue.component('tg_chats_body_en', {
  template:`<span>How to use:</br>
            <ul>
              <li>Create a Telegram chat and add Telegram bot <strong>@goipAssistantBot</strong> to chat members</li>
              <li>Create a token on this page and link your chat by sending this token to Telegram bot via group chat f.e. <strong>@goipAssistantBot YOUR_TOKEN</strong></li>
              <li>Define the filters and types of notifications in the token settings that you want to receive in the your Telegram chat</li>
            </ul>
            </span>`
});

Vue.component('tg_chats_body_ru', {
  template:`<span>Как использовать:</br>
            <ul>
              <li>Создайте группу в Telegram и добавьте туда Telegram бота <strong>@goipAssistantBot</strong></li>
              <li>Создайте токен на этой странице и привяжите свою группу отправив этот токен боту, используя групповой чат, например так: <strong>@goipAssistantBot ВАШ_ТОКЕН</strong></li>
              <li>В настройках токена определите фильтры и типы уведомлений которые вы хотите получать в свою Telegram группу</li>
            </ul>
            </span>`
});

Vue.component('tg_chat_sms_sender_filter_desc_en', {
  template:`<span>
                 You can use this filter to specify the list of sources (senders) whose SMS will redirected to the Telegram chat.</br>
                 Please use the vertical bar sign | as a separator to set multiple sources (senders) in filter</br>
                 Just remain this field blank if you want to receive SMS from any sources.</br></br>
                 Blacklist - if enabled, SMS from sources in this list will NOT be redirected to the Telegram chat.
            </span>`
});


Vue.component('tg_chat_sms_sender_filter_desc_ru', {
  template:`<span>
                Укажите здесь список источников (отправителей), SMS-сообщения которых вы хотите перенаправить в Telegram группу.</br>
                Используйте знак вертикальная черта | как разделитель, что бы указать несколько источников (отправителей)</br>
                Или оставьте это поле пустым если хотите получать все SMS-сообщения.</br></br>
                Чёрный список - если включено, SMS-сообщения от источников указанных в списке НЕ будут перенаправлены в Telegram группу.
            </span>`
});

Vue.component('tg_chat_sms_content_filter_desc_en', {
  template:`<span>
                Please use this field to filter SMS messages by content (SMS text)</br>
                Specify the list of words and phrases contained in SMS messages that you want to redirect to the Telegram group.</br>
                Use the vertical bar sign | as a separator to indicate several words and phrases</br></br>
                Examples:</br>
                https://|http:// - filters messages containing links to web resources</br>
                Called you|Subscriber with number|Your balance - will filter service messages about the availability of another subscriber, as well as messages containing balance information</br>
                Just remain this field blank if you want to receive SMS messages with any content. </br> </br>
                Blacklist - if enabled, SMS messages with the specified content will NOT be redirected to the Telegram group.
            </span>`
});

Vue.component('tg_chat_sms_content_filter_desc_ru', {
  template:`<span>
                Используйте это поле для фильтрации SMS-сообщений по содержимому (тексту смс сообщения)</br>
                Укажите список слов и словосочетаний содержащиеся в SMS-сообщениях которые вы хотите перенаправить в Telegram группу.</br>
                Используйте знак вертикальная черта | как разделитель, что бы указать несколько слов и словосочетаний</br></br>
                Примеры:</br>
                https://|http:// - отфильтрует сообщения с ссылками на веб ресурсы</br>
                Вам звонил|Абонент с номером|Ваш баланс - отфильтрует сервисные сообщения о доступности другого абонента а так же сообщения с информацией по балансу</br>
                Или оставьте это поле пустым если хотите получать SMS-сообщения с любым содержимым.</br></br>
                Чёрный список - если включено, SMS-сообщения с указанным содержимым НЕ будут перенаправлены в Telegram группу.
            </span>`
});

export default {
  name: "TelegramChats",
  data() {
    return {
      tpl_h_en: `<tg_chats_head_en/>`,
      tpl_h_ru: `<tg_chats_head_ru/>`,
      tpl_b_en: `<tg_chats_body_en/>`,
      tpl_b_ru: `<tg_chats_body_ru/>`,
      tpl_sf_d_en: `<tg_chat_sms_sender_filter_desc_en/>`,
      tpl_sf_d_ru: `<tg_chat_sms_sender_filter_desc_ru/>`,
      tpl_cf_d_en: `<tg_chat_sms_content_filter_desc_en/>`,
      tpl_cf_d_ru: `<tg_chat_sms_content_filter_desc_ru/>`,

      dialogEdit: false,
      dialogDelete: false,
      editedChat: {
        created_at: "",
        updated_at: "",
        type: "",
        title: "",
        uuid: "",
        settings: {
          chat_id: 0,
          gsm_gateway_state_notification: false,
          gsm_gateway_ports_state_notification: false,
          sim_replacement_notification: false,
          sms_incoming_notification: false,
          sms_incoming_filter_by_sender: "",
          sms_incoming_filter_by_sender_is_blacklist: false,
          sms_incoming_filter_by_content: "",
          sms_incoming_filter_by_content_is_blacklist: false,
        }
      },
    }
  },
  computed: {
    ...mapGetters("telegram", [
      'tgChats',
    ]),
    formTitle () {
      let text = this.$t('text.tg_new_chat_notification_settings');
      if (this.editedChat.uuid !== "") {
        text = this.$t('text.tg_edit_chat_notification_settings');
        if (this.editedChat.title !== "") {
          text = text + " \"" + this.editedChat.title + "\""
        }
      }
      return text
    },
    chatsTableHeader() {
      return [
        {text: this.$t('text.Token'), value: 'uuid', align: 'left', sortable: true},
        {text: this.$t('text.ID'), value: 'title', align: 'left', sortable: true},
        {text: this.$t('text.Type'), value: 'type', align: 'left', sortable: true},
        {text: this.$t('text.Created_at'), value: 'created_at', align: 'left', sortable: true},
        {text: this.$t('text.Actions'), value: 'actions', align: 'right', sortable: false},
      ];
    }
  },
  async mounted() {
    await this.getChats();
  },
  methods: {
    ...mapActions("telegram", ["getChats", "newChat", "setChat", "delChat"]),
    addChat() {
      this.editedChat = this.toDefault();
      this.dialogEdit = true
    },
    async deleteChat(token) {
      await this.delChat({
        token,
      });
      await this.getChats();
      this.dialogDelete = false;
    },
    async saveChat(chat) {
      await this.setChat({chat});
      this.dialogEdit = false
    },
    deleteChatDialog(chat) {
      this.dialogDelete = true;
      this.editedChat = chat;
    },
    editChatDialog(chat) {
      this.dialogEdit = true;
      this.editedChat = chat;
    },
    async closeEdit(){
      await this.getChats();
      this.dialogEdit = false
    },
    closeDelete(){
      this.dialogDelete = false
    },
    color(active){
      if (!active) {
        return "gray"
      }
      return "blue"
    },
    parseDate(datetime) {
      return this.$dayjs(datetime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
    },
    toDefault() {
      return {
        uuid: "",
        settings: {
          chat_id: 0,
          gsm_gateway_state_notification: true,
          gsm_gateway_ports_state_notification: false,
          sim_replacement_notification: false,
          sms_incoming_notification: false,
          sms_incoming_filter_by_sender: "",
          sms_incoming_filter_by_sender_is_blacklist: false,
          sms_incoming_filter_by_content: "",
          sms_incoming_filter_by_content_is_blacklist: false,
        }
      }
    }
  }
};
</script>
