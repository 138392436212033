<template>
    <div class="b-filter-button">
      <v-row no-gutters>
        <v-col cols="6">
          <v-menu
            :close-on-content-click="false"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('text.Filter').toUpperCase() }}
              </v-btn>
            </template>

            <v-card
              width="480px"
            >
              <v-card-text>
                <v-responsive class="b-filters">
                  <div class="b-filters-block">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="b-date-picker-outer">
                          <eDateTimePicker
                            :initial-datetime="datetime"
                            @e-datetime-changed="changeDatetime"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="b-filters-block">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="filter.selectedCallDirection"
                          :label="$t('text.Call_Direction')"
                          :items="callDirections"
                        >
                          <template v-slot:selection="direction">
                            {{ direction.item['text'] }}
                          </template>
                          <template v-slot:item="direction">
                            {{ direction.item['text'] }}
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="filter.selectedCallStatus"
                          :label="$t('text.Call_Status')"
                          :items="callStatuses"
                          :item-text="callStatuses"
                          :item-value="callStatuses"
                          multiple
                          @focus="getCallStatuses()"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByCallerNumber"
                          :label="$t('text.Caller_Number')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByDestinationNumber"
                          :label="$t('text.Destination_Number')"
                        />
                      </v-col>
                    </v-row>
                  </div>

                  <div class="b-filters-block">
                    <v-row no-gutters>
                      <v-col
                              cols="12"
                              md="6"
                      >
                        <v-text-field
                                v-model="filter.searchBySimCard"
                                :label="$t('text.SIM_IMSI')"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <MobileOperatorsSelector
                          v-model="filter.selectedOperators"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="filter.selectedGsmGateway"
                          :items="gateways"
                          item-text="username"
                          :label="$t('text.GSM_Gateway')"
                        >
                          <template v-slot:selection="data">
                            {{ data.item['username'] + " (" + data.item['alias'] + ")" }}
                          </template>
                          <template v-slot:item="data">
                            {{ data.item['username'] + " (" + data.item['alias'] + ")" }}
                          </template>
                        </v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="filter.selectedGsmGatewayPort"
                          :label="$t('text.Port')"
                          :items="gsmGatewayPorts"
                          item-text="port"
                          @focus="getGsmGatewayPorts(filter.selectedGsmGateway)"
                        />
                      </v-col>
                    </v-row>
                  </div>

                  <div class="b-filters-block">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByDurationLessThat"
                          :label="$t('text.Duration') + ' < '"
                          type="number"
                          suffix="sec."
                          min="1"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByBillsecLessThat"
                          :label="$t('text.Billsec') + ' < '"
                          type="number"
                          suffix="sec."
                          min="1"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByAsrLessThat"
                          :label="$t('text.ASR') + ' < '"
                          type="number"
                          min="1"
                          max="100"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="filter.searchByAqLessThat"
                          :label="$t('text.AQ') + ' < '"
                          type="number"
                          min="1"
                          max="100"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-responsive>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="resetButtonLabel"
                  class="mr-2"
                  text
                  @click="resetAllFilters"
                >
                  {{ resetButtonLabel }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  small
                  @click="rendering"
                >
                  {{ $t('text.Apply')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="6"> <!-- export to csv button -->
          <v-btn
              color="success"
              small
              @click="exportToCsv"
              :depressed="loading"
              :disabled="loading"
          >
            {{ $t('text.Export')}}
          </v-btn>
        </v-col>
      </v-row>
  </div>
</template>

<script>
  import { apiCall } from '../api/v1/calls';
  import MobileOperatorsSelector from './MobileOperatorsSelector';
  import eDateTimePicker from './eDateTimePicker';
  import mGsmGateways from "../../mixins/customer/mGsmGateways";
  import {mapActions, mapState} from "vuex";

  if (!String.prototype.format) {
    String.prototype.format = function(...args) {
      return this.replace(/(\{\d+\})/g, function(a) {
        return args[+(a.substr(1, a.length - 2)) || 0];
      });
    };
  }

  export default {
    name: 'CallDetailsRecordsFilters',
    components: {MobileOperatorsSelector, eDateTimePicker },
    mixins: [ mGsmGateways ],
    props: [
      // eslint-disable-next-line vue/require-prop-types
      'value',
    ],
    data () {
      return {
        callStatuses: [],
        gsmGatewayPorts: [],
        filter: {
          searchByCallerNumber: '',
          searchByDestinationNumber: '',
          searchBySimCard: '',
          searchByDurationLessThat: null,
          searchByBillsecLessThat: null,
          searchByAsrLessThat: null,
          searchByAqLessThat: null,
          selectedCallDirection: '',
          selectedCallStatus: '',
          selectedGsmGateway: '',
          selectedGsmGatewayPort: null,
          selectedOperators: [],
          exportToCsv: false,
        },
        datetimeDefault: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
        datetime: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
        loading: false,
      }
    },
    computed: {
      ...mapState("gsm_gateways", {
        gateways: state => state.gateways,
      }),
      data: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        },
      },
      callDirections() {
        return [
          { text: this.$t('text.Incoming'), value: 'incoming' },
          { text: this.$t('text.Outgoing'), value: 'outgoing' }
        ]
      },
      countFilterOptions() {
        let count = 0;
        for (const value of Object.values(this.filter)) {
          if (!!value && value.length > 0) {
            count++;
          }
        }
        return count;
      },
      resetButtonLabel() {
        return this.countFilterOptions > 0
          ? `RESET ${this.countFilterOptions} OPTION`
          : this.countFilterOptions > 1
            ? `RESET ${this.countFilterOptions} OPTION`
            : false;
      },
    },
    created() {
      this.$emit('start');
    },
    mounted() {
      this.rendering()
    },
    methods: {
      ...mapActions("gsm_gateways", ["getGsmGateways"]),
      async rendering() {
        this.$emit('start');
        await this.getGsmGateways();

        let request = '/customer/getCdrRecords?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        // datetime from/to validation
        if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
          this.datetime = this.datetimeDefault
        }
        request = "{0}&start_datetime={1}".format(request, this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss'));
        request = "{0}&end_datetime={1}".format(request, this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss'));
        // search by caller_id_number/destination_number
        if (this.filter.searchByCallerNumber !== 'undefined' && this.filter.searchByCallerNumber !== "") {
          request = "{0}&caller_id_number={1}".format(request, this.filter.searchByCallerNumber);
        }
        if (this.filter.searchByDestinationNumber !== 'undefined' && this.filter.searchByDestinationNumber !== "") {
          request = "{0}&destination_number={1}".format(request, this.filter.searchByDestinationNumber);
        }
        // search by sim card
        if (this.filter.searchBySimCard !== 'undefined' && this.filter.searchBySimCard !== "") {
          request = "{0}&sim_card={1}".format(request, this.filter.searchBySimCard);
        }

        // search by call direction
        if (this.filter.selectedCallDirection !== 'undefined' && this.filter.selectedCallDirection !== "") {
          request = "{0}&call_direction={1}".format(request, this.filter.selectedCallDirection);
        }
        // search gsm gateway/port
        if (this.filter.selectedGsmGateway !== 'undefined' && this.filter.selectedGsmGateway !== "") {
          request = "{0}&gsm_gateway={1}".format(request, this.filter.selectedGsmGateway);
        }
        if (this.filter.selectedGsmGatewayPort && this.filter.selectedGsmGatewayPort > 0) {
          request = "{0}&gsm_gateway_port={1}".format(request, this.filter.selectedGsmGatewayPort);
        }
        // search by call status
        if (Array.isArray(this.filter.selectedCallStatus) && this.filter.selectedCallStatus.length > 0) {
          request = "{0}&call_status={1}".format(request, this.filter.selectedCallStatus);
        }
        // search by mobile operators
        // https://stackoverflow.com/questions/40493085/how-to-pass-an-array-as-a-url-parameter -- on client side
        // https://stackoverflow.com/questions/64801244/how-can-golang-gin-get-the-array-parameters-of-get-requests-query-string -- on server side
        if (Array.isArray(this.filter.selectedOperators) && this.filter.selectedOperators.length > 0) {
          request = "{0}&{1}".format(request, this.filter.selectedOperators.map(function(el, idx) {
              return 'hni=' + el;
            }).join('&'));
        }

        if (this.filter.searchByDurationLessThat !== null) {
          request = "{0}&duration_less_that={1}".format(request, this.filter.searchByDurationLessThat);
        }
        if (this.filter.searchByBillsecLessThat !== null) {
          request = "{0}&billsec_less_that={1}".format(request, this.filter.searchByBillsecLessThat);
        }
        if (this.filter.searchByAsrLessThat !== null) {
          request = "{0}&asr_less_that={1}".format(request, this.filter.searchByAsrLessThat);
        }
        if (this.filter.searchByAqLessThat !== null) {
          request = "{0}&aq_less_that={1}".format(request, this.filter.searchByAqLessThat);
        }
        // export formats
        if (this.filter.exportToCsv === true) {
          request = "{0}&export_to_csv={1}".format(request, this.filter.exportToCsv);
        }
        this.loading = true;
        apiCall({endpoint: request, method: 'GET', data: null})
          .then(response => {
            if (this.filter.exportToCsv) { // export to csv
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'cdr.csv');
              document.body.appendChild(link);
              link.click();
            } else {
              this.data = response.data.result;
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.loading = false;
            this.$emit('end');
            this.filter.exportToCsv = false});
      },
      exportToCsv() {
        this.filter.exportToCsv = true;
        this.rendering()
      },
      changeDatetime(dtime) {
        this.datetime = dtime;
        this.rendering()
      },
      getGsmGatewayPorts(gateway) {
        this.getCustomerGsmGatewayPorts(gateway)
        .then(ports => {
          this.gsmGatewayPorts = ports
        }).catch(e => {
          this.gsmGatewayPorts =  []
        });
      },
      getCallStatuses() {
        apiCall({endpoint: '/customer/getCallStatuses', method: 'GET', data: null})
          .then(response => {
            this.callStatuses = response.data.result;
            return response.data.result
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      resetAllFilters(){
        this.filter.searchByCallerNumber = '';
        this.filter.searchByDestinationNumber = '';
        this.filter.searchBySimCard = '';
        this.filter.selectedCallDirection = '';
        this.filter.selectedCallStatus = '';
        this.filter.selectedGsmGateway = '';
        this.filter.selectedGsmGatewayPort = null;
        this.filter.selectedOperators = [];
        this.filter.searchByDurationLessThat = null;
        this.filter.searchByBillsecLessThat = null;
        this.filter.searchByAsrLessThat = null;
        this.filter.searchByAqLessThat = null;
        this.filter.exportToCsv = false;
        this.datetime = this.datetimeDefault;
        this.rendering()
      },
    }
  };
</script>
