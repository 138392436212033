export const causes = {
    USER_BUSY: {
        short: 'USER BUSY',
        description: '',
    },
    NORMAL_CLEARING: {
        short: 'ANSWERED',
        description: '',
    },
    ORIGINATOR_CANCEL: {
        short: 'CANCEL',
        description: '',
    },
    NO_ANSWER: {
        short: 'NO ANSWER',
        description: '',
    },
    NO_USER_RESPONSE: {
        short: 'NO ANSWER',
        description: '',
    },
    NORMAL_TEMPORARY_FAILURE: {
        short: 'TEMPORARY FAILURE',
        description: '',
    },
    UNSPECIFIED: {
        short: 'UNSPECIFIED',
        description: '',
    },
    NORMAL_UNSPECIFIED: {
        short: 'UNSPECIFIED',
        description: '',
    },
    INVALID_MSG_UNSPECIFIED: {
        short: 'UNSPECIFIED',
        description: '',
    },
    UNALLOCATED_NUMBER: {
        short: 'INVALID NUMBER',
        description: '',
    },
    INVALID_NUMBER_FORMAT: {
        short: 'INVALID NUMBER',
        description: '',
    },
    NO_ROUTE_TRANSIT_NET: {
        short: 'NO ROUTE',
        description: '',
    },
    NO_ROUTE_DESTINATION: {
        short: 'NO ROUTE',
        description: '',
    },
    DESTINATION_OUT_OF_ORDER: {
        short: 'NO ROUTE',
        description: '',
    },
    EXCHANGE_ROUTING_ERROR: {
        short: 'ROUTING ERROR',
        description: '',
    },
    INCOMPATIBLE_DESTINATION: {
        short: 'ROUTING ERROR',
        description: '',
    },
    CALL_REJECTED: {
        short: 'REJECTED',
        description: '',
    },
    SUBSCRIBER_ABSENT: {
        short: 'REJECTED',
        description: '',
    },
    FACILITY_REJECTED: {
        short: 'REJECTED',
        description: '',
    },
    MANDATORY_IE_MISSING: {
        short: 'REJECTED',
        description: '',
    },
    SERVICE_NOT_IMPLEMENTED: {
        short: 'REJECTED',
        description: '',
    },
    FACILITY_NOT_IMPLEMENTED: {
        short: 'REJECTED',
        description: '',
    },
    ACCESS_INFO_DISCARDED: {
        short: 'REJECTED',
        description: '',
    },
    NORMAL_CIRCUIT_CONGESTION: {
        short: 'CONGESTION',
        description: '',
    },
    NETWORK_OUT_OF_ORDER: {
        short: 'NETWORK ERROR',
        description: '',
    },
    RECOVERY_ON_TIMER_EXPIRE: {
        short: 'TIMER EXPIRE',
        description: '',
    },
    REQUESTED_CHAN_UNAVAIL: {
        short: 'SERVICE UNAVAILABLE',
        description: '',
    },
    SERVICE_UNAVAILABLE: {
        short: 'SERVICE UNAVAILABLE',
        description: '',
    },
};


