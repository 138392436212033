<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>{{ $t('text.please_login') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model.trim="$v.auth.username.$model"
                  :label="$t('text.login_username')"
                  name="email"
                  autocomplete="email"
                  prepend-icon="person"
                  type="text"
                  :error-messages="usernameError"
                  @input="validUsername"
                />
                <v-text-field
                  id="password"
                  v-model.trim="$v.auth.password.$model"
                  :label="$t('text.login_password')"
                  name="password"
                  autocomplete="current-password"
                  prepend-icon="lock"
                  type="password"
                  :error-messages="passwordError"
                  @input="validPassword"
                />
              </v-form>
              <v-alert
                v-if="loginError"
                border="left"
                color="red"
                dense
                elevation="4"
                outlined
                type="info"
              >
                {{ loginError }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="login"
              >
                {{ $t('text.login') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import { AUTH_REQUEST } from '../../store/actions/auth'
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

  export default {
    name: 'Login',
    data () {
      return {
        auth: {
          username: '',
          password: '',
        },
        usernameError: '',
        passwordError: '',
        loginError: '',
      }
    },
    validations: {
      auth: {
        username: { required, email },
        password: { required }
      }
    },
    methods: {
       login() {
         if (!this.validFields()) {
           return
         }

         this.$store.dispatch(AUTH_REQUEST, { username: this.auth.username, password: this.auth.password}).then(() => {
           this.$router.push('/')
         }).catch(() => {
           this.loginError = this.$t('text.login_invalid');
           this.usernameError = ' ';
           this.passwordError = ' ';
         })
      },
      validUsername() {
        this.loginError = '';
        this.usernameError = '';
        this.passwordError = '';
        this.$v.auth.$touch();
        if (!this.$v.auth.$dirty) return '';

        if (!this.$v.auth.username.email) {
          return this.usernameError = this.$t('text.email_is_invalid');
        }
      },
      validPassword() {
        this.loginError = '';
        this.passwordError = '';
      },
      validFields(){
        this.$v.auth.$touch();
        if (!this.$v.auth.username.required) {
          this.usernameError = this.$t('text.is_required_field');
          return false
        }

        if (!this.$v.auth.password.required) {
          this.passwordError = this.$t('text.is_required_field');
          return false
        }

        return true
      }
    },
  }
</script>
