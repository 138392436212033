export const ENGLISH_TRANSLATIONS = {
    text: {
        AppTitle: 'GoIPZilla',
        // https://medium.com/better-programming/how-to-add-localization-and-translation-to-your-vue-js-app-dc4ad2b6c3e8
        // GSM gateway
        GSM_Gateway: 'GSM Gateway',
        GSM_Gateways: 'GSM Gateways',
        GSM_Gateways_Select: 'Select GSM Gateways',
        GSM_GATEWAYS: 'GSM GATEWAYS',
        GSM_Port: 'GSM Port',
        GSM_Ports: 'GSM Ports',
        GSM_Port_Management: 'GSM Port Management',
        GSM_Gateway_and_port: 'GSM Gateway (port)',
        GSM_Groups: 'GSM Groups',
        GSM_Group: 'GSM Group',
        GSM_Status: 'GSM Status',
        Group_code: 'Group Code',
        Group_name: 'Group Name',
        No_ports: 'No ports',
        NO_AVAILABLE_PORTS: 'NO AVAILABLE PORTS',
        All: 'All',
        AvailablePorts: 'Available ports',
        ActivePorts: 'Enabled ports',
        New_group: 'New group',
        Description: 'Description',
        Network_Availability_Status: "IP-network availability status",
        VoIP_Registration_Status: "VoIP-registration status",
        VoIP_Ping_Status: "VoIP-ping status",
        // Порт
        Port: 'Port',
        port: 'port',
        port_display_number: 'Display Number', // не планирую использовать как самостоятельное поле
        port_signal_level: 'Signal',
        port_gsm_status: 'GSM Status',
        port_voip_status: 'VOIP Status',
        port_voip_state: 'VOIP State',
        port_idle: 'Idle',
        port_remain_time: 'Remain Time',
        port_power: 'Power State',
        // Статус питания порта
        port_power_on: 'On',
        port_power_off: 'Off',
        // Статус порта
        port_voip_status_login: 'Registered',
        port_voip_status_logout: 'Unregistered',
        port_voip_status_failed: 'Auth Failed', //@TODO для новых прошивок (коды 401, 403 и т.д.)
        // Состояние порта
        //@TODO отображать в панели как 'Исходящий вызов на $display_number' или 'Разговаривает с $display_number'
        port_voip_state_idle: 'IDLE',
        port_voip_state_loading: 'LOADING',
        port_voip_state_dialing: 'DIALING: ',
        port_voip_state_alerting: 'CALL TO: ',
        port_voip_state_connected: 'CONNECTED TO: ',
        port_voip_state_active: 'ACTIVE (busy)',
        port_voip_state_incoming: 'CALL FROM: ',
        port_voip_state_unknown: 'UNKNOWN',
        // Сим карты
        SIM: "SIM",
        sim_card: 'SIM Card',
        SIM_Card: 'SIM Card',
        sim_cards: 'sim cards',
        SIM_CARDS: 'SIM CARDS',
        Sim_Cards_List: 'SIM Cards List',
        Sim_Card_Management: 'SIM Card Management',
        sim_number: 'SIM Number',
        sim_imei: 'SIM IMEI',
        sim_imsi: 'IMSI',
        SIM_IMSI: 'SIM IMSI',
        sim_iccid: 'SIM ICCID',
        SIM_Group: 'SIM Group',
        SIM_Group_Capacity: 'SIM Group Capacity',
        SIM_card_not_installed: 'SIM card not installed',
        sim_mobile_network: 'Mobile Network',
        sim_mobile_operator: 'SIM Mobile Operator',
        sim_gsm_login: 'GSM Status',
        Mobile_operator: 'Mobile Operator',
        Balance: 'Balance',
        Balance_no_data: 'No balance data',
        Extracted_from: 'Extracted from',
        Lock_SIM_for_outgoing_calls: 'Lock SIM Card for outgoing calls',
        Lock_SIM_for_outgoing_sms: 'Lock SIM Card for outgoing SMS',
        Unlock_SIM_for_outgoing_calls: 'Unlock SIM Card for outgoing calls',
        Unlock_SIM_for_outgoing_sms: 'Unlock SIM Card for outgoing SMS',
        Method: 'Method',
        bank_cards: 'Bank cards',
        // SIM Bank
        id: 'ID',
        sim_bank: 'SIM Bank',
        slot: 'Slot',
        sim_bank_name: 'SIM Bank Name',

        // SIM Groups
        sim_group: 'SIM Group',
        sim_groups: 'SIM Groups',
        sim_group_name: 'Group Name',
        sim_group_active: 'Active',
        sim_group_inactive: 'Inactive',
        sim_group_status: 'Status',
        sim_group_work_interval: 'Work Interval(m)',
        sim_group_sleep_interval: 'Sleep Interval(s)',
        sim_group_imei_type: 'IMEI Type',
        sim_group_imei_random: 'IMEI Random',
        sim_group_schedule_mode: 'Schedule Mode',
        cycle: 'Cycle',
        daily: 'Daily',
        schedule:  'Schedule',
        sim_group_schedule_daily_period: 'Schedule Daily Period',
        sim_group_next_time: 'Next Time',

        // Navigation
        Statistics: 'Statistics',
        Outgoing_calls_statistics: 'Outgoing calls statistics',
        Detail_Records: "Detail Records",
        Call_Detail_Records: "Call Detail Records",
        Telegram_Users_Management: "Telegram Users Management",
        Telegram_Users: "Telegram Users",
        Telegram_Chats: "Telegram Chats",
        SMS_Detail_Records: "SMS Detail Records",
        Sms_Received_Records: "Received SMS-messages",
        Sms_Sent_Records: "Sent SMS-messages",
        USSD_Detail_Records: "USSD Detail Records",
        USSD_RESPONSE: "RESPONSE",
        Calls_Analytics: "Calls Analytics",
        Call_Analytics_by_the_time_periods: "Analytics by the time periods",
        CALLS_ANALYTICS: "CALLS ANALYTICS",
        PortsStateActivity: "Ports State Activity",

        // CallDetailsRecords page
        Caller_Name: "Caller Name",
        Caller_Number: "Caller (А)",
        Destination_Number: "Destination (B)",
        Start_Stamp: "Start Stamp",
        Duration: "Duration",
        Billsec: "Billsec",
        Billmin: "Billed minutes",
        Progress_Media_sec: "Progress Media Sec.",
        Call_Status: "Call Status",
        Hangup_Disposition: "Disposition",
        Call_Direction: "Call Direction",
        Incoming: "Incoming",
        Outgoing: "Outgoing",
        DialOut_Group: "Group",
        DialOut_Group_Capacity: "Group Capacity",
        Filter_Date: "Date",
        Range_separator: "To",
        Start_Date: "Start date",
        End_Date: "End date",
        Last_hour: "Last hour",
        Last_3_hours: "Last 3 hours",
        Last_6_hours: "Last 6 hours",
        Last_8_hours: "Last 8 hours",
        Last_12_hours: "Last 12 hours",
        Last_day: "Last day",
        Last_2_days: "Last 2 days",
        Last_3_days: "Last 3 days",
        Last_4_days: "Last 4 days",
        Last_5_days: "Last 5 days",
        Last_6_days: "Last 6 days",
        Last_7_days: "Last 7 days",
        Last_week: "Last week",
        Last_2_weeks: "Last_2_weeks",
        Last_month: "Last month",
        Last_3_months: "Last 3 months",

        // filters
        Filter: 'Filter',
        Filter_by_Call_Direction: 'Filter by Call Direction',
        Filter_by_Call_Status: 'Filter by Call Status',
        Filter_by_Mobile_Operator: 'Filter by Mobile Operator',
        Filter_by_Gsm_Gateway: 'Filter by Gsm Gateway',
        Filter_by_Gsm_Gateway_Port: 'Filter by Gsm Gateway Port',
        Search_by_Caller_Number: 'Search by Caller Number',
        Search_by_Destination_Number: 'Search by Destination Number',
        Search_by_SIM_IMSI_or_Number: 'Search by SIM IMSI or Number',

        // Countries
        country: 'country',
        Country: 'Country',
        countries: 'countries',
        Countries: 'Countries',

        // languages
        select_language: 'Select Language',
        english: 'English',
        russian: 'Russian',

        // Calls
        Calls: 'Calls',
        Calls_num: 'Calls',
        Calls_Total: 'Calls Total',
        Calls_Answered: 'Calls Answered',
        ACD: 'ACD',
        ASR: 'ASR',
        AQ: 'Audio Quality',
        sip_bye_from: 'BYE From',

        // SMS
        SMS: 'SMS',
        SMS_Messages: 'SMS',
        SIM_Number: "SIM Number",
        SMS_Sender: "SMS Sender",
        SMS_Text: "SMS content",
        Sent_at: 'Sent at',
        Sending_status: 'Sending status',
        Received_at: 'Received at',
        Delivered_at: 'Delivered at',
        Delivery_code: 'Delivery code',
        delivery_code: 'delivery code',
        Recipient: 'Recipient',
        // USSD
        USSD: 'USSD',
        USSD_COMMANDS: 'USSD COMMANDS',
        TELEGRAM_MANAGER: 'TELEGRAM MANAGER',
        INTEGRATIONS: 'INTEGRATIONS',
        USSD_command_execution_failed: 'USSD Command execution failed',
        USSD_command_successfully_completed: 'USSD Command successfully completed',
        USSD_command_response: 'Response',

        // integration service names
        Daazweb: 'Daazweb',

        // Time
        Minutes: 'Minutes',
        Last_1_hour: 'Last hour',
        Last_3_hour: 'Last 3 hours',
        Last_12_hour: 'Last 12 hours',
        Last_1_day: 'Last day',
        Last_1_month: 'Last month',
        Last_1_year: 'Last year',

        // Actions
        Actions: 'Actions',
        Add: 'Add',
        SIM_check_balance: "SIM Balance check",
        SIM_extract_number: "SIM Number extraction",
        SIM_edit_number: "Edit Sim Number",
        SIM_save_number: "Save Sim Number",
        SIM_edit_comment: "Edit Sim Comment",
        SIM_save_comment: "Save Sim Comment",
        Mark_SIM_as_unused: "mark SIM as unused",
        Mark_SIM_as_used: "mark SIM as used",
        Remove_sim_to_archive: "Remove SIM to archive",
        Remove_sim_from_archive: "Remove SIM from archive",
        Show_SIMs_archive: "Show SIMs archive",
        Hide_SIMs_archive: "Hide SIMs archive",
        changes_saved: 'Data saved',
        changes_not_saved: 'Cannot save changes',
        // SIM Bank Slot Statuses
        slot_status_idle: 'Idle',
        slot_status_busy: 'Busy',
        slot_status_inactive: 'Inactive',
        slot_status_disabled: 'Disabled',
        slot_status_online: 'Online',
        slot_status_offline: 'Offline',

        // Other
        Yes: 'Yes',
        No: 'No',
        Dashboard: 'Dashboard',
        Confirm: 'Подтвердить',
        Cancel: 'Cancel',
        Current: 'Current',
        Comment: 'Comment',
        At: 'at',
        Export: 'Export',
        Save: 'Save',
        Send: 'Send',
        Delete: 'Delete',
        Reset: 'Reset',
        Apply: 'Apply',
        Close: 'Close',
        Search: 'Search',
        Download: 'Download',
        Logout: 'Logout',
        Number: 'Number',
        Date: 'Date',
        Loading_Please_wait: 'Loading... Please wait',
        Please_wait: 'Please wait',
        Hide: 'Hide',
        Show_hidden_SIM: 'Show hidden',
        Copy: 'Copy to clipboard',
        Copy_done: 'Copied',
        Copy_error: 'Not copied',
        Unknown: 'Unknown',
        Unknown_male: 'Unknown',
        IsNotSet: 'Is not set',
        IsNotUsed: 'Is not used',

        // Metrics
        Averages: 'Averages',
        Answer_Seizure_Ratio: 'Answer Seizure Ratio',
        Average_Call_Duration: 'Average Call Duration (seconds)',
        Answered: 'Answered',
        Total: 'Total',
        // short
        sec: 'sec.',
        min: 'мин.',

        // Errors
        Cannot_execute_ussd_command: 'Cannot execute USSD-command',
        Cannot_get_ussd_command_response: 'Cannot get USSD-command response',
        Operation_not_supported: 'operation not supported',
        USSD_command_must_be_assigned: 'USSD-command must be assigned',

        New_token: 'New token',
        Add_token: 'Add token',
        ID : 'ID',
        Token : 'Token',
        Created_at : 'Created at',
        Updated_at : 'Updated at',
        Proceeded_at : 'Proceeded at',
        Tg_account_id : 'Tg account ID',
        Username : 'Username',
        First_name : 'First name',
        Last_name : 'Last name',
        Language_code : 'Language code',
        Direction: 'Direction',

        // Originators
        Originator: 'Originator',

        // Statistics
        Outgoing_calls_analytics: 'Outgoing calls analytics',
        Originator_calls_analytics: 'Originator analytics',
        total_calls_grouped_by_hours: 'Total calls count (grouped by hours)',
        total_calls_count_grouped_by_days: 'Total answered/failed calls count (grouped by days)',
        total_billed_minutes_grouped_by_days: 'Total billed minutes (grouped by days)',
        total_billed_minutes_grouped_by_hours: 'Total billed minutes (grouped by hours)',
        total_billed_minutes_grouped_by_months: 'Total billed minutes (grouped by months)',
        audio_quality_of_calls_grouped_by_days: 'Audio Quality of calls (grouped by days)',
        total_calls_grouped_by_sim_cards: 'The count of outgoing answered/failed calls (grouped by SIM-cards)',
        total_billed_minutes_grouped_sim_cards: 'Total billed minutes (grouped by SIM-cards)',
        dynamic_of_asr_on_sim_cards: 'The dynamic of ASR on SIM cards for the period',
        dynamic_of_acd_on_sim_cards: 'The dynamic of ASR on SIM cards for the period',
        distribution_of_calls_intensity_grouped_by_hours: 'The distribution of calls intensity (grouped by hours)',
        distribution_of_calls_and_minutes_grouped_by_hours: 'The distribution of answered/failed calls count and minutes (grouped by hours)',
        dynamic_of_changes_originator_asr_acd_by_hour: 'The dynamic of change ASR/ACD of the originator (grouped by the hour)',
        summary_statistics_for_the_selected_period: 'Summary statistics for the selected period',
        answered_calls: 'Answered calls (SIP 200)',
        canceled_calls: 'Canceled calls (SIP 487)',
        busy_calls: 'Busy calls (SIP 486)',
        unanswered_calls: 'Unanswered calls',
        other_calls: 'Other calls',
        total_calls: 'Calls total',
        minutes_billed: 'Minutes billed',
        low_quality_calls: 'Low quality calls',
        medium_quality_calls: 'Medium quality calls',
        high_quality_calls: 'High quality calls',
        Minutes_Total: 'Minutes Total',
        Type: 'Type',

        // Telegram
        tg_delete_chat_dialog: 'Are you sure you want to delete this token?',
        tg_new_chat_notification_settings: 'The notifications settings of new Telegram chat',
        tg_edit_chat_notification_settings: 'The notifications settings of Telegram chat',
        tg_notify_about_gsm_gateway_status: 'Notify about GSM Gateway status.',
        tg_notify_about_gsm_port_state: 'Notify about GSM Gateway ports states.',
        tg_notify_about_sim_replacement : 'Notify about SIM card inserting/replacement',
        tg_forward_incoming_sms: 'Forward incoming SMS messages to Telegram chat.',
        tg_incoming_sms_filter_by_sender: 'Enter senders whose SMS will be ignored (split by commas).',
        tg_incoming_sms_filter_by_sender_text_prompt: 'source1|source 2|source_3',
        tg_incoming_sms_filter_by_content_text_prompt: 'text1|text 2|text_3',
        tg_filter_is_blacklist: 'Is blacklist',
        // Daazweb
        daaz_delete_token_dialog: 'Are you sure you want to delete this token?',
        daaz_new_token_notification_settings: 'A new token adding',
        daaz_edit_token_notification_settings: 'The settings of token',
        daaz_boost: 'boost',
        // boost mode
        daaz_boost_mode: 'BOOST Mode',
        daaz_boost_mode_otk: 'otk',
        daaz_boost_mode_neotk: 'neotk',
        daaz_boost_mode_disabled: 'disabled',
        // boost status
        daaz_boost_status: 'BOOST Status',
        daaz_boost_status_active: 'Available',
        daaz_boost_status_boosted: 'Boosted',
        daaz_boost_status_forbidden: 'Forbidden',
        daaz_boost_status_off: 'Disabled',
        daaz_boost_status_need_enable_deals: 'Deals should be enabled',
        daaz_boost_status_max_used: 'The maximum BOOSTs today reached',
        daaz_boost_status_used_by_another_users: 'Used by another users',
        daaz_boost_status_until_boost_10000: 'You should complete orders to buy BTC in the amount of 10,000 RUB',
        daaz_boost_status_low_balance: 'Low balance to use BOOST',
        daaz_boost_status_processing: "Trying to enable BOOST...",
        daaz_filter_by_content: 'SMS Content filter',
        daaz_filter_by_seder: 'SMS Sender filter',
        // PortStateActivity
        psa_hide_gsm_logout_ports: 'Hide inactive ports',
        Activity_Monitor: 'Activity Monitor',

        // Login page localization
        login: "Login",
        login_username: 'Your e-mail',
        login_password: 'Password',
        login_invalid: 'Invalid e-mail or password',
        email_is_invalid: 'This e-mail is invalid',
        is_required_field: 'This field is required',
        please_login: 'Please login',

        // GsmGatewayManager page
        gw_description: 'Description',
        gw_alias: 'Alias',
        gw_serial_number: 'Serial Number',
        gw_login: 'Username',
        gw_password: 'Password',
        gw_sip_ip_port: 'SIP address and port',
        gw_net_ip_port: 'SMS address and port',
        gw_settings_title: 'General setting',
        gw_delete_gw_dialog: 'Are you sure you want to delete this GSM gateway?',
        gw_input_successfully_updated: 'Input has been updated to: ',
        gw_input_not_updated: 'Input has not been updated',

        // SimList
        sl_sim_number_successfully_updated: 'SIM card number has been updated to: ',
        sl_sim_number_not_updated: 'The SIM card number has not been updated. Please use digits only',
        sl_sim_comment_successfully_updated: 'SIM card comment has been updated to: ',
        sl_sim_comment_not_updated: 'The SIM card comment has not been updated',

        // SimManagement
        sm_sim_calls_statistics: 'Outgoing Calls Statistics',
        sm_sim_card_information: 'SIM Card Info',
        sm_sim_move_to_archive: 'Move to archive',
        sm_sim_group_binding_status: 'None',
        // SimCard
        A: 'A',
        A_Cancel: 'A (Cancel)',
        B: 'B',
        B_Refuse: 'B (Refuse)',
        Refuse: 'Refuse',
        //phone
        phone: 'Phone',
        number: 'Number',
        call: 'Call',
        answer: 'Answer',
        hangup: 'Hangup',
        hold: 'Hold',
        unhold: 'Unhold',
        register: 'Register',
        unregister: 'Unregister',
        no_sim: 'SIM Card Not Chosen',
        sim_to_call: 'SIM Card For Web Call',
        not_sim_to_call: 'Choose SIM Card For Web Call',
    }
};
