export const RUSSIAN_TRANSLATIONS = {
    text: {
        AppTitle: 'GoIPZilla',
        // GSM шлюз
        GSM_gateway: 'GSM шлюз',
        GSM_Gateway: 'GSM Шлюз',
        GSM_Gateways: 'GSM Шлюзы',
        GSM_Gateways_Select: 'GSM Шлюзы',
        GSM_Gateway_and_port: 'GSM Шлюз (порт)',
        GSM_GATEWAYS: 'GSM ШЛЮЗЫ',
        GSM_Port: 'GSM Порт',
        GSM_Ports: 'GSM Порты',
        GSM_Port_Management: 'Управление GSM портами',
        GSM_Groups: 'GSM Группы',
        GSM_Group: 'GSM Группа',
        GSM_Status: 'GSM Статус',
        Group_code: 'Код группы',
        Group_name: 'Имя группы',
        No_ports: 'Свободных портов нет',
        NO_AVAILABLE_PORTS: 'НЕТ ДОСТУПНЫХ ПОРТОВ',
        All: 'Все',
        AvailablePorts: 'Доступные порты',
        ActivePorts: 'Подключенные порты',
        New_group: 'Новая группа',
        Description: 'Название',
        Network_Availability_Status: "Статус доступности по IP-сети",
        VoIP_Registration_Status: "Статус VoIP-регистрации",
        VoIP_Ping_Status: "VoIP-ping статус",
        // Порт
        Port: 'Порт',
        port: 'порт',
        port_display_number: 'Номер абонента', // не планирую использовать как самостоятельное поле
        port_signal_level: 'Уровень сигнала',
        port_gsm_status: 'GSM статус',
        port_voip_status: 'VOIP статус',
        port_voip_state: 'Состояние',
        port_idle: 'Длительность простоя',
        port_remain_time: 'Задержка',
        port_power: 'Статус питания',
        // Статус питания порта
        port_power_on: 'Включен',
        port_power_off: 'Выключен',
        // Статус порта
        port_voip_status_login: 'Зарегистрирован',
        port_voip_status_logout: 'Не зарегистрирован',
        port_voip_status_failed: 'Ошибка регистрации', //@TODO для новых прошивок (коды 401, 403 и т.д.)
        // Состояние порта
        //@TODO отображать в панели как 'Исходящий вызов на $display_number' или 'Разговаривает с $display_number'
        port_voip_state_idle: 'СВОБОДЕН',
        port_voip_state_loading: 'ЗАГРУЗКА',
        port_voip_state_dialing: 'НАБОР:',
        port_voip_state_alerting: 'ИСХОДЯЩИЙ НА:',
        port_voip_state_connected: 'РАЗГОВОР С:',
        port_voip_state_active: 'АКТИВЕН (занят)',
        port_voip_state_incoming: 'ВХОДЯЩИЙ ОТ:',
        port_voip_state_unknown: 'НЕИЗВЕСТЕН',
        // Сим карты
        SIM: "SIM",
        sim_card: 'SIM Карта',
        SIM_Card: 'SIM Карта',
        SIM_CARDS: 'SIM КАРТЫ',
        sim_cards: 'SIM Карты',
        Sim_Cards_List: 'Список SIM-Карт',
        Sim_Card_Management: 'Управление SIM-картами',
        sim_number: 'Номер SIM-карты',
        sim_imei: 'IMEI',
        sim_imsi: 'IMSI',
        SIM_IMSI: 'SIM IMSI',
        sim_iccid: 'SIM ICCID',
        SIM_Group: 'SIM Группа',
        SIM_Group_Capacity: 'Ёмкость SIM Группы',
        SIM_card_not_installed: 'SIM-карта отсутствует',
        sim_mobile_network: 'Мобильная сеть',
        sim_mobile_operator: 'Мобильный оператор (SIM)',
        sim_gsm_login: 'Статус в GSM сети',
        Mobile_operator: 'Мобильный оператор',
        Balance: 'Баланс',
        Balance_no_data: 'Отсутствует информация о балансе',
        Extracted_from: 'Получено из',
        Extracted: 'Получено из',
        Lock_SIM_for_outgoing_calls: 'Заблокировать SIM-карту для исходящих вызовов',
        Lock_SIM_for_outgoing_sms: 'Заблокировать SIM-карту для исходящих SMS-сообщений',
        Unlock_SIM_for_outgoing_calls: 'Разблокировать SIM-карту для исходящих вызовов',
        Unlock_SIM_for_outgoing_sms: 'Разблокировать SIM-карту для исходящих SMS-сообщений',
        Method: 'Метод',
        bank_cards: 'Банковские карты',

        // SIM Bank
        id: 'ID',
        sim_bank: 'SIM Банк',
        slot: 'Слот',
        sim_bank_name: 'Имя SIM Банка',

        // SIM Группы
        sim_group: 'SIM Группа',
        sim_groups: 'SIM Группы',
        sim_group_name: 'Имя группы',
        sim_group_active: 'Активна',
        sim_group_inactive: 'Не активна',
        sim_group_work_interval: 'Интервал работы (мин.)',
        sim_group_sleep_interval: 'Интервал сна (сек.)',
        sim_group_status: 'Статус',
        sim_group_imei_type: 'Тип IMEI',
        sim_group_imei_random: 'Случайный IMEI',
        sim_group_schedule_mode: 'Режим работы',
        cycle: 'Циклический',
        daily: 'Ежедневно',
        schedule: 'По расписанию',
        sim_group_schedule_daily_period: 'Период запуска',
        sim_group_next_time: 'Следующий запуск',

        // Навигация
        Statistics: 'Статистика',
        Outgoing_calls_statistics: 'Статистика по исходящим вызовам',
        Detail_Records: "Детализация",
        Call_Detail_Records: "Детализация вызовов",
        Telegram_Users_Management: "Управление Telegram Пользователями",
        Telegram_Users: "Telegram Пользователи",
        Telegram_Chats: "Telegram Группы",
        SMS_Detail_Records: "Детализация SMS-сообщений",
        Sms_Received_Records: "Принятые SMS-сообщения",
        Sms_Sent_Records: "Отправленные SMS-сообщения",
        USSD_Detail_Records: "Детализация USSD-команд",
        USSD_RESPONSE: "ПОЛУЧЕН ОТВЕТ",
        Calls_Analytics: "Аналитика по звонкам",
        Call_Analytics_by_the_time_periods: "Аналитика по временным периодам",
        CALLS_ANALYTICS: "АНАЛИТИКА ПО ЗВОНКАМ",
        PortsStateActivity: "Ports State Activity",

        // Страница детализации вызовов
        Caller_Name: "Имя звонящего",
        Caller_Number: "Инициатор (А)",
        Destination_Number: "Вызываемый (Б)",
        Start_Stamp: "Дата вызова",
        Duration: "Длительность вызова",
        Billsec: "Длительность разговора",
        Billmin: "Потрачено минут",
        Progress_Media_sec: "Длительность ожидания",
        Call_Status: "Статус вызова",
        Call_Direction: "Направление вызова",
        Incoming: "Входящий",
        Outgoing: "Исходящий",
        Hangup_Disposition: "Кто повесил трубку",
        DialOut_Group: "Группа",
        DialOut_Group_Capacity: "Ёмкость группы",
        Filter_Date: "Дата",
        Range_separator: "Пo",
        Start_Date: "Начало",
        End_Date: "Окончание",
        Last_hour: "За час",
        Last_3_hours: "За 3 часа",
        Last_6_hours: "За 6 часов",
        Last_8_hours: "За 8 часов",
        Last_12_hours: "За 12 часов",
        Last_day: "За день",
        Last_2_days: "За 2 дня",
        Last_3_days: "За 3 дня",
        Last_4_days: "За 4 дня",
        Last_5_days: "За 5 дней",
        Last_6_days: "За 6 дней",
        Last_7_days: "За 7 дней",
        Last_week: "За неделю",
        Last_2_weeks: "За 2 недели",
        Last_month: "За месяц",
        Last_3_months: "За 3 месяца",

        // Фильтры
        Filter: 'Фильтр',
        Filter_by_Call_Direction: 'Фильтр по направлению вызова',
        Filter_by_Call_Status: 'Фильтр по статусу вызова',
        Filter_by_Mobile_Operator: 'Фильтр по мобильному оператору',
        Filter_by_Gsm_Gateway: 'Фильтр по GSM Шлюзу',
        Filter_by_Gsm_Gateway_Port: 'Фильтр по номеру GSM порта',
        Search_by_Caller_Number: 'Поиск по номеру звонящего',
        Search_by_Destination_Number: 'Поиск по вызываемому номеру',
        Search_by_SIM_IMSI_or_Number: 'Поиск по IMSI или номеру',

        // Countries
        country: 'страна',
        Country: 'Страна',
        countries: 'страны',
        Countries: 'Страны',

        // languages
        select_language: 'Язык интерфейса',
        english: 'Английский',
        russian: 'Русский',

        // Calls
        Calls: 'Звонки',
        Calls_num: 'Вызовов',
        Calls_Total: 'Вызовов всего',
        Calls_Answered: 'Вызовов отвечено',
        ACD: 'ACD',
        ASR: 'ASR',
        AQ: 'Качество аудио',
        sip_bye_from: 'Завершил',

        // SMS
        SMS: 'SMS',
        SMS_Messages: 'SMS-сообщения',
        SIM_Number: 'SIM Номер',
        SMS_Sender: 'Отправитель',
        SMS_Text: 'Текст SMS-сообщения',
        Sent_at: 'Дата и время отправки',
        Sending_status: 'Статус отправки',
        Received_at: 'Дата и время получения',
        Delivered_at: 'Дата и время доставки',
        Delivery_code: 'Код доставки',
        delivery_code: 'код доставки',
        Recipient: 'Получатель',
        // USSD
        USSD: 'USSD',
        USSD_COMMANDS: 'USSD КОМАНДЫ',
        TELEGRAM_MANAGER: 'TELEGRAM МЕНЕДЖЕР',
        INTEGRATIONS: 'ИНТЕГРАЦИИ',
        USSD_command_execution_failed: 'Не удалось выполнить USSD запрос',
        USSD_command_successfully_completed: 'USSD запрос успешно выполнен',
        USSD_command_response: 'Получен ответ',

        // integration service names
        Daazweb: 'Daazweb',

        // Time
        Minutes: 'Минуты',
        Last_1_hour: 'За последний час',
        Last_3_hour: 'За последние 3 часа',
        Last_12_hour: 'За последние 12 часов',
        Last_1_day: 'За последние сутки',
        Last_1_month: 'За последний месяц',
        Last_1_year: 'За последний год',

        // Actions
        Actions: 'Действия',
        Add: 'Добавить',
        SIM_check_balance: "Проверить баланс",
        SIM_extract_number: "Извлечь номер",
        SIM_edit_number: "Изменить номер",
        SIM_save_number: "Сохранить номер",
        SIM_edit_comment: "Изменить комментарий",
        SIM_save_comment: "Сохранить комментарий",
        Remove_sim_to_archive: "Убрать SIM-карту в архив",
        Remove_sim_from_archive: "Убрать SIM-карту из архива",
        Show_SIMs_archive: "Показать архив SIM-карт",
        Hide_SIMs_archive: "Скрыть архив SIM-карт",
        changes_saved: 'Изменения сохранены',
        changes_not_saved: 'Ну удалось сохранить изменения',
        // SIM Bank Slot Statuses
        slot_status_idle: 'Бездействует',
        slot_status_busy: 'Занят',
        slot_status_inactive: 'Не активен',
        slot_status_disabled: 'Выключен',
        slot_status_online: 'Онлайн',
        slot_status_offline: 'Офлайн',

        // Другое
        Yes: 'Да',
        No: 'Нет',
        Dashboard: 'Дашборд',
        Confirm: 'Подтвердить',
        Cancel: 'Отменить',
        Current: 'Текущий',
        Comment: 'Коментарий',
        At: 'с',
        Export: 'Экспорт',
        Save: 'Сохранить',
        Send: 'Отправить',
        Delete: 'Удалить',
        Reset: 'Сбросить',
        Apply: 'Применить',
        Close: 'Закрыть',
        Search: 'Поиск',
        Download: 'Скачать',
        Logout: 'Выйти',
        Number: 'Номер',
        Date: 'Дата',
        Loading_Please_wait: 'Получаю данные... Пожалуйста ожидайте',
        Please_wait: 'Пожалуйста ожидайте',
        Hide: 'Скрыть',
        Show_hidden_SIM: 'Показать скрытые',
        Copy: 'Скопировать в буфер',
        Copy_done: 'Скопировано',
        Copy_error: 'Не скопировано',
        Unknown: 'Неизвестно',
        Unknown_male: 'Неизвестен',
        IsNotSet: 'Не назначена',
        IsNotUsed: 'Не используется',

        // Metrics
        Averages: 'Усредненные',
        Answer_Seizure_Ratio: 'Процент успешных вызовов',
        Average_Call_Duration: 'Средняя длительность вызова (секунды)',
        Answered: 'Отвечено',
        Total: 'Всего',
        // short
        sec: 'сек.',
        min: 'min.',

        // Errors
        Cannot_execute_ussd_command: 'Не удалось выполнить USSD-команду',
        Cannot_get_ussd_command_response: 'Не удалось получить ответ на USSD-команду',
        Operation_not_supported: 'Операция не поддерживается',
        USSD_command_must_be_assigned: 'USSD-команда должна быть назначена',

        Token : 'Токен',
        New_token: 'Новый токен',
        Add_token: 'Добавить токен',
        ID : 'ID',
        Created_at : 'Дата создания',
        Updated_at : 'Дата изменения',
        Proceeded_at : 'Дата присвоения',
        Tg_account_id : 'ID пользователя',
        Username : 'Имя пользователя',
        First_name : 'Имя',
        Last_name : 'Фамилия',
        Language_code : 'Код языка',
        Direction: 'Направление',

        // Originators
        Originator: 'Оригинатор',

        // Statistics
        Outgoing_calls_analytics: 'Аналитика по исходящим вызовам',
        Originator_calls_analytics: 'Аналитика по оригинаторам',
        total_calls_grouped_by_hours: 'Общее количество вызовов (сгруппированно по часам)',
        total_calls_count_grouped_by_days: 'Общее количество отвеченных/не отвеченных вызовов (сгруппированно по дням)',
        total_billed_minutes_grouped_by_days: 'Общее количество минут (сгруппированно по дням)',
        total_billed_minutes_grouped_by_hours: 'Общее количество минут (сгруппированно по часам)',
        total_billed_minutes_grouped_by_months: 'Общее количество минут (сгруппированно по месяцам)',
        audio_quality_of_calls_grouped_by_days: 'Распределение качества аудио вызовов (сгруппированно по дням)',
        total_calls_grouped_by_sim_cards: 'Количество исходящих отвеченных/не отвеченных вызовов за период (сгруппированно по SIM-картам)',
        total_billed_minutes_grouped_sim_cards: 'Общее количество минут за период (сгруппированно по SIM-картам)',
        dynamic_of_asr_on_sim_cards: 'Динамика изменения ASR на SIM-картах за период',
        dynamic_of_acd_on_sim_cards: 'Динамика изменения ACD на SIM-картах за период',
        distribution_of_calls_intensity_grouped_by_hours: 'Интенсивность вызовов (сгруппированно по часам)',
        distribution_of_calls_and_minutes_grouped_by_hours: 'Распределение отвеченных/не отвеченных вызовов и минут (сгруппированно по часам)',
        dynamic_of_changes_originator_asr_acd_by_hour: 'Динамика изменения ASR/ACD оригинатора (сгруппированно по часам)',
        summary_statistics_for_the_selected_period: 'Сводная статистика за выбранный период',
        answered_calls: 'Вызовы с ответом (SIP 200)',
        canceled_calls: 'Вызов отменён (SIP 487)',
        busy_calls: 'Занято (SIP 486)',
        unanswered_calls: 'Вызовы без ответа',
        other_calls: 'Другие',
        total_calls: 'Всего вызовов',
        minutes_billed: 'Потрачено минут',
        low_quality_calls: 'Вызовы низкого качества',
        medium_quality_calls: 'Вызовы среднего качества',
        high_quality_calls: 'Вызовы высокого качества',
        Minutes_Total: 'Минут всего',
        Type: 'Тип',

        // Telegram
        tg_delete_chat_dialog: 'Вы уверены, что хотите удалить этот токен?',
        tg_new_chat_notification_settings: 'Настройки уведомлений новой Telegram группы',
        tg_edit_chat_notification_settings: 'Настройки уведомлений Telegram группы',
        tg_notify_about_gsm_gateway_status: 'Уведомлять о изменении статуса GSM-шлюза.',
        tg_notify_about_gsm_port_state: 'Уведомлять о изменении состояния портов GSM-шлюза.',
        tg_notify_about_sim_replacement: 'Уведомлять о вставке/замене SIM-карты',
        tg_forward_incoming_sms: 'Перенаправлять входящие SMS-сообщения в Telegram группу.',
        tg_incoming_sms_filter_by_sender: 'Список источников входящие SMS-сообщения от которых буду (split by commas).',
        tg_incoming_sms_filter_by_sender_text_prompt: 'источник1|источник 2|источник_3',
        tg_incoming_sms_filter_by_content_text_prompt: 'текст1|текст 2|текст_3',
        tg_filter_is_blacklist: 'Чёрный список',
        // Daazweb
        daaz_delete_token_dialog: 'Вы уверены, что хотите удалить этот токен?',
        daaz_new_token_notification_settings: 'Добавление нового токена',
        daaz_edit_token_notification_settings: 'Настройки токена',
        daaz_boost: 'boost',
        daaz_boost_mode: 'Режим BOOST',
        daaz_boost_mode_otk: 'За откупы',
        daaz_boost_mode_neotk: 'Без откупов',
        daaz_boost_mode_disabled: 'Выключен',
        // boost status
        daaz_boost_status: 'Статус BOOST',
        daaz_boost_status_active: 'Доступен',
        daaz_boost_status_boosted: 'Активен',
        daaz_boost_status_forbidden: 'Недоступен',
        daaz_boost_status_off: 'Выключен',
        daaz_boost_status_need_enable_deals: 'Необходимо включить сделки, чтобы использовать',
        daaz_boost_status_max_used: 'Вы использовали максимальное количество BOOST сегодня',
        daaz_boost_status_used_by_another_users: 'Использует максимальное количество человек на данный момент',
        daaz_boost_status_until_boost_10000: 'Вам нужно выполнить заявок на покупку BTC на сумму 10 000 RUB',
        daaz_boost_status_low_balance: 'Низкий баланс для использования',
        daaz_boost_status_processing: "Пытаемся включить BOOST...",
        daaz_filter_by_content: 'Фильтр по содержимому SMS',
        daaz_filter_by_seder: 'Фильтр по отправителю SMS',
        // PortStateActivity
        psa_hide_gsm_logout_ports: 'Скрыть не активные порты',
        Activity_Monitor: 'Монитор Активности',

        // Login page localization
        login: "Войти",
        login_username: 'Ваш e-mail',
        login_password: 'Пароль',
        login_invalid: 'Не корректные e-mail или пароль',
        email_is_invalid: 'Не корректный e-mail',
        is_required_field: 'Это обязательное поле',
        please_login: 'Пожалуйста авторизуйтесь',

        // GsmGatewayManager page
        gw_description: 'Описание',
        gw_alias: 'Псевдоним',
        gw_serial_number: 'Серийный номер',
        gw_login: 'Логин',
        gw_password: 'Пароль',
        gw_sip_ip_port: 'SIP адрес и порт',
        gw_net_ip_port: 'SMS адрес и порт',
        gw_settings_title: 'Основные настройки',
        gw_delete_gw_dialog: 'Вы уверены, что хотите удалить этот GSM шлюз?',
        gw_input_successfully_updated: 'Поле изменено на: ',
        gw_input_not_updated: 'Некорректный формат',

        // SimList
        sl_sim_number_successfully_updated: 'Номер SIM-карты изменён на: ',
        sl_sim_number_not_updated: 'Некорректный формат номера SIM карты. Пожалуйста, используйте только цифры',
        sl_sim_comment_successfully_updated: 'Комментарий SIM-карты изменён на: ',
        sl_sim_comment_not_updated: 'Комментарий не сохранен',

        // SimManagement
        sm_sim_calls_statistics: 'Статистика по исходящим вызовам',
        sm_sim_card_information: 'Инфорамация по SIM-карте',
        sm_sim_move_to_archive: 'Убрать в архив',
        sm_sim_group_binding_status: 'Не назначена',
        // SimCard
        A: 'A',
        A_Cancel: 'A (Отмена)',
        B: 'Б',
        B_Refuse: 'Б (Отказ)',
        Refuse: 'Refuse',
        //phone
        phone: 'Телефон',
        number: 'Номер',
        call: 'Вызов',
        answer: 'Ответвет',
        hangup: 'Отбой',
        hold: 'Удержание',
        unhold: 'Снять удержание',
        register: 'Вкл. входящие',
        unregister: 'Откл. входящие',
        no_sim: 'SIM Карта Не Выбрана',
        sim_to_call: 'SIM Карта Для Веб Звонка',
        not_sim_to_call: 'Выбрать SIM Карту Для Веб Звонка',
    }
};
