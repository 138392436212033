<template>
  <v-tooltip
    open-delay="800"
    close-delay="800"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mr-2"
        :color="color()"
        :small="small"
        :large="large"
        :size="size"
        v-bind="attrs"
        v-on="on"
        @click="action()"
      >
        {{ icon() }}
      </v-icon>
    </template>
    <span> {{ tooltip() }} </span>
  </v-tooltip>
</template>

<script>
    import lockItem from '../../../mixins/LockItem'

    export default {
        name: "LockItemButton",
        mixins: [ lockItem ],
        props: {
            resource: {
              type: String
            },
            lock_state: {
              type: Boolean,
            },
            sim_imsi: {
              type: String
            },
            gsm_group: {
              type: Number
            },
            gsm_gateway: {
              type: Number
            },
            gsm_gateway_port: {
              type: Number
            },
            small: {
                type: Boolean,
            },
            large: {
                type: Boolean,
            },
            size: {
              type: Number,
            }
        },
        methods: {
            color() {
                if (this.lock_state === true) {
                    return "red"
                }
                return "green"
            },
            action() {
              this.lockItem(this.resource, !this.lock_state, this.sim_imsi, undefined, undefined, undefined)
              .then(response => {
                // console.log('response: ', response);
                if (response.status === 200) {
                  this.$emit('switch-sim-card-lock-state', this.sim_imsi, this.resource, !this.lock_state);
                }
              })
              .catch(e => {
                console.error(e)
              });
            },
            icon() {
                if (this.resource === "calls") {
                  return "mdi-phone-lock"
                } else if (this.resource === "sms") {
                  return "mdi-email-lock"
                }
                return "mdi-help-circle"
            },
            tooltip() {
              if (this.resource === "calls") {
                if (this.lock_state === false) {
                  return this.$t('text.Lock_SIM_for_outgoing_calls')
                }
                return this.$t('text.Unlock_SIM_for_outgoing_calls')
              } else if (this.resource === "sms") {
                if (this.lock_state === false) {
                  return this.$t('text.Lock_SIM_for_outgoing_sms')
                }
                return this.$t('text.Unlock_SIM_for_outgoing_sms')
              }
              return ""
            }
        }
    }
</script>

<style scoped>

</style>