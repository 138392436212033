import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import FlagRu from '../components/svg/flags/ru';
import FlagEn from '../components/svg/flags/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#172B4D',
      },
    },
  },
  icons: {
    values: {
      ru: {
        component: FlagRu,
      },
      en: {
        component: FlagEn,
      },
      telegramSvgPath: (require('simple-icons/icons/telegram')).path,
    },
  },
});
