import { mapFields } from 'vuex-map-fields';

export default {
  data: () => ({
    top: 0,
    fixHeight: 0,
    itemsPerPageSelected: null,
    itemsPerPageOption: null,
    containerTop: 0,
    containerHeight: 0,
  }),
  async mounted() {
    await this.$nextTick();
    this.initObservers();
  },
  computed: {
    ...mapFields('tabs', [
      'tab',
    ]),
    containerHeightStyle() {
      // if (this.itemsPerPageSelected === -1) {
      //   return {};
      // }

      let adjust = 0;

      return {
        height: `calc(100vh - ${this.containerTop + adjust}px)`,
      };
    },
    itemsPerPage: {
      get() {
        let baseH = 110;
        const elH = 48;

        if (this.heightCalcVariant === 'dashboard') {
          baseH = 0;
        }

        const items = () => {
          let itemsPerPage = 1;
          for (const n of [...Array(100).keys()]) {
            if (n > 0
              && this.fixHeight > n * elH + baseH) {
              itemsPerPage = n;
            }
          }
          return itemsPerPage;
        };

        this.itemsPerPageOption = items();

        if (this.itemsPerPageSelected === -1) {
          return this.itemsPerPageSelected;
        }

        return items();
      },
      set(value) {
        this.itemsPerPageSelected = value;
      },
    },
  },
  methods: {
    initObservers() {
      const fixHeightEl = this.$refs.fixHeight;
      if (fixHeightEl) {
        this.setFixHeight(fixHeightEl);
        const ro = new ResizeObserver(entries => {
          this.setFixHeight(entries[0]);
        });
        ro.observe(fixHeightEl);
      }

      const containerHeightEl = this.$refs.containerHeight;
      if (containerHeightEl) {
        this.setContainerHeight(containerHeightEl);
        const roContainer = new ResizeObserver(entries => {
          this.setContainerHeight(entries[0]);
        });
        roContainer.observe(containerHeightEl);
      }
    },
    setFixHeight(entry) {
      console.log('xxx setFixHeight - this.$refs.fixHeight', this.$refs.fixHeight);
      if (!this.$refs.fixHeight) {
        return false;
      }
      const elCr = this.$refs.fixHeight.getBoundingClientRect();
      const entryCr = entry && entry.contentRect ? entry.contentRect : elCr;

      if (window.innerHeight < elCr.y + 64) {
        this.itemsPerPageSelected = -1;
        return false;
      }

      this.top = elCr.y;
      this.fixHeight = entryCr.height;
      // console.log('xxx setFixHeight - window.innerHeight, this.top, this.fixHeight:', window.innerHeight, this.top, this.fixHeight);
    },
    setContainerHeight(entry) {
      console.log('xxx setContainerHeight - this.$refs.containerHeight', this.$refs.containerHeight);
      if (!this.$refs.containerHeight) {
        return false;
      }
      const elCr = this.$refs.containerHeight.getBoundingClientRect();
      const entryCr = entry && entry.contentRect ? entry.contentRect : elCr;

      this.containerTop = elCr.y;
      this.containerHeight = entryCr.height;
      // console.log('xxx setContainerHeight - window.innerHeight, this.containerTop, this.containerHeight:', window.innerHeight, this.containerTop, this.containerHeight);
    },
  },
  watch: {
    async tab() {
      await this.$nextTick();
      this.initObservers();
    },
  },
};
