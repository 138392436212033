import {apiCall} from "../../components/api/v1/calls";

export default {
    name: "mCountries",
    methods: {
        getCustomerSimCountries () {
            return new Promise(function(resolve, reject) {
                apiCall({endpoint: '/customer/getSimCountries', method: 'GET', data: null})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot get countries: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot get countries: ", e);
                    reject(e)
                })
            })
        },
    }
}