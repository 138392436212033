<template>
  <v-layout
    row
    wrap
    align-center
    :key="$root.$i18n.locale"
  >
    <v-card width="100%">
      <v-tabs
        v-model="tab"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="b-overflow-visible">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <v-card flat>
            <v-card-text class="pb-0">
              <component
                :is="item.content"
                :key="tab"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-layout>
</template>

<script>
    import SmsSentRecords from "./SmsSentRecords";
    import SmsRecvRecords from "./SmsRecvRecords";
    import { mapFields } from "vuex-map-fields";

    export default {
        name: "SmsDetails",
        components: { SmsRecvRecords, SmsSentRecords },
      computed: {
        ...mapFields('tabs', [
          'tab',
        ]),
         items() {
           return [
             {tab: this.$t('text.Sms_Received_Records'), content: 'SmsRecvRecords'},
             {tab: this.$t('text.Sms_Sent_Records'), content: 'SmsSentRecords'},
           ]
         }
      }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        padding: 4px;
    }
</style>
