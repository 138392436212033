<template>
  <span v-if="!editable">
    {{ newNumber }}
  </span>
  <span
    class="d-inline-flex align-center"
    v-else
  >
    <input
      ref="input"
      v-model.trim="$v.newNumber.$model"
      class="b-table-input"
      :class="{_error: $v.newNumber.$error}"
      type="text"
      :readonly="!editing"
      :style="{width: inputWidth}"
      @keyup.enter="saveNumber"
    >
    <span
      ref="mirror"
      class="b-table-input-width"
      aria-hidden="true"
    >{{ widthValue }}</span>
    <v-tooltip
      open-delay="800"
      close-delay="800"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          :color="color"
          :loading="updating"
          x-small
          v-bind="attrs"
          v-on="on"
          @click="saveNumber"
        >
          <v-icon>
            {{ icon }}
          </v-icon
            >
        </v-btn>
      </template>
      <span> {{ tooltipText }} </span>
    </v-tooltip>
  </span>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { POSITION } from 'vue-toastification';
import mSimCards from '../../../mixins/customer/mSimCards';

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 8,
  timeout: 10000,
};

const isPhoneNumber = value => /^\+?\d+$/.test(value); // "+11111" or "1111"

export default {
  name: 'SimNumber',
  mixins: [ mSimCards],
  props: {
    sim_imsi: {
      type: String,
      default: '',
      required: true
    },
    number: {
      type: String,
      default: '',
      required: true
    },
    editable: Boolean,
  },
  data() {
    return {
      editing: false,
      updating: false,
      newNumber: null,
      inputWidth: 'auto',
    };
  },
  computed: {
    widthValue() {
      const length = !this.newNumber
        ? 0
        : this.newNumber.length;
      const min = 12;
      const max = 16;
      const dString = '8';
      return length < min
        ? dString.repeat(min)
        : length > max
          ? dString.repeat(max)
          : this.newNumber;
    },
    icon() {
      return this.editing
        ? !this.$v.newNumber.$error
          ? 'mdi-check'
          : 'mdi-close'
        : 'mdi-pencil';
    },
    color() {
      return !this.$v.newNumber.$error
        ? 'success'
        : 'error';
    },
    tooltipText() {
      return this.editing ? this.$t('text.SIM_save_number') : this.$t('text.SIM_edit_number');
    },
  },
  validations: {
    newNumber: {
      required,
      isPhoneNumber,
    },
  },
  watch: {
    async newNumber() {
      await this.$nextTick();
      const mirror = this.$refs.mirror;
      const w = mirror && mirror.getBoundingClientRect().width;
      this.inputWidth = w && w > 0
        ? `${w + 4}px`
        : 'auto';
    },
  },
  created() {
    this.newNumber = this.number && this.number.toLowerCase() !== 'unknown'
      ? this.number
      : '';
  },
  methods: {
    saveNumber() {
      if (this.editing) {
        // Save new value if it is validated.
        if (!this.$v.newNumber.$error) {
          this.updating = true;
          this.setCustomerSimCardNumber(this.sim_imsi, this.newNumber)
          .then(r => {
            // nothing to do
            /*
            this.$toast(this.$t('text.sl_sim_number_successfully_updated') + this.newNumber, {
              ...toastOptions,
              type: 'success',
            });
             */
          })
          .catch(e => {
            this.newNumber = this.number;
            this.$toast(this.$t('text.sl_sim_number_not_updated'), {
              ...toastOptions,
              type: 'error',
            });
          })
          .finally(() => this.updating = false);
        } else {
          this.newNumber = this.number;
          this.$toast(this.$t('text.sl_sim_number_not_updated'), {
            ...toastOptions,
            type: 'error',
          });
        }
      } else {
        this.$refs.input.focus();
      }

      this.editing = !this.editing;
    },
  },
};
</script>
