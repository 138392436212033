<template>
  <v-tooltip
    open-delay="800"
    close-delay="800"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mr-2"
        :color="color(sim['gsm_login'])"
        :large="large"
        :small="small"
        :size="size"
        v-bind="attrs"
        v-on="on"
      >
        {{ icon(sim['gsm_login']) }}
      </v-icon>
    </template>
    <span> {{ tooltip(sim) }} </span>
  </v-tooltip>
</template>

<script>
    export default {
        name: "SimGsmLoginIndicator",
        props: {
            sim: {
              type: Object,
              default: function () {
                return undefined
              }
            },
            large: {
              type: Boolean
            },
            small: {
              type: Boolean
            },
            size: {
              type: Number
            },
        },
        methods: {
          color(status) {
            if (status === 'LOGIN') {
              return "success"
            }
            return "gray"
          },
          icon(status) {
            if (status === 'LOGIN') {
              return "mdi-signal"
            }
            return "mdi-signal-off"
          },
          tooltip(sim) {
            // @TODO add gw:port in tooltip here
            if (sim['gsm_login'] === 'LOGIN'){
              return sim['gsm_login']
            }
            return sim['gsm_login']
          }
        }
    }
</script>

<style scoped>

</style>