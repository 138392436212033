<template>
  <v-card
      width="100%"
      outlined
  >
    <v-card-subtitle>
      GsmGatewayPorts
    </v-card-subtitle>
    <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        :loading="status === 'loading'"
        dense
    >
      <template
              v-slot:[`header.gsm_gateway.username`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
              v-slot:[`header.voip_reg`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
              v-slot:[`header.voip_ping`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
              v-slot:[`header.gsm_gateway.voip_net_ip_port`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
              v-slot:[`header.net_status`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
          v-slot:[`header.ports.total_ports`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
          v-slot:[`header.ports.gsm_login_ports`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
          v-slot:[`header.ports.voip_login_ports`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
          v-slot:[`header.ports.sms_login_ports`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template
          v-slot:[`header.ports.smb_login_ports`]="{ header }"
      >
        <div v-html="header.text"></div>
      </template>
      <template v-slot:[`item.voip_reg`]="{ item }">
        <div v-if="item.gsm_gateway.voip_reg_status === 'REGISTERED' && item.gsm_gateway.voip_reg_code === 200" :class="['_no-wrap', '_accent']">
          {{item.gsm_gateway.voip_reg_status}}
        </div>
        <div v-else-if="item.gsm_gateway.voip_reg_status === 'UNREGISTERED' && item.gsm_gateway.voip_reg_code > 200" :class="['_no-wrap', '_warning']">
        {{item.gsm_gateway.voip_reg_status}} ({{item.gsm_gateway.voip_reg_code}})
      </div>
        <div v-else :class="['_no-wrap', '_disabled']">
          {{item.gsm_gateway.voip_reg_status}}
        </div>
      </template>
      <template v-slot:[`item.voip_ping`]="{ item }">
        <div :class="[item.gsm_gateway.voip_ping_status === 'REACHABLE' ? '_accent' : '_disabled']">
          {{item.gsm_gateway.voip_ping_status}}
        </div>
      </template>
      <template v-slot:[`item.gsm_gateway.voip_net_ip_port`]="{ item }">
        <div v-if="item.gsm_gateway.voip_net_ip_port === 'UNKNOWN'" :class="['_disabled']">
          {{item.gsm_gateway.voip_net_ip_port}}
        </div>
        <div v-else>
          {{item.gsm_gateway.voip_net_ip_port}}
        </div>
      </template>
      <template v-slot:[`item.net_status`]="{ item }">
        <div :class="[item.gsm_gateway.net_status === 'REACHABLE' ? '_accent' : '_disabled']">
          {{item.gsm_gateway.net_status}}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    data: () => ({
      headers: [
        {
          text: '<br>GSM Gateway',
          value: 'gsm_gateway.username',
          sortable: false,
        },
        {
          text: '<br>VOIP REG',
          value: 'voip_reg',
          sortable: false,
        },
        {
          text: '<br>VOIP PING',
          value: 'voip_ping',
          sortable: false,
        },
        {
          text: '<br>NET IP:PORT',
          value: 'gsm_gateway.voip_net_ip_port',
          sortable: false,
        },
        {
          text: '<br>NET STATUS',
          value: 'net_status',
          sortable: false,
        },
        {
          text: '<br>TOTAL PORTS',
          value: 'ports.total_ports',
          sortable: false,
        },
        {
          text: '<br>GSM LOGIN',
          value: 'ports.gsm_login_ports',
          sortable: false,
        },
        {
          text: '<br>VOIP LOGIN',
          value: 'ports.voip_login_ports',
          sortable: false,
        },
        {
          text: '<br>SMS LOGIN',
          value: 'ports.sms_login_ports',
          sortable: false,
        },
        {
          text: '<br>SMB LOGIN',
          value: 'ports.smb_login_ports',
          sortable: false,
        },
      ],
    }),
    created() {
      this.reset();
      this.load();
    },
    computed: {
      ...mapState('dashboardGsmGatewayPortsCount', {
        status: state => state.status,
        data: state => state.data,
      }),
    },
    methods: {
      ...mapMutations('dashboardGsmGatewayPortsCount', [
        'reset',
      ]),
      ...mapActions('dashboardGsmGatewayPortsCount', [
        'load',
      ]),
    }
  };
</script>

<style lang="scss" scoped>
  ._accent {
    color: green;
  }
  ._warning {
    color: red;
  }
  ._disabled {
    color: #ababab;
  }
</style>
