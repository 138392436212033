import {apiCall} from "../components/api/v1/calls";

export default {
  name: "UssdCommands",
  methods: {
      sendUssdCommand (gateway, port, imsi, command, command_type) {
          return apiCall({endpoint: '/customer/sendUSSDCommand', method: 'POST', data: {
               goip_gateway: gateway,
               goip_port: port,
               sim_imsi: imsi,
               ussd_command: command,
               ussd_command_type: command_type
           }})
      },
      getSimUssdCommands (sim_imsi, ussd_command_type) {
          return apiCall({endpoint: '/customer/getSimUssdCommands/' + sim_imsi + '?ussd_command_type=' + ussd_command_type, method: 'GET'})
      }
  }
}