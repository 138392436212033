<template>
  <v-tooltip open-delay=800 top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        medium
        :color="setStatusColor(netStatus)"
        v-bind="attrs"
        v-on="on"
      >
        mdi-ip-network
      </v-icon>
    </template>
    <span> {{ $t('text.Network_Availability_Status') }} </span>
  </v-tooltip>
</template>

<script>
    export default {
        name: "NetStatusIndicator",
        props: {
          netStatus: String,
        },
        methods: {
            setStatusColor(status) {
              let color = "gray"
              if (status === 'REACHABLE') {
                  color = "green"
              }
              return color
            },
        }
    }
</script>

<style scoped>

</style>