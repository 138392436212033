<template>
  <v-tooltip open-delay=800 top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        medium
        :color="setStatusColor(voipRegStatus, voipPingStatus)"
        v-bind="attrs"
        v-on="on"
      >
        {{ setIcon(voipRegStatus, voipPingStatus) }}
      </v-icon>
    </template>
    <span> {{ $t('text.VoIP_Ping_Status') }} </span>
  </v-tooltip>
</template>

<script>
    export default {
        name: "VoipPingStatusIndicator",
        props: {
            voipRegStatus: String,
            voipPingStatus: String,
        },
        methods: {
            setStatusColor(voipRegStatus, voipPingStatus) {
                if (voipRegStatus === 'REGISTERED') {
                    if (voipPingStatus === 'REACHABLE') {
                        return "green"
                    }
                    return "orange"
                }
                return "gray"
            },
          setIcon(voipRegStatus, voipPingStatus) {
            if (voipRegStatus === 'REGISTERED') {
              if (voipPingStatus === 'REACHABLE') {
                return "mdi-check-network"
              }
              return "mdi-close-network"
            }
            return "mdi-close-network"
          },
        }
    }
</script>

<style scoped>

</style>