<template>
  <v-tooltip open-delay=800 top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        medium
        :color="setStatusColor(netStatus, voipRegStatus)"
        v-bind="attrs"
        v-on="on"
      >
        mdi-phone-voip
      </v-icon>
    </template>
    <span> {{ $t('text.VoIP_Registration_Status') }} </span>
  </v-tooltip>
</template>

<script>
    export default {
        name: "VoipRegStatusIndicator",
        props: {
            netStatus: String,
            voipRegStatus: String,
        },
        methods: {
            setStatusColor(netStatus, voipRegStatus) {
                if (voipRegStatus === 'REGISTERED') {
                    return "green"
                }
                if (netStatus === 'REACHABLE' ) {
                        return "orange"
                }
                return "gray"
            },
        }
    }
</script>

<style scoped>

</style>