
import { apiCall } from '../../components/api/v1/calls';

const state = {
  status: 'loading',
  toggle: 0,
  data: [],
};

const getters = {
  chart: state => {
    const seriesA = [];
    const seriesB = [];
    const dates = [];

    for (const i of state.data) {
      seriesA.push(i.calls_total - i.calls_answered);
      seriesB.push(i.calls_answered);
      dates.push(i.date);
    }

    const a = {
      data: seriesA,
      name: 'Missed calls',
    };

    const b = {
      data: seriesB,
      name: 'Answered calls',
    };

    return {
      categories: dates,
      series: [b, a],
    };
  },
};

const actions = {
  load: function ({state, commit}) {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');

      let date = new Date();
      date.setHours(0,0,0,0);

      switch(state.toggle) {
        case 1:
          date = new Date(date).getTime() - 3600 * 1000 * 24 * 14;
          break;
        case 2:
          date.setMonth(date.getMonth() - 1);
          break;
        case 3:
          date.setMonth(date.getMonth() - 3);
          break;
        default:
          date = new Date(date).getTime() - 3600 * 1000 * 24 * 7;
          break;
      }

      const start_datetime = this._vm.$dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss');
      const end_datetime = this._vm.$dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss');

      apiCall({
        endpoint: '/customer/getCallStatsBars', method: 'POST', data: {
          direction: 'outgoing',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_datetime,
          end_datetime,
          hidden: false,
          top_10_worst_asr: true,
        },
      })
        .then(resp => {
          commit('status', 'success');
          // console.log('getCallStatsBars - load() - resp:', resp);
          commit('data', resp.data.result);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
};

const mutations = {
  reset: (state) => {
    state.status = 'loading';
    state.toggle = 0;
    state.data = [];
  },
  status: (state, payload) => {
    state.status = payload;
  },
  setToggle: (state, payload) => {
    state.toggle = payload;
  },
  data: (state, payload) => {
    state.data = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
