<template>
  <span v-if="!editable">
    {{ newValue }}
  </span>
  <span
    v-else
    class="d-inline-flex align-center"
  >
    <v-btn
      v-if="fieldType === 'password' && showToggleVisibilityIcon === true"
      class="mr-2"
      icon
      x-small
      @click="hidden = !hidden"
    >
      <v-icon
        small
      >
        {{ visibilityIcon }}
      </v-icon>
    </v-btn>
    <div class="b-table-input-outer">
      <input
        ref="input"
        v-model.trim="$v.newValue.$model"
        class="b-table-input"
        :class="{_error: $v.newValue.$error}"
        :type="inputType"
        :readonly="!editing"
        :style="{width: inputWidth}"
        @keyup.enter="saveValue"
      >
      <div
        v-if="showProgress"
        class="b-table-input-progress">
        <div
          class="b-table-input-progress-line"
          :style="progressStyle"
        />
      </div>
    </div>
    <span
      ref="mirror"
      class="b-table-input-width"
      aria-hidden="true"
    >{{ widthValue }}</span>
    <v-tooltip
      open-delay="800"
      close-delay="800"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          icon
          :color="color"
          :loading="updating"
          x-small
          v-bind="attrs"
          v-on="on"
          @click="saveValue"
        >
          <v-icon
            small
          >
            {{ actionIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span> {{ tooltipText }} </span>
    </v-tooltip>
    <v-tooltip
      v-if="!editing"
      open-delay="800"
      close-delay="800"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          icon
          :color="color"
          x-small
          v-bind="attrs"
          v-on="on"
          @click="copy"
        >
          <v-icon
            small
          >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
      <span> {{ $t('text.Copy') }} </span>
    </v-tooltip>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          small
          fab
          text
          @click="snack = false"
        >
          <v-icon dark>
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { POSITION } from 'vue-toastification';
import { mapActions } from 'vuex';

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 8,
  timeout: 10000,
};

const check = {
  alias: /.*/,
  description: /^(?:[0-9a-zA-Z()\[\]\s]{0,25})$/,
  password: /(?=.*\d)(?=.*[a-zA-Z])/,
};

export default {
  name: 'GsmGatewayInput',
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
      required: true,
    },
    option: {
      type: String,
      default: 'description',
      required: true,
    },
    fieldType: {
      type: String,
      default: 'text',
    },
    editable: Boolean,
    showToggleVisibilityIcon: Boolean
  },
  data() {
    return {
      editing: false,
      updating: false,
      newValue: null,
      inputWidth: 'auto',
      hidden: false,
      snack: false,
      snackColor: '',
      snackText: '',
    };
  },
  computed: {
    widthValue() {
      const length = !this.newValue
        ? 0
        : this.newValue.length;
      const min = 12;
      const max = 25;
      const dString = this.hidden ? '•' : '8';
      return length < min
        ? dString.repeat(min)
        : length > max
          ? dString.repeat(max)
          : this.newValue;
    },
    visibilityIcon() {
      return !this.hidden
        ? 'mdi-eye'
        : 'mdi-eye-off';
    },
    actionIcon() {
      return this.editing
        ? !this.$v.newValue.$error
          ? 'mdi-check'
          : 'mdi-close'
        : 'mdi-pencil';
    },
    color() {
      return !this.$v.newValue.$error
        ? 'success'
        : 'error';
    },
    inputType() {
      return this.hidden && !this.editing
        ? 'password'
        : 'text';
    },
    tooltipText() {
      return this.editing ? this.$t('text.SIM_save_number') : this.$t('text.SIM_edit_number');
    },
    showProgress() {
      return this.option === 'password' && this.editing;
    },
    progressStyle() {
      const v = this.newValue || '';
      let progress = v.length / 25 * 100;

      if (/(?=.*\d)/.test(v)) {
        progress = progress + 10;
      }

      if (/(?=.*[A-Z])/.test(v)) {
        progress = progress + 20;
      }

      if (/(?=.*\W)/.test(v)) {
        progress = progress + 30;
      }

      if (progress > 100) {
        progress = 100;
      }
      return {
        width: `${progress}%`,
      };
    },
  },
  validations: {
    newValue: {
      required,
      isValidValue(value) {
        if (this.option === 'password'
          && (value.length < 5 || value.length > 25)
        ) {
          return false;
        }

        return check[this.option].test(value);
      },
    },
  },
  watch: {
    async newValue() {
      console.log('watch newValue');
      await this.$nextTick();
      const mirror = this.$refs.mirror;
      const w = mirror && mirror.getBoundingClientRect().width;
      this.inputWidth = w && w > 0
        ? `${w + 4}px`
        : 'auto';
    },
  },
  created() {
    this.getValue();
    if (this.fieldType === 'password') {
      this.hidden = true;
    }
  },
  methods: {
    ...mapActions('gsm_gateways', ['getGsmGateways', 'setGsmGateway']),
    getValue() {
      this.newValue = this.item[this.option];
    },
    async saveValue() {
      if (this.editing) {
        // Save new value if it is validated.
        if (!this.$v.newValue.$error) {
          this.updating = true;

          this.$set(this.item, this.option, this.newValue);

          const gw = this.item;
          await this.setGsmGateway(gw)
            .then(r => {
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = this.$t('text.changes_saved');

              /*
              this.$toast(this.$t('text.gw_input_successfully_updated') + this.newValue, {
                ...toastOptions,
                type: 'success',
              });
               */
            })
            .catch(e => {
              this.getValue();
              this.snack = true;
              this.snackColor = 'error';
              this.snackText = this.$t('text.changes_not_saved');

              /*
              this.$toast(this.$t('text.gw_input_not_updated'), {
                ...toastOptions,
                type: 'error',
              });
               */
            })
            .finally(() => this.updating = false);
          await this.getGsmGateways();
        } else {
          this.getValue();
        }
      } else {
        this.$refs.input.focus();
      }

      this.editing = !this.editing;
    },
    copy() {
      // Clear previous selection.
      (window.getSelection ? window.getSelection() : document.selection).empty();

      const copy = this.newValue;
      let el = document.body.appendChild(document.createTextNode(copy));
      const range = document.createRange();
      range.selectNode(el);
      window.getSelection().addRange(range);

      let successful;

      try {
        // Now that we've selected the anchor text, execute the copy command
        successful = document.execCommand('copy');
      } catch (error) {
        // console.log('copy - error:', error);
      }

      if (successful) {
        this.$toast(this.$t('text.Copy_done'), {
          ...toastOptions,
          type: 'success',
        });
      } else {
        this.$toast(this.$t('text.Copy_error'), {
          ...toastOptions,
          type: 'error',
        });
      }

      // Remove the selections - NOTE: Should use removeRange(range) when it is supported
      window.getSelection().removeAllRanges();
      el.parentNode.removeChild(el);
    },
  },
};
</script>
