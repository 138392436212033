<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
    :style="containerHeightStyle"
  >
    <div class="b-fixed-height">
      <SmsRecvFilter
          v-model="data"
          @start="loading = true"
          @end="loading = false"
      />
    <v-divider/>
      <!-- data table -->
      <div
        ref="fixHeight"
        class="b-fixed-height__table-outer"
      >
        <v-data-table
          :key="itemsPerPage"
          :headers="columns"
          :items="data"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            'items-per-page-options': [itemsPerPageOption, -1]
          }"
          :loading="loading"
          :loading-text="$t('text.Loading_Please_wait')"
        >
          <template v-slot:[`header.gsm_gateway`]="{ header }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ header['text'] }}
            </div>
          </template>

          <template v-slot:[`item.received_at`]="{ item }">
            <div :style="{ 'white-space': 'nowrap' }">
              {{ item['received_at'] }}
            </div>
          </template>

          <template v-slot:[`item.gsm_gateway`]="{ item }">
            <span v-if="typeof item['gsm_gateway'] !== 'undefined' && item['gsm_gateway'] !== ''" :style="{ 'white-space': 'nowrap' }">
                {{ getGatewayAlias(item['gsm_gateway']) }} ({{ item['gsm_gateway_port'] }})
            </span>
          </template>

          <template v-slot:[`item.sim_imsi`]="{ item }">
            <div class="text-center">
              <v-chip
                color="blue"
                text-color="default"
                :style="{ paddingLeft: `4px` , paddingRight: `8px`}"
                pill
                outlined
              >
                <MobileOperatorIcon
                  v-show="isReady"
                  :icon-name="item['mobile_operator']"
                  width="22"
                  height="22"
                  compact
                  generate-ready-event
                  @svg-icon-ready="ready"
                />
                <span :style="{ paddingLeft: `4px`, paddingRight: `4px` }">
                  {{ item['sim_imsi'] }}
                </span>
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-layout>
</template>

<script>
  import height from '../mixins/height';
  import MobileOperatorIcon from './elements/MobileOperatorIcon';
  import SmsRecvFilter from './filters/SmsRecvFilter';
  import {mapActions, mapState} from "vuex";

  export default {
    name: "SmsRecvRecords",
    components: {
      MobileOperatorIcon,
      SmsRecvFilter,
    },
    mixins: [
      height,
    ],
    data () {
      return {
        data: [],
        loading: true,
        isReady: false
      }
    },
    computed: {
      ...mapState("gsm_gateways", {
        gateways: state => state.gateways,
      }),
      columns() {
        return [
          { text: this.$t('text.Received_at') + `:`, value: 'received_at', align: 'center', sortable: false },
          { text: this.$t('text.SMS_Sender') + `:`, value: 'sender', align: 'center', sortable: false },
          { text: this.$t('text.SIM_Number') + `:`, value: 'sim_number', align: 'center', sortable: false },
          { text: this.$t('text.SMS_Text') + `:`, value: 'sms_text', align: 'center', sortable: false },
          { text: this.$t('text.GSM_Gateway_and_port') + `:`, value: 'gsm_gateway', align: 'center', sortable: false },
          // { text: this.$t('text.Port') + `:`, value: 'gsm_gateway_port', align: 'center', sortable: false },
          { text: this.$t('text.SIM_Card') + `:`, value: 'sim_imsi', align: 'center', sortable: false },
          // { text: this.$t('text.Mobile_operator') + `:`, value: 'mobile_operator', align: 'center', sortable: false },
        ]},
    },
    mounted () {
      this.getGsmGateways()
    },
    methods: {
      ...mapActions("gsm_gateways", ["getGsmGateways"]),
      getGatewayAlias(username) {
        let index = this.gateways.findIndex(gw => gw.username === username);
        if (index !== -1) {
          return this.gateways[index].alias
        }
        return username
      },
      ready() {
        this.isReady = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .v-data-table td, .v-data-table th {
    padding: 0 12px;
  }
</style>
