import {apiCall} from "../../components/api/v1/calls";

export default {
    name: "mSimCards",
    methods: {
        getCustomerSimCards (hidden, filter, stats) {
            return new Promise(function(resolve, reject) {
                let jbody = {
                    hidden: hidden, // filter sim cards by visibility
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    mobile_operators: [],
                    countries: [],
                };

                if (filter !== undefined) {
                    // filter by mobile operators
                    if (Array.isArray(filter.operators) && filter.operators.length > 0) {
                        jbody.mobile_operators = filter.operators
                    }
                    // filter by countries
                    if (Array.isArray(filter.countries) && filter.countries.length > 0) {
                        jbody.countries = filter.countries
                    }
                }

                if (stats !== undefined) {
                    jbody.with_call_stats = stats
                }
                apiCall({endpoint: '/customer/getSimCards',method: 'POST', data: jbody})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot get sim cards: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot get sim cards: ", e);
                    reject(e)
                })
            })
        },
        setCustomerSimCardNumber (imsi, number) {
            return new Promise(function(resolve, reject) {
                apiCall({endpoint: '/customer/setSimNumber?sim_imsi=' + imsi + '&sim_number=' + encodeURIComponent(number), method: 'POST', data: null})
                .then(response => {
                    if (response.status === 200) {
                        // console.debug(response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot update sim card number: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot update sim card number: ", e);
                    reject(e)
                })
            })
        },
        setCustomerSimCardComment (imsi, comment) {
            return new Promise(function(resolve, reject) {
                apiCall({endpoint: '/customer/setSimComment' ,method: 'POST', data: {
                    sim_imsi: imsi,
                    comment,
                }})
                .then(response => {
                    if (response.status === 200) {
                        console.debug('xxx', response);
                        resolve(response.data.result)
                    } else {
                        const e = new Error("cannot update sim card comment: non 200 response code received");
                        console.debug(e);
                        reject(e)
                    }
                })
                .catch(e => {
                    console.debug("cannot update sim card comment: ", e);
                    reject(e)
                })
            })
        }
    }
}
