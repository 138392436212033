<template>
    <v-layout
        ref="containerHeight"
        wrap
        row
        :style="containerHeightStyle"
    >
        <div class="dt-right-top-picker">
            <v-row>
                <v-col cols="12">
                    <div class="b-date-picker-outer">
                        <eDateTimePicker
                            :initial-datetime="datetime"
                            @e-datetime-changed="changeDatetime"
                        />
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="b-fixed-height">
            <div
              ref="fixHeight"
              class="b-fixed-height__table-outer"
            >
                <v-data-table
                    v-model="selected"
                    :key="itemsPerPage"
                    :headers="columns"
                    :items="items"
                    item-key="imsi"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{
                      'items-per-page-options': [itemsPerPageOption, -1]
                    }"
                    dense
                    show-select
                    selectable-key="imsi"
                >
                    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                            :disabled="item['gsm_login'] !== 'LOGIN'"
                            :value="isSelected"
                            @input="select($event)"
                        />
                    </template>
                    <template v-slot:[`footer.prepend`]>
                        <div class="row">
                          <div class="col-auto">
                            <SimsUssdCommandButton
                              command="balance"
                              v-bind="{ sims: selected, disabled: disabledUssdCommands }"
                              @ussd-command-response="ussdCommandResponseProcessing"
                              @ussd-command-lock="lock"
                              @ussd-command-unlock="unlock"
                            />
                          </div>
                          <div class="col-auto">
                            <SimsUssdCommandButton
                              command="number"
                              v-bind="{ sims: selected, disabled: disabledUssdCommands }"
                              @ussd-command-response="ussdCommandResponseProcessing"
                              @ussd-command-lock="lock"
                              @ussd-command-unlock="unlock"
                            />
                          </div>
                          <div class="col-auto">
                              <!-- will show list of archived sim cards-->
                              <HideSimCardsButton
                                  :hide="showHidden"
                                  @switch-sim-cards-hiding="switch_sim_cards_hiding"
                              />
                          </div>
                        </div>
                    </template>
                    <template v-slot:[`item.number`]="{ item }">
                        <!-- :key - will update component on the number changing -->
                        <v-list-item
                            :key="`${item.iccid}`"
                            :style="{ paddingLeft: '0px', paddingRight: '8px'}"
                            dense
                        >
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '6px' }">
                                <MobileOperatorIcon
                                    :icon-name="item['mobile_operator_name']"
                                    compact
                                />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <!-- SIM Number -->
                                    <span class="d-flex align-center text-left">
                                        <SimNumber
                                            class="mr-2"
                                            :sim_imsi="item['imsi']"
                                            :number="item.number"
                                            :editable="true"
                                        />
                                        <!-- The button for SIM number extraction -->
                                        <!--
                                        <ExtractSimNumberButton
                                            v-if="item['gsm_login'] === 'LOGIN'"
                                            v-bind="{ sim: item, locked: locked_items[item['imsi']] }"
                                            @ussd-command-response="ussdCommandResponseProcessing"
                                            @ussd-command-lock="lock"
                                            @ussd-command-unlock="unlock"
                                        />
                                        -->
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- IMSI -->
                    <template v-slot:[`item.iccid`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-content>
                                <v-list-item-title>
                                  <v-tooltip v-if="getShowPhone"
                                      open-delay="100"
                                      close-delay="100"
                                      bottom
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon style="display: inline-flex"
                                              v-bind:class="{ 'red--text': getSimId !== item['iccid'], 'green--text': getSimId === item['iccid'] }"
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="chooseSim(item)"
                                      >mdi-sim</v-icon>
                                    </template>
                                    <span>{{ getSimId === item['iccid'] ? $t('text.sim_to_call') : $t('text.not_sim_to_call') }}</span>
                                  </v-tooltip>
                                    <span class="d-flex align-center text-center " @click="chooseSim(item)" style="cursor: pointer; display: inline-flex !important;">
                                        {{ item['iccid'] }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- Balance -->
                    <template v-slot:[`item.balance`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="24" min-width="24" height="24" :style="{ marginRight: '6px' }">
                                <v-icon :color="colorizeBalance(item)">
                                    mdi-cash-multiple
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-center text-left">
                                        <span v-if="item['balance']" class="mr-2">{{ item['balance'] }} {{ item['balance_currency'] }}</span>
                                        <!-- <span v-if="item['balance']" :class="'mr-2 ' + colorizeBalance(item) + '--text'">{{ item['balance'] }} {{ item['balance_currency'] }}</span> -->
                                        <!--
                                        <span v-if="item['balance']">
                                           <ExtractSimBalanceButton
                                               v-if="item['gsm_login'] === 'LOGIN'"
                                               v-bind="{ sim: item, size: 18, locked: locked_items[item['imsi']]}"
                                               @ussd-command-response="ussdCommandResponseProcessing"
                                               @ussd-command-lock="lock"
                                               @ussd-command-unlock="unlock"
                                           />
                                        </span>
                                        <span v-else class="font-weight-light">
                                          <ExtractSimBalanceButton
                                              v-if="item['gsm_login'] === 'LOGIN'"
                                              v-bind="{ sim: item, size: 18, locked: locked_items[item['imsi']]}"
                                              @ussd-command-response="ussdCommandResponseProcessing"
                                              @ussd-command-lock="lock"
                                              @ussd-command-unlock="unlock"
                                          />
                                        </span>
                                        -->
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- Calls -->
                    <template v-slot:[`item.call_stats.calls_total`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="24" min-width="24" height="24" :style="{ marginRight: '4px' }">
                                <v-icon color="blue" dense>
                                    mdi-phone-outgoing
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    <span>{{ item['call_stats']['calls_total'] }}/</span>
                                    <span class="green--text">{{ item['call_stats']['calls_answered'] }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- Billed minutes -->
                    <template v-slot:[`item.call_stats.billmin`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="24" min-width="24" height="24" :style="{ marginRight: '4px'}">
                                <v-icon color="blue" dense>
                                    mdi-clock-check-outline
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    <span class="text--darken-4 blue--text">
                                      {{ item['call_stats']['billmin'] }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:[`item.call_stats.asr`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="24" min-width="24" height="24" :style="{ marginRight: '4px' }">
                                <v-icon color="blue">
                                    mdi-finance
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    <span :style="{ color: colorizeAsr(item['call_stats']['asr']) }">{{ item['call_stats']['asr'] }}</span>
                                    <span>/</span>
                                    <span>{{ item['call_stats']['acd'] }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- GSM Gateway status -->
                    <template v-slot:[`item.gsm_gateway`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '4px' }">
                                <GsmPortSignal v-bind="{signal: item['gsm_port_signal'], sim_imsi: item['imsi'], size: 20 }"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    <span v-if="item['gsm_login'] === 'LOGIN'" :style="{ whiteSpace: 'nowrap' }">
                                        {{ item['gsm_gateway_alias'] }} {{ $t('text.slot').toLowerCase() }}: {{ item["gsm_port"] }}
                                    </span>
                                    <span v-else>
                                        {{ $t('text.slot_status_offline') }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- SIM Bank status -->
                    <template v-slot:[`item.sim_bank`]="{ item }">
                        <v-list-item v-if="item.hasOwnProperty('sim_bank')" dense :style="{ paddingLeft: '6px', paddingRight: '6px' }">
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '0px' }">
                                <SimBankLoginIndicator v-bind="{ login_status: item['sim_bank']['sim_login'], size: 20 }"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-left text-left">
                                    <span v-if="item['sim_bank']['sim_login'] === 11" :style="{ whiteSpace: 'nowrap' }">
                                         {{ item['sim_bank']['type'] }}  {{ $t('text.id') }}: {{ item['sim_bank']['name'] }} {{ $t('text.slot').toLowerCase() }}: {{ item['sim_bank']["index"] }}
                                    </span>
                                    <!-- @TODO additional investigation required about what means 12 status
                                        <span v-else-if="item['sim_bank']['sim_login'] === 12">{{ $t('text.she_is_disabled') }}</span> -->
                                    <span v-else-if="item['sim_bank']['sim_login'] === 13">{{ $t('text.slot_status_idle') }}</span>
                                    <span v-else-if="item['sim_bank']['sim_login'] === 14">{{ $t('text.slot_status_busy') }}</span>
                                    <span v-else>{{ $t('text.slot_status_offline') }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else dense :style="{ paddingLeft: '6px', paddingRight: '6px' }">
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '0px' }">
                                <SimBankLoginIndicator v-bind="{ login_status: 0, size: 20 }"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    <span>{{ $t('text.IsNotUsed') }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- SIM Group status -->
                    <template v-slot:[`item.sim_group`]="{ item }">
                        <v-list-item v-if="item.hasOwnProperty('sim_group')" dense :style="{ paddingLeft: '6px', paddingRight: '6px'}">
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '0px' }">
                                <SimGroupIndicator v-bind="{ group: item.sim_group, size: 20}"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-left text-left">
                                    <!-- @TODO add group selector here -->
                                    <span v-if="item['sim_group']['name']">{{ item['sim_group']['name'] }}</span>
                                    <span v-else>{{ $t('text.sm_sim_group_binding_status') }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else dense :style="{ paddingLeft: '6px', paddingRight: '6px' }">
                            <v-list-item-avatar width="26" min-width="26" height="26" :style="{ marginRight: '0px' }">
                                <SimGroupIndicator v-bind="{ group: undefined, size: 20}"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-left text-left">
                                    <span>{{ $t('text.IsNotSet') }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- Comment -->
                    <template v-slot:[`item.comment`]="{ item }">
                        <span class="d-flex justify-center b-cell-with-icon">
                            <SimComment
                                :sim_imsi="item['imsi']"
                                :comment="item.comment"
                                :editable="true"
                            />
                        </span>
                    </template>
                    <!-- Actions -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-list-item dense :style="{ paddingLeft: '6px', paddingRight: '0px'}">
                            <v-list-item-action :style="{ marginRight: '1px' }">
                                <LockItemButton
                                    v-bind="{ sim_imsi: item['imsi'], resource: 'calls', lock_state: item['locked_for_calls'], size: 18 }"
                                    @switch-sim-card-lock-state="switch_sim_card_lock_state"
                                />
                            </v-list-item-action>

                            <v-list-item-action :style="{ marginLeft: '1px', marginRight: '1px'}">
                                <LockItemButton
                                    v-bind="{ sim_imsi: item['imsi'], resource: 'sms', lock_state: item['locked_for_sms'], size: 18 }"
                                    @switch-sim-card-lock-state="switch_sim_card_lock_state"
                                />
                            </v-list-item-action>

                            <v-list-item-action :style="{ marginLeft: '1px' }">
                                <HideSimCardButton
                                    v-bind="{ sim: item, size: 18 }"
                                    @switch-sim-card-hiding="switch_sim_card_hiding"
                                />
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-data-table>
            </div>
        </div>
    </v-layout>
</template>

<script>
    import Vue from "vue";
    import {POSITION, TYPE} from "vue-toastification";
    import eDateTimePicker from './filters/eDateTimePicker';
    // import ExtractSimNumberButton from './elements/buttons/ExtractSimNumberButton';
    // import ExtractSimBalanceButton from './elements/buttons/ExtractSimBalanceButton';
    import SimsUssdCommandButton from './elements/buttons/SimsUssdCommandButton';
    import GsmPortSignal from './elements/GsmPortSignal';
    import mSimCards from '@/mixins/customer/mSimCards';
    import height from '@/mixins/height';
    import MobileOperatorIcon from './elements/MobileOperatorIcon';
    import SimBankLoginIndicator from './elements/SimCard/SimBankLoginIndicator';
    import SimGroupIndicator from './elements/SimCard/SimGroupIndicator';
    //import SimGsmLoginIndicator from './elements/SimCard/SimGsmLoginIndicator'
    import SimNumber from './elements/SimCard/SimNumber';
    import SimComment from './elements/SimCard/SimComment';
    import UssdResponseNotify from "./notifications/UssdResponseNotify";
    import LockItemButton from "./elements/buttons/LockItemButton";
    import HideSimCardButton from "./elements/buttons/HideSimCardButton";
    import HideSimCardsButton from './elements/buttons/HideSimCardsButton';
    import Vuex, {mapActions, mapGetters} from "vuex";

    Vue.use(Vuex);

    export default {
        name: 'SimManagement',
        components: {
            eDateTimePicker,
            // ExtractSimNumberButton,
            // ExtractSimBalanceButton,
            SimsUssdCommandButton,
            GsmPortSignal,
            HideSimCardButton,
            HideSimCardsButton,
            LockItemButton,
            MobileOperatorIcon,
            SimBankLoginIndicator,
            SimGroupIndicator,
            //SimGsmLoginIndicator,
            SimNumber,
            SimComment,
        },
        mixins: [
            height,
            mSimCards,
        ],
        data () {
            return {
                heightCalcVariant: 'dashboard',
                selected: [],
                items: [],
                locked_items: {},
                countries: [],
                filter: {
                    searchByNumberOrImsi: '',
                    operators: [],
                    countries: [],
                },
                sim_call_stats: {
                    direction: 'outgoing',
                },
                loading: false,
                showHidden: false,

                datetimeDefault: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
                datetime: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
            }
        },
        computed: {
          ...mapGetters("phoneState", ['getSimId',]),
          ...mapGetters("phoneState", ['getShowPhone',]),
            columns() {
                return [
                    { text: this.$t('text.sim_number'), value: 'number', width: '5%', align: 'center', filterable: false, sortable: true },
                    // { text: this.$t('text.sim_imsi'), value: 'imsi', width: '5%', align: 'center', filterable: false, sortable: false },
                    { text: this.$t('text.sim_iccid'), value: 'iccid', width: '5%', align: 'center', filterable: false, sortable: true },
                    // { text: this.$t('text.Country'), value: 'mobile_operator_country', align: 'center', filterable: false, sortable: true },
                    // { text: this.$t('text.sim_mobile_operator'), value: 'mobile_operator_name', align: 'center', filterable: false, sortable: true },
                    // { value: 'mobile_operator_network' },
                    // { value: 'mobile_operator_country' },
                    // { value: 'mobile_operator_country_iso' },
                    { text: this.$t('text.Balance'), value: 'balance', width: '5%', align: 'center', filterable: false, sortable: false },
                    { text: this.$t('text.Calls'), value: 'call_stats.calls_total', width: '8%', align: 'center', filterable: false, sortable: true },
                    { text: this.$t('text.Minutes'), value: 'call_stats.billmin', width: '8%', align: 'center', filterable: false, sortable: true },
                    { text: this.$t('text.ASR') + "/" + this.$t('text.ACD'), value: 'call_stats.asr', width: '8%', align: 'center', filterable: false, sortable: true },
                    // { value: 'balance_currency' },
                    // { value: 'balance_extracted_from' },
                    // { value: 'balance_extracted_at' },
                    { text: this.$t('text.GSM_Gateway'), value: 'gsm_gateway', width: '5%', align: 'center', filterable: false, sortable: false },
                    { text: this.$t('text.sim_bank'), value: 'sim_bank', width: '5%', align: 'center', filterable: false, sortable: false },
                    { text: this.$t('text.sim_group'), value: 'sim_group', width: '10%', align: 'center', filterable: false, sortable: false },
                    { text: this.$t('text.Comment'), value: 'comment', width: '30%', align: 'center', filterable: false, sortable: true },
                    { text: this.$t('text.Actions'), value: 'actions', width: '5%', align: 'center', filterable: false, sortable: false }
                ];
            },
            disabledUssdCommands() {
                const isLocked = Object.values(this.locked_items).find(i => !!i);
                const noSelected = this.selected.length < 1;
                return isLocked || noSelected;
            },
        },
        mounted() {
            this.rendering()
        },
        methods: {
          ...mapActions("phoneState", ["chooseSim"]),
            changeDatetime(dtime) {
                this.datetime = dtime;
                this.rendering()
            },
            async rendering () {
                await this.getSimCards()
            },
            getSimCards() {
                this.loading = true;

                // datetime from/to validation
                if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
                    this.datetime = this.datetimeDefault
                }
                this.sim_call_stats.start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
                this.sim_call_stats.end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');

                this.getCustomerSimCards(this.showHidden, this.filter, this.sim_call_stats)
                .then(cards => {
                    this.items = cards;
                })
                .catch(e => {
                    this.items = []
                })
                .finally(() => this.loading = false);
            },
            colorizeBalance(sim) {
                let color = "default";
                if (sim['balance'] !== undefined) {
                    color = "success";
                    // @TODO balance colorize should be customized by customer
                    if (parseFloat(sim['balance']) < 10) return "red";
                    else if (parseFloat(sim['balance']) <= 100) return 'orange';
                    return color
                }
                return color
            },
            colorizeAsr(asr) {
                if (asr >= 0 && asr <= 30) return 'red';
                else if (asr > 30 && asr <= 50) return 'orange';
                else return 'green'
            },
            ussdCommandResponseProcessing(notify) {
                let type = TYPE.DEFAULT;
                if (notify.failure) {
                    type = TYPE.ERROR;
                }

                let content = {
                    component: UssdResponseNotify,
                    // Any prop can be passed, but don't expect them to be reactive
                    props: {
                        title: 'SIM: ' + notify.sim_imsi,
                        text: notify.text
                    },
                };

                const options = {
                    position: POSITION.BOTTOM_RIGHT,
                    maxToasts: 8,
                    timeout: 10000,
                    type: type
                };

                // send notify about ussd command response
                this.$toast(content, options);

                // send notify about extracted balance
                if (notify.balance) {
                    options.type = TYPE.SUCCESS;
                    this.$toast({
                        component: UssdResponseNotify,
                        // Any prop can be passed, but don't expect them to be reactive
                        props: {
                            title: 'SIM: ' + notify.sim_imsi,
                            text: [this.$t('text.Current') + ' ' + this.$t('text.Balance').toLowerCase() + ': ' + notify.balance.value + ' ' + notify.balance.currency]
                        },
                    }, options);

                    this.items.forEach(function(sim, i, cards) {
                        if (sim.imsi === notify.sim_imsi) {
                            Vue.set(cards[i], 'balance', notify.balance['value']);
                            Vue.set(cards[i], 'balance_currency', notify.balance['currency']);
                            Vue.set(cards[i], 'balance_extracted_at', notify.balance['extracted_at']);
                            if (notify.balance['extracted_from'] === 1) {
                                Vue.set(cards[i], 'balance_extracted_from', 'sms');
                            } else if (notify.balance['extracted_from'] === 2) {
                                Vue.set(cards[i], 'balance_extracted_from', 'ussd');
                            } else {
                                Vue.set(cards[i], 'balance_extracted_from', 'unknown');
                            }

                            return true
                        }
                    });
                }

                // send notify about extracted number
                if (notify.sim_number) {
                    options.type = TYPE.SUCCESS;
                    this.$toast({
                        component: UssdResponseNotify,
                        // Any prop can be passed, but don't expect them to be reactive
                        props: {
                            title: 'SIM: ' + notify.sim_imsi,
                            text: [this.$t('text.Current') + ' ' + this.$t('text.Number').toLowerCase() + ': ' + notify.sim_number]
                        },
                    }, options);

                    this.items.forEach(function(sim, i, cards) {
                        if (sim.imsi === notify.sim_imsi) {
                            Vue.set(cards[i], 'number', notify.sim_number);
                            return true
                        }
                    });
                }
            },
            lock(sim_imsi) { // lock sim for other ussd commands
                if (Array.isArray(sim_imsi)) {
                    for (const imsi of sim_imsi) {
                        Vue.set(this.locked_items, imsi, true);
                    }
                } else {
                    Vue.set(this.locked_items, sim_imsi, true);
                }
            },
            unlock(sim_imsi) { // unlock sim for other ussd commands
                if (Array.isArray(sim_imsi)) {
                    for (const imsi of sim_imsi) {
                        Vue.set(this.locked_items, imsi, false);
                    }
                } else {
                    Vue.set(this.locked_items, sim_imsi, false);
                }
            },
            switch_sim_card_lock_state(sim_imsi, resource, lock_state) {
                this.items.forEach(function(sim, i, cards) {
                    if (sim.imsi === sim_imsi) {
                        Vue.set(cards[i], 'locked_for_' + resource, lock_state);
                        return true
                    }
                });
            },
            switch_sim_card_hiding(sim_imsi) {
                this.items.forEach(function(sim, i, cards) {
                    if (sim.imsi === sim_imsi) {
                        Vue.delete(cards, i);
                        return true
                    }
                });
            },
            switch_sim_cards_hiding() {
                this.showHidden = !this.showHidden;
                this.rendering()
            },
        }
    }

</script>

<style scoped lang="scss">
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>
