<template>
  <v-layout
    ref="containerHeight"
    wrap
    row
  >

    <div class="b-fixed-height">
      <v-card
        class="mb-3"
        width="100%"
      >
        <v-card-text>
          <v-row
            no-gutters
            style="height: 64px;"
          >
            <!-- search by SIM number or SIM IMSI code -->
            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="filter.searchByNumberOrImsi"
                append-icon="search"
                :label="$t('text.Search_by_SIM_IMSI_or_Number')"
                clearable
                hide-details
              />
            </v-col>
            <!-- filter by SIM mobile operator  -->
            <v-col
              cols="3"
              md="3"
            >
              <MobileOperatorsSelector
                v-model="filter.operators"
                @on-select="rendering"
              />
            </v-col>
            <!-- filter by mobile operator country -->
            <v-col
              cols="3"
              md="3"
            >
              <v-select
                v-model="filter.countries"
                :label="$t('text.Country')"
                :items="countries"
                item-value="iso2"
                item-text="name"
                clearable
                hide-details
                multiple
                @focus="getCountries()"
                @change="rendering"
              />
            </v-col>
            <!-- Reset filters button -->
            <v-btn
              class="text-center mt-3"
              @click="resetAllFilters"
            >
              {{ $t('text.Reset') }}
            </v-btn>
            <!-- Show hidden SIMs button -->
            <div :style="{'position': 'absolute', 'right': '18px', 'top': '50%', 'transform': 'translateY(-50%)'}">
              <HideSimCardsButton
                large
                :hide="showHidden"
                @switch-sim-cards-hiding="switch_sim_cards_hiding"
              />
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div class="b-fixed-height">
      <v-divider />
      <v-list dense>
        <template v-for="item in items">
          <!-- :key - will update component on the number changing -->
          <v-list-item
            :key="`${item.imsi}_${item.number}`"
            :style="{ 'max-height': '58px' }"
          >
            <v-list-item-avatar>
              <MobileOperatorIcon
                :icon-name="item['mobile_operator_name']"
                :width="22"
                :height="22"
                compact
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <!-- SIM Number -->
                <span class="d-flex align-center text-center font-weight-bold">
                  <span class="mr-2">{{ $t('text.SIM_Number') }}:</span>
                  <SimNumber
                    class="mr-2"
                    :sim_imsi="item['imsi']"
                    :number="item.number"
                    :editable="true"
                  />
                  <!-- The button for SIM number extraction -->
                  <ExtractSimNumberButton
                    v-if="item['gsm_login'] === 'LOGIN'"
                    v-bind="{ sim: item, locked: locked_items[item['imsi']] }"
                    @ussd-command-response="ussdCommandResponseProcessing"
                    @ussd-command-lock="lock"
                    @ussd-command-unlock="unlock"
                  />
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>
                  ICCID: {{ item["iccid"] }} IMSI: {{ item['imsi'] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- SIM GSM status indication -->
            <v-list-item-avatar>
              <SimGsmLoginIndicator
                      v-bind="{ sim: item }"
                      large
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="item['gsm_login'] === 'LOGIN'">
                <span class="text-center font-weight-bold">
                  {{ $t('text.GSM_Gateway') }}:  {{ item['gsm_gateway_alias'] }} {{ $t('text.slot').toLowerCase() }}: {{ item["gsm_port"] }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else>
                <span class="text-center font-weight-bold">
                  {{ $t('text.offline') }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item['gsm_login'] === 'LOGIN'">
                <span>
                  IMEI: {{ item["imei"] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- SIM Bank status indication -->
            <v-list-item-avatar v-if="item['sim_bank'] !== undefined">
              <SimBankLoginIndicator
                      v-bind="{ bank: item['sim_bank'] }"
                      large
              />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <SimBankLoginIndicator
                      v-bind="{ bank: { sim_login: 0 } }"
                      large
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="item['sim_bank'] !== undefined && item['sim_bank']['sim_login'] === 11">
                <span  class="text-center font-weight-bold">
                  {{ item['sim_bank']['type'] }}  {{ $t('text.id') }}: {{ item['sim_bank']['name'] }} {{ $t('text.slot').toLowerCase() }}: {{ item['sim_bank']["slot"] }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else-if="item['sim_bank'] !== undefined && item['sim_bank']['sim_login'] === 12">
                <span  class="text-center font-weight-bold">
                  {{ $t('text.slot_is_disabled') }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else-if="item['sim_bank'] !== undefined && item['sim_bank']['sim_login'] === 13">
                <span  class="text-center font-weight-bold">
                  {{ $t('text.slot_is_disabled') }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else-if="item['sim_bank'] !== undefined && item['sim_bank']['sim_login'] === 14">
                <span  class="text-center font-weight-bold">
                  {{ $t('text.slot_status_busy') }}
                </span>
              </v-list-item-title>
              <v-list-item-title v-else>
                <span  class="text-center font-weight-bold">
                  {{ $t('text.offline') }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle v-if="item['sim_bank'] !== undefined">
                  {{ $t('text.slot') }} IMEI: {{ item['sim_bank']['slot_imei'] }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- SIM Balance -->
            <v-list-item-avatar>
              <v-icon
                large
                :color="setBalanceColor(item)"
              >
                mdi-currency-usd
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <span class="d-flex align-center text-center font-weight-bold">
                  <span v-if="item['balance']" class="mr-2">{{ $t('text.Balance') }}: {{ item['balance'] }} {{ item['balance_currency'] }}</span>
                  <span v-if="item['balance']">
                     <ExtractSimBalanceButton
                             v-if="item['gsm_login'] === 'LOGIN'"
                             v-bind="{ sim: item, locked: locked_items[item['imsi']]}"
                             @ussd-command-response="ussdCommandResponseProcessing"
                             @ussd-command-lock="lock"
                             @ussd-command-unlock="unlock"
                     />
                  </span>
                  <span v-else>
                  {{ $t('text.Balance_no_data') }}
                    <ExtractSimBalanceButton
                            v-if="item['gsm_login'] === 'LOGIN'"
                            v-bind="{ sim: item, locked: locked_items[item['imsi']]}"
                            @ussd-command-response="ussdCommandResponseProcessing"
                            @ussd-command-lock="lock"
                            @ussd-command-unlock="unlock"
                    />
                  </span>
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="item['balance']">
                  {{ $t('text.Method') }}: {{ item['balance_extracted_from'].toUpperCase() }} {{ $t('text.Date') }}: {{ item['balance_extracted_at'] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- Lock SIM for calls action button --->
            <v-list-item-action>
              <LockItemButton
                v-bind="{ sim_imsi: item['imsi'], resource: 'calls', lock_state: item['locked_for_calls'] }"
                @switch-sim-card-lock-state="switch_sim_card_lock_state"
              />
            </v-list-item-action>
            <!-- Lock SIM for sms action button --->
            <v-list-item-action>
              <LockItemButton
                v-bind="{ sim_imsi: item['imsi'], resource: 'sms', lock_state: item['locked_for_sms'] }"
                @switch-sim-card-lock-state="switch_sim_card_lock_state"
              />
            </v-list-item-action>
            <!-- Hide SIM action button --->
            <v-list-item-action>
              <HideSimCardButton
                v-bind="{ sim: item }"
                @switch-sim-card-hiding="switch_sim_card_hiding"
              />
            </v-list-item-action>
          </v-list-item>
          <v-divider
            :key="item['imsi'] + item['imei']"
            inset
          />
        </template>
      </v-list>
    </div>
  </v-layout>
</template>

<script>
  import Vue from 'vue';
  import {POSITION, TYPE} from "vue-toastification";
  import SimGsmLoginIndicator from './elements/SimCard/SimGsmLoginIndicator';
  import SimBankLoginIndicator from './elements/SimCard/SimBankLoginIndicator';
  import SimNumber from './elements/SimCard/SimNumber';
  import ExtractSimNumberButton from './elements/buttons/ExtractSimNumberButton';
  import ExtractSimBalanceButton from './elements/buttons/ExtractSimBalanceButton';
  import HideSimCardButton from './elements/buttons/HideSimCardButton';
  import HideSimCardsButton from './elements/buttons/HideSimCardsButton';
  import LockItemButton from './elements/buttons/LockItemButton';
  import MobileOperatorIcon from './elements/MobileOperatorIcon';
  import MobileOperatorsSelector from './filters/MobileOperatorsSelector';
  import UssdResponseNotify from  './notifications/UssdResponseNotify';
  import mSimCards from '../mixins/customer/mSimCards';
  import mCountries from '../mixins/data/mCountries';
  import {mapActions, mapState} from "vuex";

export default {
  name: 'SimList',
  components: {
    SimNumber,
    MobileOperatorIcon,
    ExtractSimNumberButton,
    ExtractSimBalanceButton,
    HideSimCardButton,
    HideSimCardsButton,
    LockItemButton,
    SimGsmLoginIndicator,
    SimBankLoginIndicator,
    MobileOperatorsSelector,
  },
  mixins: [ mSimCards, mCountries ],
  data () {
    return {
      items: [],
      locked_items: {},
      countries: [],
      filter: {
        searchByNumberOrImsi: '',
        operators: [],
        countries: [],
      },
      loading: false,
      showHidden: false,
    }
  },
  mounted() {
    this.rendering()
  },
  methods: {
    async rendering () {
      await this.getSimCards()
    },
    getSimCards() {
      this.loading = true;

        this.getCustomerSimCards(this.showHidden, this.filter)
        .then(cards => {
          this.items = cards;
        })
        .catch(e => {
          this.items = []
        })
        .finally(() => this.loading = false);
    },
    getCountries () {
      this.getCustomerSimCountries()
      .then(countries => {
        this.countries = countries;
      })
      .catch(e => {
        this.countries = [];
      });
    },
    setBalanceColor(sim) {
      let color = "default";
      if (sim['balance'] !== undefined && sim['gsm_login'] === 'LOGIN') {
        color = "success";
        // @TODO balance colorize should be customized by customer
        if (sim['balance'] < 10) return 'red';
        else if (sim['balance'] <= 100) return 'orange';
        return color
      }
      return color
    },
    ussdCommandResponseProcessing(notify) {
      let type = TYPE.DEFAULT;
      if (notify.failure) {
        type = TYPE.ERROR;
      }

      let content = {
        component: UssdResponseNotify,
        // Any prop can be passed, but don't expect them to be reactive
        props: {
          title: 'SIM: ' + notify.sim_imsi,
          text: notify.text
        },
      };

      const options = {
        position: POSITION.BOTTOM_RIGHT,
        maxToasts: 8,
        timeout: 10000,
        type: type
      };
      // send notify about ussd command response
      this.$toast(content, options);
      // send notify about extracted balance
      if (notify.balance) {
        options.type = TYPE.SUCCESS;
        this.$toast({
          component: UssdResponseNotify,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            title: 'SIM: ' + notify.sim_imsi,
            text: [this.$t('text.Current') + ' ' + this.$t('text.Balance').toLowerCase() + ': ' + notify.balance.value + ' ' + notify.balance.currency]
          },
        }, options);

        this.items.forEach(function(sim, i, cards) {
          if (sim.imsi === notify.sim_imsi) {
            Vue.set(cards[i], 'balance', notify.balance['value']);
            Vue.set(cards[i], 'balance_currency', notify.balance['currency']);
            Vue.set(cards[i], 'balance_extracted_at', notify.balance['extracted_at']);
            if (notify.balance['extracted_from'] === 1) {
              Vue.set(cards[i], 'balance_extracted_from', 'sms');
            } else if (notify.balance['extracted_from'] === 2) {
              Vue.set(cards[i], 'balance_extracted_from', 'ussd');
            } else {
              Vue.set(cards[i], 'balance_extracted_from', 'unknown');
            }

            return true
          }
        });
      }
      // send notify about extracted number
      if (notify.sim_number) {
        options.type = TYPE.SUCCESS;
        this.$toast({
          component: UssdResponseNotify,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            title: 'SIM: ' + notify.sim_imsi,
            text: [this.$t('text.Current') + ' ' + this.$t('text.Number').toLowerCase() + ': ' + notify.sim_number]
          },
        }, options);

        this.items.forEach(function(sim, i, cards) {
          if (sim.imsi === notify.sim_imsi) {
            Vue.set(cards[i], 'number', notify.sim_number);
            return true
          }
        });
      }
    },
    lock(sim_imsi) { // lock sim for other ussd commands
      Vue.set(this.locked_items, sim_imsi, true);
    },
    unlock(sim_imsi) { // unlock sim for other ussd commands
      Vue.set(this.locked_items, sim_imsi, false);
    },
    switch_sim_card_lock_state(sim_imsi, resource, lock_state) {
      this.items.forEach(function(sim, i, cards) {
        if (sim.imsi === sim_imsi) {
          Vue.set(cards[i], 'locked_for_' + resource, lock_state);
          return true
        }
      });
    },
    switch_sim_card_hiding(sim_imsi) {
      this.items.forEach(function(sim, i, cards) {
        if (sim.imsi === sim_imsi) {
          Vue.delete(cards, i);
          return true
        }
      });
    },
    switch_sim_cards_hiding() {
      this.showHidden = !this.showHidden;
      this.rendering()
    },
    resetAllFilters(){
      this.filter.searchByNumberOrImsi = '';
      this.filter.operators = [];
      this.filter.countries = [];
      this.$emit('sim-list-page-rendering', this.filter);
    },
  }
}

</script>

<style scoped lang="scss">
  .v-card__text {
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .v-card__title {
    padding: 4px;
  }
</style>
