<template>
      <v-btn
        :large="large"
        :small="small"
        @click="event"
      >
        {{ tooltip() }}
      </v-btn>
</template>

<script>
    export default {
        name: "HideSimCardsButton",
        props: {
            hide: {
                type: Boolean,
            },
            small: {
                type: Boolean,
            },
            large: {
                type: Boolean,
            }
        },
        methods: {
            event() {
                this.$emit('switch-sim-cards-hiding', this.hide);
            },
            icon() {
                if (this.hide) {
                    return "mdi-eye-off"
                }
                return "mdi-eye"
            },
            tooltip() {
                if (this.hide){
                    return this.$t('text.Hide_SIMs_archive')
                }
                return this.$t('text.Show_SIMs_archive')
            }
        }
    }
</script>

<style scoped>

</style>