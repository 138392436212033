
import { apiCall } from '../../components/api/v1/calls';

const DATETIME = [ new Date().setHours(0,0,0,0), new Date() ];

const state = {
  status: 'loading',
  datetime: DATETIME,
  data: [],
};

const getters = {
};

const actions = {
  load: function ({state, commit}) {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');

      const start_datetime = this._vm.$dayjs(state.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
      const end_datetime = this._vm.$dayjs(state.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');

      apiCall({
        endpoint: '/customer/getSimCallStats', method: 'POST', data: {
          // NOTE: all current browsers except for IE11 will return the user timezone as a string.
          // http://kangax.github.io/compat-table/esintl/#test-DateTimeFormat_resolvedOptions().timeZone_defaults_to_the_host_environment
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          start_datetime,
          end_datetime,
          hidden: false,
          top_10_worst_asr: true,
        },
      })
        .then(resp => {
          commit('status', 'success');
          // console.log('getSimCallStats - load() - resp:', resp);
          commit('data', resp.data.result);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
};

const mutations = {
  reset: (state) => {
    state.status = 'loading';
    state.datetime = DATETIME;
    state.data = [];
  },
  status: (state, payload) => {
    state.status = payload;
  },
  setDatetime: (state, payload) => {
    state.datetime = payload;
  },
  data: (state, payload) => {
    state.data = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
