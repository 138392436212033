<template>
  <v-navigation-drawer
          app="app"
          mini-variant
          clipped
          permanent
          dark
          color="primary"
  >
    <v-list
            nav
            dense
    >
      <tippy
              v-for="(item, index) in menu"
              :key="index"
              arrow
              interactive
              placement="left"
              sticky
              boundary="viewport"
      >
        <template v-slot:trigger>
          <v-list-item
                  v-if="!item.children"
                  link
                  :to="{name: item.link}"
                  exact
          >
            <v-list-item-icon style="margin-left: 0">
              <v-icon color="#0098D9">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
                  v-else
          >
            <v-list-item-icon style="margin-left: 0">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>

        <div>
          <v-list
                  dense
          >
            <v-list-item
                    v-if="!item.children"
                    link
                    :to="{name: item.link}"
                    exact
            >
              <div v-text="item.label"></div>
            </v-list-item>

            <v-list-item
                    v-else
            >
              <div v-text="item.label"></div>
            </v-list-item>

            <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    link
                    :to="{name: child.link}"
                    exact
            >
              <v-list-item-icon>
                <v-icon>
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                      :to="{name: child.link}"
                      v-text="child.label"
              />
            </v-list-item>
          </v-list>
        </div>
      </tippy>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    computed: {
      menu() {
        return [
          /*  @TODO need update
         {
           link: 'Dashboard',
           label: this.$t('text.Dashboard'),
           icon: 'mdi-view-dashboard',
         },
         */
          {
            link: 'GsmPortManager',
            label: this.$t('text.GSM_Ports').toUpperCase(),
            icon: 'mdi-apps',
          },
          {
            link: 'GsmGatewayManager',
            label: this.$t('text.GSM_GATEWAYS'),
            icon: 'mdi-router-wireless-settings',
          },
          {
            link: 'SimManager',
            label: this.$t('text.SIM_CARDS'),
            icon: 'mdi-sim',
          },
          {
            link: 'CallDetails',
            label: this.$t('text.Calls').toUpperCase(),
            icon: 'mdi-phone-log',
          },
          {
            link: 'SmsDetails',
            label: this.$t('text.SMS'),
            icon: 'mdi-forum',
          },
          {
            link: 'SendUSSDPage',
            label: this.$t('text.USSD_COMMANDS'),
            icon: 'mdi-swap-horizontal-bold',
          },
          {
            link: 'TelegramManager',
            label: this.$t('text.TELEGRAM_MANAGER'),
            icon: 'telegram',
          },
            /*
          {
            link: 'Integrations',
            label: this.$t('text.INTEGRATIONS'),
            icon: 'mdi-cog-transfer-outline',
            active: false,
            children: [
              {
                link: 'D',
                // label: this.$t('text.Daazweb'),
                icon: 'mdi-alpha-d-box',
              },
            ],
          },
             */
        ]
      }
    },
    methods: {
      closeGroups() {
        console.log('index', Object.entries(this.menu));
        for (const [index, item] of Object.entries(this.menu)) {
          console.log('index', index);
        }
      },
    },
  };
</script>

<style lang="scss">
  .tippy-popper {
    .v-list {
      background: transparent !important;
    }
  }
</style>

<style lang="scss" scoped>
  a {
    &, &:hover {
      text-decoration: none;
    }
  }

  .v-navigation-drawer--mini-variant {
    max-width: 78px;
  }
</style>
