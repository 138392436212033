<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
    :style="containerHeightStyle"
  >

      <div class="dt-right-top-picker">
        <v-row>

          <v-col
            cols="12"
            md="5"
          >
            <div class="b-date-picker-outer">
            <v-select
               v-model="selectedOriginator"
               :items="originators"
               item-text="origination_prefix"
               :label="$t('text.Originator')"
               @change="getStats"
            >
              <template v-slot:selection="data">
                {{ data.item['name'] + " (" + data.item['origination_prefix'] + ")" }}
              </template>
              <template v-slot:item="data">
                {{ data.item['name'] + " (" + data.item['origination_prefix'] + ")" }}
              </template>
            </v-select>
          </div>
          </v-col>
          <v-col cols="12" md="7">
            <div class="b-date-picker-outer">
              <eDateTimePicker
                :initial-datetime="datetime"
                @e-datetime-changed="changeDatetime"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider />

      <v-card
        class="pt-4"
        width="100%"
        outlined
      >
        <!--
        <v-card-title>
          {{ $t('text.Outgoing_calls_analytics') }}
        </v-card-title>
        -->
        <v-row>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="380"
              :options="chart1Options"
              :series="chart1.series"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="380"
              :options="chart4Options"
              :series="chart4.series"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="380"
              :options="chart3Options"
              :series="chart3.series"
            />
          </v-col>
          <v-col
            class="b-graph"
            cols="6"
          >
            <apexchart
              height="410"
              :options="chart2"
              :series="chart2.series"
            />
          </v-col>
        </v-row>
      </v-card>

  </v-layout>
</template>

<script>
    import dayjs from "dayjs"; // @TODO понять как использовать dayjs в computed property без импорта в данном компоненте
    import height from '../../mixins/height';
    import eDateTimePicker from '../filters/eDateTimePicker';
    import mCallsStats from "../../mixins/stats/mCallsStats";
    import {mapActions, mapState} from "vuex";

    export default {
       name: "OriginatorCharts",
       components: { eDateTimePicker },
       mixins: [ height, mCallsStats ],
       data: vm => ({
         loading: false,
         selectedOriginator: null,
         stats: {},
         // charts settings @TODO стандартизировать
         chart1: {
           series: [{
             data: [],
             type: 'column',
             name: vm.$t('text.answered_calls'),
           },{
             data: [],
             type: 'column',
             name: vm.$t('text.canceled_calls'),
           },{
             data: [],
             type: 'column',
             name: vm.$t('text.busy_calls'),
           }, {
             data: [],
             type: 'column',
             name: vm.$t('text.other_calls'),
           }, {
             data: [],
             type: 'line',
             name: vm.$t('text.minutes_billed'),
           }],
           chart: {
             type: 'bar',
             stacked: true,
             zoom: {
               enabled: true
             },
             toolbar: {
               show: true,
               offsetX: 0,
               offsetY: 0,
               tools: {
                 download: false,
                 selection: false,
                 zoom: true,
                 zoomin: true,
                 zoomout: true,
                 pan: false,
                 reset: true | '<img src="/static/icons/reset.png" width="20">',
               },
             },
           },
           colors: ['#00BF32', '#FFEB3B', '#9C27B0', '#A2ABA8', '#4C59D8'],
           dataLabels: {
             enabled: false,
           },
           stroke: {
             width: [1, 1, 1, 1, 3],
           },
           subtitle: {
             text: vm.$t('text.distribution_of_calls_and_minutes_grouped_by_hours'),
             align: 'center',
             margin: 0,
             offsetX: 0,
             offsetY: 15,
           },
           xaxis: {
             type: 'datetime',
             categories: [],
             labels: {
               hideOverlappingLabels: true,
               datetimeUTC: false // do not use UTC because we already provide offset
             },
             tooltip: {
               enabled: false
             }
           },
           yaxis: [
             {
               seriesName: vm.$t('text.answered_calls'),
               axisTicks: {
                 show: true,
               },
               axisBorder: {
                 show: true,
                 color: '#4C59D8'
               },
               labels: {
                 style: {
                   colors: '#4C59D8',
                 },
               },
               title: {
                 text: vm.$t('text.total_calls'),
                 style: {
                   color: '#4C59D8',
                 }
               },
             },
             {
               seriesName: vm.$t('text.answered_calls'),
               show: false,
             },
             {
               seriesName: vm.$t('text.canceled_calls'),
               show: false,
             },
             {
               seriesName: vm.$t('text.busy_calls'),
               show: false,
             },
             {
               seriesName: vm.$t('text.minutes_billed'),
               opposite: true,
               axisTicks: {
                 show: true,
               },
               axisBorder: {
                 show: true,
                 color: '#4C59D8'
               },
               labels: {
                 style: {
                   colors: '#4C59D8',
                 },
               },
               title: {
                 text: vm.$t('text.minutes_billed'),
                 style: {
                   color: '#4C59D8',
                 }
               },
             },
           ],
           tooltip: {
             shared: true,
             intersect: false,
             followCursor: true,
             x: {
               show: true,
               format: 'dd MMM HH:mm',
             },
             fixed: {
               enabled: true,
               position: 'topRight',
             }
           },
           legend: {
             horizontalAlign: 'center',
             offsetY: 5,
           },
           noData: {
             text: vm.$t('text.Please_wait'),
             align: 'center',
             verticalAlign: 'middle',
             offsetX: 0,
             offsetY: 0,
             style: {
               color: undefined,
               fontSize: '14px',
               fontFamily: undefined
             }
           },
         },
         chart2: {
           series: [{
             data: [],
             type: 'column',
             name: vm.$t('text.answered_calls'),
           }, {
             data: [],
             type: 'column',
             name: vm.$t('text.canceled_calls'),
           }, {
             data: [],
             type: 'column',
             name: vm.$t('text.busy_calls'),
           }, {
             data: [],
             type: 'column',
             name: vm.$t('text.other_calls'),
           }, {
             data: [],
             type: 'column',
             name: vm.$t('text.minutes_billed'),
           }],
           chart: {
             type: 'bar',
             stacked: false,
             toolbar : {
               show: false
             }
           },
           colors: ['#00BF32', '#FFEB3B', '#9C27B0', '#A2ABA8', '#4C59D8'],
           plotOptions: {
             bar: {
               barHeight: '100%',
               dataLabels: {
                 position: 'center',
               },
             },
           },
           dataLabels: {
             enabled: true
           },
           subtitle: {
             text: vm.$t('text.summary_statistics_for_the_selected_period'),
             align: 'center',
             margin: 0,
             offsetX: 0,
             offsetY: 15,
           },
           xaxis: {
             type: 'category',
             categories: [],
             labels: {
               hideOverlappingLabels: true,
               showDuplicates: false,
               datetimeUTC: true, // do not use UTC because we already provide offset
               formatter: function(value, timestamp, opts) {
                 return dayjs(value).format('D MMM')
               }
             },
             tooltip: {
               enabled: false
             }
           },
           tooltip: {
             shared: true,
             intersect: false,
             followCursor: true,
             x: {
               show: true,
               format: 'dd MMM',
             },
             fixed: {
               enabled: true,
               position: 'topRight',
             }
           },
           legend: {
             horizontalAlign: 'center',
             offsetX: 5,
           },
           noData: {
             text: vm.$t('text.Please_wait'),
             align: 'center',
             verticalAlign: 'middle',
             offsetX: 0,
             offsetY: 0,
             style: {
               color: undefined,
               fontSize: '14px',
               fontFamily: undefined
             }
           },
         },
         chart3: {
           series:[
             {
               data: [],
               name: vm.$t('text.total_calls'),
             }
           ],
           chart: {
             type: 'area',
             dropShadow: {
               enabled: true,
               top: 0,
               opacity: 0.25
             },
             toolbar: {
               show: true,
               offsetX: 0,
               offsetY: 0,
               tools: {
                 download: false,
                 selection: false,
                 zoom: true,
                 zoomin: true,
                 zoomout: true,
                 pan: false,
                 reset: true | '<img src="/static/icons/reset.png" width="20">',
               },
             },
             offsetX: 0,
             offsetY: 0,
           },
           dataLabels: {
             enabled: false,
           },
           colors: ['#00a9ff'],
           fill: {
             type: "gradient",
             gradient: {
               shadeIntensity: 1,
               opacityFrom: 0.7,
               opacityTo: 0.9,

             }
           },
           responsive: [{
             breakpoint: 480,
             options: {
               legend: {
                 position: 'bottom',
                 offsetX: 0,
                 offsetY: 0
               }
             }
           }],
           plotOptions: {
             bar: {
               horizontal: false,
             },
           },
           subtitle: {
             text: vm.$t('text.distribution_of_calls_intensity_grouped_by_hours'),
             align: 'center',
             margin: 0,
             offsetX: 0,
             offsetY: 15,
           },
           xaxis: {
             type: 'datetime',
             categories: [],
             labels: {
               hideOverlappingLabels: true,
               datetimeUTC: false // do not use UTC because we already provide offset
             }
           },
           grid: {
             padding: {
               top: 0,
               right: 30,
               bottom: 0,
               left: 0
             }
           },
           stroke: {
             curve: 'smooth',
             width: 3,
           },
           tooltip: {
             shared: true,
             intersect: false,
             x: {
               format: 'dd MMM HH:mm',
             },
             fixed: {
               enabled: true,
               position: 'topRight',
             }
           },
           noData: {
             text: vm.$t('text.Please_wait'),
             align: 'center',
             verticalAlign: 'middle',
             offsetX: 0,
             offsetY: 0,
             style: {
               color: undefined,
               fontSize: '14px',
               fontFamily: undefined
             }
           },
         },
         chart4: {
           series: [
             {
               data: [],
               type: 'line',
               name: vm.$t('text.Answer_Seizure_Ratio'),
             },
             {
               data: [],
               type: 'line',
               name: vm.$t('text.Average_Call_Duration'),
             },
           ],
           chart: {
             type: 'line',
             zoom: {
               show: false,
             },
             toolbar: {
               show: true,
               offsetX: 0,
               offsetY: 0,
               tools: {
                 download: false,
                 selection: false,
                 zoom: true,
                 zoomin: true,
                 zoomout: true,
                 pan: false,
                 reset: true | '<img src="/static/icons/reset.png" width="20">',
               },
             }
           },
           colors: ['#00E396','#008FFB'],
           dataLabels: {
             enabled: true,
             formatter:function(val, opts){
               if(val > 0)
                 return val;
             }
           },
           stroke: {
             width: [3, 3]
           },
           subtitle: {
             text: vm.$t('text.dynamic_of_changes_originator_asr_acd_by_hour'),
             align: 'center',
             margin: 0,
             offsetX: 0,
             offsetY: 15,
           },
           xaxis: {
             type: 'datetime',
             categories: [],
             labels: {
               hideOverlappingLabels: true,
               datetimeUTC: false // do not use UTC because we already provide offset
             },
           },
           yaxis: [
             {
               seriesName: vm.$t('text.Answer_Seizure_Ratio'),
               opposite: false,
               axisTicks: {
                 show: true,
               },
               axisBorder: {
                 show: true,
                 color: '#00E396'
               },
               labels: {
                 style: {
                   colors: '#00E396',
                 },
               },
             },
             {
               seriesName: vm.$t('text.Average_Call_Duration'),
               opposite: true,
               axisTicks: {
                 show: true,
               },
               axisBorder: {
                 show: true,
                 color: '#008FFB'
               },
               labels: {
                 style: {
                   colors: '#008FFB',
                 },
               },
             },
           ],
           tooltip: {
             shared: true,
             intersect: false,
             followCursor: true,
             x: {
               show: true,
               format: 'dd MMM HH:mm',
             },
             fixed: {
               enabled: true,
               position: 'topRight',
             }
           },
           legend: {
             horizontalAlign: 'center',
             offsetY: 5,
           },
           noData: {
             text: vm.$t('text.Please_wait'),
             align: 'center',
             verticalAlign: 'middle',
             offsetX: 0,
             offsetY: 0,
             style: {
               color: undefined,
               fontSize: '14px',
               fontFamily: undefined
             }
           },
         },
         datetimeDefault: [ dayjs().subtract(24, 'hour'), dayjs() ], // default is one week day from 00:00
         datetime: [ dayjs().subtract(24, 'hour'), dayjs() ], // default is one week day from 00:00
       }),
       computed: {
         ...mapState("originators", {
           originators: state => state.originators,
         }),
         chart1Options() {
           return {
             ...this.chart1,
             chart: {
               ...this.chart1.chart,
               events: {
                 zoomed: (chartContext, { xaxis, yaxis }) => {
                   this.updateChart({ chart: 'chart1', xaxis, interval: 'hour' });
                 },
               },
             },
           };
         },
         chart4Options() {
           return {
             ...this.chart4,
             chart: {
               ...this.chart4.chart,
               events: {
                 zoomed: (chartContext, { xaxis, yaxis }) => {
                   this.updateChart({ chart: 'chart4', xaxis, interval: 'hour' });
                 },
               },
             },
           };
         },
         chart3Options() {
           return {
             ...this.chart3,
             chart: {
               ...this.chart3.chart,
               events: {
                 zoomed: (chartContext, { xaxis, yaxis }) => {
                   this.updateChart({ chart: 'chart3', xaxis, interval: 'hour' });
                 },
               },
             },
           };
         },
       },
       mounted() {
          this.getStats();
          // this.hideS();
       },
       methods: {
        ...mapActions("originators", ["getOriginators"]),
        changeDatetime(dtime) {
           this.datetime = dtime;
           this.getStats()
        },
        async getStats() {
          let originator = "";
          if (!this.selectedOriginator) {
             await this.getOriginators();
             if (Array.isArray(this.originators) && this.originators.length > 0 && this.originators[0].origination_prefix)
               this.selectedOriginator = this.originators[0].origination_prefix;
             else {
               // #TODO noData information should be changed here
               return
             }
          }
          // @TODO change originator_prefix to character varying, so it can contain many other simbols (not number only), so we will have flexibility
          this.getOriginatorCallsStats('hour', parseInt(this.selectedOriginator, 10));
          this.getOriginatorCallsStats('month', parseInt(this.selectedOriginator, 10));
        },
        getOriginatorCallsStats(interval, originator) {
          this.loading = true;
          // datetime from/to validation
          if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
            this.datetime = this.datetimeDefault
          }
          const start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
          const end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');
          // console.log(`start time ${start_datetime} end time ${end_datetime}`)
          this.getCallsStatsGroupByInterval('outgoing', start_datetime, end_datetime, interval, false,
                 undefined,
                 undefined,
                 undefined,
                 undefined,
                  originator)
          .then(stats => {
            this.$set(this.stats, interval, stats);
            if (interval === 'month') {
              this.updateChart({ chart: 'chart2', interval });
            }
            if (interval === 'hour') {
              this.updateChart({ chart: 'chart1', interval });
              this.updateChart({ chart: 'chart3', interval });
              this.updateChart({ chart: 'chart4', interval });
            }
          })
          .catch(e => {
            // console.log(e);
          })
          .finally(() => this.loading = false);
        },
        updateChart({ chart, xaxis, interval = 'hour' }) {
          const updatedStats = !xaxis
            ? this.stats[interval]
            : this.stats[interval].filter(s => {
              const date = new Date(s.date).getTime();
              const checkMin = !xaxis.min || date >= xaxis.min;
              const checkMax = !xaxis.max || date <= xaxis.max;
              return checkMin && checkMax;
            });

          const dates = [];
          const answered = [];
          const canceled = [];
          const busy = [];
          const other = [];
          const total = [];
          const billmin = [];
          const asr = [];
          const acd = [];

          for (const i of updatedStats) {
            dates.push(i.date);
            answered.push(i.calls_answered);
            canceled.push(i.calls_canceled);
            busy.push(i.calls_busy);
            other.push(i.calls_total - (i.calls_answered + i.calls_canceled + i.calls_busy));
            total.push(i.calls_total);
            billmin.push(i.billmin);
            asr.push(i.asr);
            acd.push(i.acd);
          }

          if (chart === 'chart1') {
            this[chart].series[0].data = answered;
            this[chart].series[1].data = canceled;
            this[chart].series[2].data = busy;
            this[chart].series[3].data = other;
            this[chart].series[4].data = billmin;
          }

          if (chart === 'chart2') {
            this[chart].series[0].data = answered;
            this[chart].series[1].data = canceled;
            this[chart].series[2].data = busy;
            this[chart].series[3].data = other;
            this[chart].series[4].data = billmin;
          }

          if (chart === 'chart3') {
            this[chart].series[0].data = total;
          }

          if (chart === 'chart4') {
            this[chart].series[0].data = asr;
            this[chart].series[1].data = acd;
          }

          this[chart] = {
            ...this[chart],
            xaxis: {
              categories: dates
            },
          };
        },
      },
    }
</script>

<style scoped>
  .b-graph {
    padding: 0px;
    padding-left: 14px;
    padding-right: 14px;
  }
  .v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select {
    margin-top: 11px;
  }
  .v-input.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-select primary--text {
    margin-top: 1px;
  }
  .v-input.theme--light.v-text-field.v-text-field--is-booted.v-select {
    margin-top: 1px;
  }
</style>
