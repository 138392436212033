<template>
  <v-layout
    ref="containerHeight"
    row
    wrap
  >
    <div class="b-fixed-height">
      <v-card
        class="mb-3"
        width="100%"
      >
        <v-card-text>
          <v-row
            no-gutters
            style="height: 64px;"
          >
            <!-- search by SIM number or SIM IMSI code -->
            <v-col
              cols="2"
              md="2"
            >
              <v-text-field
                v-model="filter.searchByNumberOrImsi"
                append-icon="search"
                :label="$t('text.Search_by_SIM_IMSI_or_Number')"
                clearable
                hide-details
              />
            </v-col>
            <!-- filter by SIM mobile operator  -->
            <v-col
              cols="2"
              md="2"
            >
              <MobileOperatorsSelector
                v-model="filter.operators"
                @on-select="rendering"
              />
            </v-col>
            <!-- filter by mobile operator country -->
            <v-col
              cols="2"
              md="2"
            >
              <v-select
                v-model="filter.countries"
                :label="$t('text.Country')"
                :items="countries"
                item-value="iso2"
                item-text="name"
                multiple
                @focus="getCountries()"
                @change="rendering()"
              />
            </v-col>
            <!-- datetime picker -->
            <v-col
              cols="4"
              md="4"
            >
              <div class="pt-md-3">
                <eDateTimePicker 
                  :initial-datetime="datetime"
                  @e-datetime-changed="changeDatetime"
                />
              </div>
            </v-col>
            <!-- Reset filters button -->
            <v-btn
              class="text-center mt-3"
              @click="resetAllFilters"
            >
              {{ $t('text.Reset') }}
            </v-btn>
            <!-- Show hidden SIMs button -->
            <div :style="{'position': 'absolute', 'right': '18px', 'top': '50%', 'transform': 'translateY(-50%)'}">
              <HideSimCardsButton
                large
                :hide="showHidden"
                @switch-sim-cards-hiding="switch_sim_cards_hiding"
              />
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div class="b-fixed-height">
      <v-divider />
      <v-list dense>
        <template v-for="item in items">

          <v-list-item
            :key="item['imsi']"
            :style="{ 'max-height': '58px' }"
          >
            <v-list-item-avatar>
              <MobileOperatorIcon
                :icon-name="item['mobile_operator_name']"
                :width="22"
                :height="22"
                compact
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <span class="text-center font-weight-bold">
                  {{ $t('text.SIM_Number') }}: {{ prepareSimNumber(item["number"]) }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>
                  ICCID: {{ item["iccid"] }} IMSI: {{ item['imsi'] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- SIM GSM status indication -->
            <v-list-item-avatar>
              <SimGsmLoginIndicator
                      v-bind="{ sim: item }"
                      large
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-center font-weight-bold">
                  {{ $t('text.GSM_Gateway') }}:  {{ item['gsm_gateway_alias'] }} {{ $t('text.port') }}: {{ item["gsm_port"] }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item['gsm_login'] === 'LOGIN'">
                <span>
                  IMEI: {{ item["imei"] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- ASR/ACD -->
            <template v-if="typeof item['call_stats']['asr'] !== 'undefined' && typeof item['call_stats']['acd'] !== 'undefined'">
              <v-list-item-avatar
                :style="{ 'margin-right': '12px'}"
              >
                <v-progress-circular
                  :rotate="90"
                  :size="40"
                  :width="4"
                  :value="item['call_stats']['asr']"
                  :color="getAsrColor(item['call_stats']['asr'])"
                >
                  {{ item['call_stats']['asr'] }}
                </v-progress-circular>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-center font-weight-bold">
                    ASR/ACD: {{ item['call_stats']['asr'] }}/{{ item['call_stats']['acd'] }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>
                    {{ $t('text.Answered') }}: {{ item['call_stats']['asr'] }}% {{ $t('text.Calls_num').toLowerCase() }}, {{ $t('text.Average_Call_Duration') }}: {{ item['call_stats']['acd'] }} {{ $t('text.sec') }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <!-- Calls stats -->
            <template v-if="typeof item['call_stats']['calls_total'] !== 'undefined'">
              <v-list-item-avatar
                :style="{ 'margin-right': '12px'}"
              >
                <v-icon
                  large
                  color="blue"
                >
                  mdi-finance
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-center font-weight-bold">
                    {{ item['call_stats']['billmin'] }} minutes billed
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text-center">
                    {{ item['call_stats']['calls_total'] }} calls total, {{ item['call_stats']['calls_answered'] }} calls answered
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>

            </template>
            <!-- AQ @TODO temporarily disabled (need fix block styles or add more data to this block)
            <template v-if="typeof item['call_stats']['audio_quality'] !== 'undefined'">
              <v-list-item-avatar
                :style="{ 'margin-right': '12px'}"
              >
                <v-progress-circular
                  :rotate="90"
                  :size="40"
                  :width="4"
                  :value="item['call_stats']['audio_quality']"
                  :color="getAQColor(item['call_stats']['audio_quality'])"
                >
                  {{ item['call_stats']['audio_quality'] }}
                </v-progress-circular>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-center">
                    Audio Quality
                  </span>
                </v-list-item-title>
              </v-list-item-content>
             </template>
              -->
            <!-- Hide SIM action button --->
            <v-list-item-action>
              <HideSimCardButton
                v-bind="{ sim: item }"
                @switch-sim-card-hiding="switch_sim_card_hiding"
              />
            </v-list-item-action>
          </v-list-item>
          <v-divider
            :key="item['imsi'] + item['imei']"
            inset
          />
        </template>
      </v-list>
    </div>
  </v-layout>
</template>

<script>
  import Vue from 'vue';
  import HideSimCardButton from './elements/buttons/HideSimCardButton';
  import HideSimCardsButton from './elements/buttons/HideSimCardsButton';
  import MobileOperatorIcon from './elements/MobileOperatorIcon';
  import MobileOperatorsSelector from './filters/MobileOperatorsSelector';
  import eDateTimePicker from './filters/eDateTimePicker';
  import height from '../mixins/height';
  import mCountries from '../mixins/data/mCountries';
  import mCallsStats from '../mixins/stats/mCallsStats';
  import SimGsmLoginIndicator from "./elements/SimCard/SimGsmLoginIndicator";

    export default {
        name: 'SimCallsStats',
      components: {
          HideSimCardButton,
          HideSimCardsButton,
          eDateTimePicker,
          SimGsmLoginIndicator,
          MobileOperatorIcon,
          MobileOperatorsSelector
      },
      mixins: [
          height, mCountries, mCallsStats
        ],
        data () {
            return {
                items: [],
                countries: [],
                filter: {
                  searchByNumberOrImsi: '',
                  operators: [],
                  countries: [],
                },
                loading: false,
                showHidden: false,
                datetimeDefault: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
                datetime: [ this.$dayjs().startOf('day'), this.$dayjs() ], // default is current day from 00:00
            }
        },
        mounted () {
            this.rendering()
        },
        methods: {
            rendering () {
              this.getStats()
            },
            getStats() {
              this.loading = true;
              // datetime from/to validation
              if (!Array.isArray(this.datetime) || this.datetime.length !== 2) {
                this.datetime = this.datetimeDefault
              }
              const start_datetime = this.$dayjs(this.datetime[0]).utc().format('YYYY-MM-DD HH:mm:ss');
              const end_datetime = this.$dayjs(this.datetime[1]).utc().format('YYYY-MM-DD HH:mm:ss');

              this.getCallStatsGroupBySim('outgoing', start_datetime, end_datetime, this.showHidden, this.filter)
              .then(stats => {
                this.items = stats;
              })
              .catch(e => {
                this.items = [];
              })
              .finally(() => this.loading = false);
            },
            getCountries () {
              this.getCustomerSimCountries()
              .then(countries => {
                this.countries = countries;
              })
              .catch(e => {
                this.countries = [];
              })
            },
            changeDatetime(dtime) {
              this.datetime = dtime;
              this.rendering()
            },
            getBalanceColor (balance) {
                if (balance < 0.5) return 'red';
                else if (balance <= 1) return 'orange';
                else return 'green'
            },
            getAsrColor (asr) {
              if (asr === 0) return 'gray';
              else if (asr > 0 && asr <= 30) return 'red';
              else if (asr > 30 && asr <= 50) return 'orange';
              else return 'green'
            },
            getAQColor (aq) {
              if (aq === 0) return 'gray';
              else if (aq > 0 && aq <= 65) return 'red';
              else if (aq > 65 && aq <= 79) return 'orange';
              else return 'green'
            },
            prepareSimNumber(number) {
              if (number && number !== "unknown") {
                return number
              }
              return ""
          },
          switch_sim_card_hiding(sim_imsi) {
            this.items.forEach(function(sim, i, cards) {
              if (sim.imsi === sim_imsi) {
                Vue.delete(cards, i);
                return true
              }
            });
          },
          switch_sim_cards_hiding() {
            this.showHidden = !this.showHidden;
            this.rendering()
          },
          resetAllFilters(){
            this.filter.searchByNumberOrImsi = '';
            this.filter.operators = [];
            this.filter.countries = [];
            this.showHidden = false;
            this.datetime = this.datetimeDefault;
            this.rendering()
          },
        }
    }

</script>

<style scoped lang="scss">
  .v-card__text {
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .v-card__title {
    padding: 4px;
  }
</style>
