<template>
  <svg-icon-loader
    :icon-path="loadIcon"
    :width="width"
    :height="height"
    :fill="fill"
    :stroke="stroke"
    :generate-ready-event="generateReadyEvent"
    @ready="$emit('svg-icon-ready')"
  />
</template>

<script>
    import {indicators} from "../../data/indicators";
    import SvgIconLoader from '../../components/svg/SvgIconLoader'

    export default {
        name: "IndicatorsIcons",
        components: { SvgIconLoader },
        props: {
            // Имя файла (без расширения)
            iconName: {
                type: String,
                required: true
            },

            // Ширина
            width: {
                type: [Number, String],
                default: 32,
            },

            // Высота
            height: {
                type: [Number, String],
                default: 16,
            },

            // Цвет заливки
            fill: {
                type: String,
                default: '#6F6F6F'
            },

            // Цвет обводки
            stroke: {
                type: String,
                default: 'none'
            },

            // Генерировать ли событие ready иконки
            generateReadyEvent: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            loadIcon() {
                const operatorObject = indicators[this.iconName.toLowerCase()];
                if (!operatorObject) {
                    // @TODO set default icon here (unknown/neutral icon...)
                    return "";
                }
                return operatorObject.icon
            },
        }
    }
</script>

<style scoped>

</style>