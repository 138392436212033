<template>
  <v-app
    id="app"
    dark
    align-with-title="true"
  >
    <!-- Drawer -->
    <NavDrawer
      v-if="$route.name !== 'Login'"
    />

    <!-- top toolbar -->
    <v-app-bar
      app
      clipped-left
      color="#0098D9"
      height="60"
    >
      <v-toolbar-title>
        <router-link
          to="/"
          tag="span"
          style="cursor: pointer"
        >
          <span :style="{color: '#ffffff', fontFamily: 'PorterFT-Bold,Roboto,sans-serif', fontSize: '24px', minWidth: '180px', cursor: 'pointer'}">{{ $t('text.AppTitle') }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <!--
      <div v-if="isAuthenticated">
         <PhoneComponent
            v-show="getShowPhone"
        />
      </div>
      -->
      <AppBarLang/>

      <template v-if="isAuthenticated">
        <!--
     <v-btn
         icon
         color="white"
         @click="switchShowPhone()"
     >
       <v-icon>phone</v-icon>
     </v-btn>
     -->
        <!-- <v-btn icon color="white">
          <v-icon>mdi-tune</v-icon>
        </v-btn>
        <v-btn icon color="white">
          <v-icon>mdi-bell</v-icon>
        </v-btn> -->
        <v-btn icon color="white">
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn
          class="hidden-xs-only"
          color="white"
          icon
          @click="logout"
        >
          <v-icon>logout</v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <!-- content router -->
    <v-main>
      <v-container
          class="content-container-class"
          fluid
      >
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import NavDrawer from './components/NavDrawer';
  import AppBarLang from './components/appBar/Lang';
  //import PhoneComponent from './components/PhoneComponent'
  import { AUTH_LOGOUT } from './store/actions/auth';
  import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'App',
  components: {
    NavDrawer,
    AppBarLang,
  },
  data() {
    return {
      sidebar: false,
      phone: false,
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
    },
    ...mapActions("phoneState", ["switchShowPhone"]),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters("phoneState", ['getShowPhone',]),
  },
};
</script>

<style lang="scss">
  #app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .v-input__control {
    /*margin-top: 22px;*/
    margin-right: 5%;
  }

  #content-container-class {
    width: 100%;
    padding: 4px;
    margin-right: auto;
    margin-left: auto;
  }

  .v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select {
    padding-top: 0;
    margin-top: 21px;
  }

  @font-face {
    font-family: "PorterFT-Bold";
    src: local("PorterFT-Bold"),
    url(./assets/fonts/PorterFT-Bold.woff2) format("truetype");
  }
</style>
