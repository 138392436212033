import { apiCall } from '../../components/api/v1/calls';
import { operators } from '../../data/operators';

export default {
  data: () => ({
    mobileOperators: [],
  }),
  methods: {
    getMobileOperators () {
      apiCall({endpoint: '/customer/getSimMobileOperators', method: 'GET', data: null})
        .then(response => {
          this.mobileOperators = Array.isArray(response.data.result) && response.data.result.map(o => {
            if (o.name) {
              const operatorObject = operators[o.name.toLowerCase().replace(/\s/g, '')];
              if (operatorObject) {
                o.icon = process.env.VUE_APP_ROOT_DIR + operatorObject.icon;
              }
            }
            return o;
          });
          return response.data.result
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
  },
};
