<template>
  <v-select
    v-model="_value"
    :label="$t('text.sim_mobile_operator')"
    :items="mobileOperators"
    item-value="hni"
    multiple
    clearable
    hide-details
    @focus="getMobileOperators"
    @change="$emit('on-select')"
  >
    <template v-slot:selection="{ item }">
      <div :style="{padding: `4px`}">
        <MobileOperatorIcon
          :icon-name="item.name"
          compact
          :height="22"
          :width="22"
          style="margin-right: 4px;"
        />
        <span :style="{textAlign: `center`, verticalAlign: `middle`}">{{ item.name }}</span>
      </div>
    </template>
    <template v-slot:item="data">
      <v-simple-checkbox
        class="mr-2"
        :value="data.attrs.inputValue"
      />
      <MobileOperatorIcon
        :icon-name="data.item.name"
        compact
        :height="22"
        :width="22"
        style="margin-right: 4px;"
      />
      <span :style="{textAlign: `center`, verticalAlign: `middle`}">{{ data.item.name }}</span>
    </template>
  </v-select>
</template>

<script>
  import getMobileOperators from '../../mixins/data/getMobileOperators';
  import MobileOperatorIcon from "../elements/MobileOperatorIcon";

  export default {
    name: 'MobileOperatorsSelector',
    components: {MobileOperatorIcon},
    mixins: [
      getMobileOperators,
    ],
    props: [
      // eslint-disable-next-line vue/require-prop-types
      'value',
    ],
    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        },
      }
    },
  };
</script>

<style lang="scss">
</style>
