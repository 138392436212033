<template>
  <!-- @TODO оформить dashboard используя apexchart пример тут https://apexcharts.com/javascript-chart-demos/sparklines/basic/ -->
  <v-layout
      row
      wrap
      align-center
  >
    <v-card
        class="mb-3"
        width="100%"
    >
      <v-card-title>
        {{ $t('text.Dashboard') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <GsmGatewayPortsCount/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DialOutGroupsCallStats/>
          </v-col>
          <v-col>
            <GsmGatewaysCallStats/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SimCallStats/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
  import GsmGatewayPortsCount from './dashboard/GsmGatewayPortsCount';
  import DialOutGroupsCallStats from './dashboard/DialOutGroupsCallStats';
  import GsmGatewaysCallStats from './dashboard/GsmGatewaysCallStats';
  import SimCallStats from './dashboard/SimCallStats';

  export default {
    components: {
      GsmGatewayPortsCount,
      DialOutGroupsCallStats,
      GsmGatewaysCallStats,
      SimCallStats,
    },
  };
</script>
