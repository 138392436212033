<template>
    <v-tooltip
        open-delay="600"
        close-delay="600"
        top
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-2"
                :color="color(filepath)"
                :small="small"
                :large="large"
                :size="size"
                v-bind="attrs"
                v-on="on"
                @click="action(domain, filepath)"
                :disabled="disabled(filepath)"
            >
                {{ icon() }}
            </v-icon>
        </template>
        <span> {{ tooltip() }} </span>
    </v-tooltip>
</template>

<script>
    import {apiDownloadFile} from "../../api/v1/calls";

    export default {
        name: "DownloadButton",
        props: {
            domain: {
                type: String,
                default: function () {
                    return ''
                }
            },
            filepath: {
                type: String,
                default: function () {
                    return ''
                }
            },
            small: {
                type: Boolean,
            },
            large: {
                type: Boolean,
            },
            size: {
                type: Number,
            }
        },
        methods: {
            color(filepath) {
                if (filepath !== "") {
                    return "blue"
                }
                return "gray"
            },
            action(domain, filepath) {
                let filename = filepath.split('/');
                if (Array.isArray(filename) && filename.length > 0) {
                    let fileName = filename[filename.length - 1];
                    apiDownloadFile({endpoint: '/audiorecords/' + domain + "/" + filepath, fileName})
                }
            },
            disabled(filepath) {
                return filepath === "";
            },
            icon(){
                return "mdi-download"
            },
            tooltip() {
                return this.$t('text.Download')
            }
        }
    }
</script>

<style scoped>

</style>