import Vue from "vue";
import VueRouter from "vue-router";
import Login from '../components/login/Login'
import store from '../store'
import SimList from '../components/SimList';
import SimCallsStats from '../components/SimCallsStats';
import CallDetailsRecords from '../components/CallDetailsRecords';
import GsmGatewayManager from "../components/GsmGatewayManager";
import GsmPortManager from '../components/GsmPortManager';
import CallDetails from '../components/CallDetails';
import SimManager from '../components/SimManager';
import SmsDetails from '../components/SmsDetails';
import SmsSentRecords from '../components/SmsSentRecords';
import SmsRecvRecords from '../components/SmsRecvRecords';
import SendUSSDPage from '../components/SendUSSDPage'
// import Daazweb from "../components/Daazweb";
import Dashboard from '../components/Dashboard';
import TelegramManager from "../components/TelegramManager";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return
  }
  next('/')
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return
  }
  next('/login')
};

const routes = [
  { path: '/', redirect: { name: 'GsmPortManager' }},
  { path: '/login', name: 'Login', component: Login, beforeEnter: ifNotAuthenticated },
  {
    path: '/GsmPortManager',
    name: 'GsmPortManager',
    component: GsmPortManager,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/GsmGatewayManager',
    name: 'GsmGatewayManager',
    component: GsmGatewayManager,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SimList',
    name: 'SimList',
    component: SimList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SimCallsStats',
    name: 'SimCallsStats',
    component: SimCallsStats,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/CallDetailsRecords',
    name: 'CallDetailsRecords',
    component: CallDetailsRecords,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SmsSentRecords',
    name: 'SmsSentRecords',
    component: SmsSentRecords,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SmsRecvRecords',
    name: 'SmsRecvRecords',
    component: SmsRecvRecords,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SendUSSDPage',
    name: 'SendUSSDPage',
    component: SendUSSDPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SimManager',
    name: 'SimManager',
    component: SimManager,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/CallDetails',
    name: 'CallDetails',
    component: CallDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/SmsDetails',
    name: 'SmsDetails',
    component: SmsDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/TelegramManager',
    name: 'TelegramManager',
    component: TelegramManager,
    beforeEnter: ifAuthenticated,
  },
  /* disabled because all clients left
  {
    path: '/D',
    name: 'D',
    component: Daazweb,
    props: { isVisible: true},
    beforeEnter: ifAuthenticated,
  },
   */
];
// Подробнее про history https://router.vuejs.org/ru/guide/essentials/history-mode.html#%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B3%D1%83%D1%80%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D1%81%D0%B5%D1%80%D0%B2%D0%B5%D1%80%D0%BE%D0%B2
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // Reset tab on route change.
  store.commit('tabs/updateField', {
    path: 'tab',
    value: null,
  });
  next();
})

export default router;
