<template>
    <v-tooltip
        open-delay="800"
        close-delay="800"
        top
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-2"
                :color="group !== undefined && group['name'] !== undefined ? 'success' : 'gray'"
                :large="large"
                :small="small"
                :size="size"
                v-bind="attrs"
                v-on="on"
            >
                mdi-select-group
            </v-icon>
        </template>
        <span> {{ $t('text.sim_group') }} </span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "SimGroupIndicator",
        props: {
            group: {
                type: Object,
                default: function () {
                    return undefined
                }
            },
            large: {
                type: Boolean
            },
            small: {
                type: Boolean
            },
            size: {
                type: Number,
            }
        },
    }
</script>

<style scoped>

</style>