<template>
  <div class="row">
    <div class="pull-left">
      <div class="form-group col-xs-6">
        <GetGsmGateways />
      </div>
    </div>
    <div class="pull-left">
      <div class="form-group col-xs-6">
        <GetGsmGatewayPorts />
      </div>
    </div>
    <!-- отправку ussd команды пока не стал выносить в отдельный компонент -->
    <div class="pull-left">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="command"
              clearable
              label="Введите USSD команду"
              type="text"
            >
              <template v-slot:append-outer>
                <v-btn
                  color="primary"
                  small
                  @click="sendUssd"
                >
                  <v-icon left>
                    mdi-target
                  </v-icon>Отправить
                </v-btn>

                <v-dialog
                  v-model="loading"
                  hide-overlay
                  persistent
                  width="300"
                >
                  <v-card
                    color="primary"
                    dark
                  >
                    <v-card-text>
                      Пожалуйста, ожидайте
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="text-center">
      <v-dialog
        v-model="response"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            {{ commandResponseTitle }}
          </v-card-title>

          <v-card-text>
            {{ $t('text.USSD_RESPONSE') }} {{ commandResponse }}
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="response = false"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueBus from 'vue-bus'
import GetGsmGateways from './GetGsmGateways'
import GetGsmGatewayPorts from './GetGsmGatewayPorts'
import {apiCall} from "./api/v1/calls";

Vue.use(VueBus);

export default {
  name: 'SendUSSDPage',
  components: { GetGsmGateways, GetGsmGatewayPorts},
  data () {
    return {
      selectedGateway: null,
      selectedPort: null,
      selectedSim: null,
      command: '',
      commandResponse: '',
      commandResponseTitle: '',
      response: false,
      loading: false,
    }
  },
  mounted () {
    this.setVarsToDefault();
    // subscribe on events
    this.$bus.on('selected-gateway', this.saveGateways);
    this.$bus.on('selected-gateway-port', this.onPortSelected)
  },
  methods: {
    saveGateways (gateway) {
      this.setVarsToDefault();
      this.selectedGateway = gateway;
      // console.log('selected-gateway: ', gateway)
    },
    onPortSelected (port, imsi) {
      this.setVarsToDefault();
      this.selectedPort = port;
      this.selectedSim = imsi;
      // console.log('selected-gateway-port: %s sim %s', port, imsi)
    },
    // отправку ussd команды пока не стал выносить в отдельный компонент
    sendUssd () {
      if (this.selectedGateway !== null && this.selectedPort !== null && this.selectedSim !== null && this.command !== '') {
        this.loading = true;
        apiCall({endpoint: '/customer/sendUSSDCommand', method: 'POST', data: {
            goip_gateway: this.selectedGateway,
            goip_port: this.selectedPort,
            sim_imsi: this.selectedSim,
            ussd_command: this.command
          }})
        .then(response => {
          if (Array.isArray(response.data.result['ussd_response'])) {
            this.commandResponse = response.data.result['ussd_response'][0];
          } else {
            this.commandResponse = response.data.result['ussd_response'];
          }
          this.commandResponseTitle = 'USSD Command success';
          this.loading = false;
          this.response = true;
        })
        .catch(e => {
          // will show default values for commandResponse and commandResponseTitle
          this.loading = false;
          this.response = true;
          // console.log('couldn\'t send USSD command, ', error)
          // this.errors.push(e)
        });
      } else {
        this.commandResponse = 'Пожалуйста, укажите шлюз и порт отправки';
        this.response = true;
      }
    },
    setVarsToDefault(){
      this.commandResponse = 'Не удалось отправить команду';
      this.commandResponseTitle = 'USSD Command error'
    }
  }
}
</script>

<style scoped>

</style>
