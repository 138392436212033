import {apiCall} from "../../components/api/v1/calls";

export default {
    name: "mGsmGateways",
    methods: {
         getCustomerGsmGateways (gateway) {
             return new Promise(function(resolve, reject) {
                 let url = '/customer/GsmGateways';
                 if (gateway) {
                     url = url + '/' + gateway
                 }

                 apiCall({endpoint: url, method: 'GET', data: null})
                 .then(response => {
                     if (response.status === 200) {
                         // console.debug(response);
                         resolve(response.data.result)
                     } else {
                         const e = new Error("cannot get gsm gateways: non 200 response code received");
                         console.debug(e);
                         reject(e)
                     }
                 })
                 .catch(e => {
                     console.debug("cannot get gsm gateways");
                     reject(e)
                 });
             })
        },
        getCustomerGsmGatewayPorts (gateway) {
            return new Promise(function(resolve, reject) {
                if (gateway !== undefined && gateway !== '') {
                    apiCall({endpoint: '/customer/getGsmGatewayPorts/' + gateway, method: 'GET', data: null})
                    .then(response => {
                        if (response.status === 200) {
                            // console.debug(response);
                            resolve(response.data.result)
                        } else {
                            const e = new Error("cannot get gsm gateway ports: non 200 response code received");
                            console.debug(e);
                            reject(e)
                        }
                    })
                    .catch(e => {
                        console.debug("cannot get gsm gateway ports");
                        reject(e)
                    })
                } else {
                    const e = new Error('cannot get gsm gateway ports (gateway required)');
                    console.debug(e);
                    reject(e)
                }
            })
        },
    }
}