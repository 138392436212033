<template>
  <v-edit-dialog
    :return-value="$v.newComment.$model"
    @cancel="reset"
    @open="reset"
    @save="save"
    :save-text="$t('text.SIM_save_comment')"
  >
    <span class="d-flex">
      <span
        v-if="newComment"
        class="mr-1"
      >{{ newComment }}</span>
      <span class="b-icon">
        <v-icon small>mdi-pencil</v-icon>
      </span>
    </span>
    <template v-slot:input>
      <v-text-field
        v-model="$v.newComment.$model"
        :label="$t('text.Comment')"
        counter
        :maxlength="max"
        :loading="updating"
        clearable
      ></v-text-field>
    </template>
  </v-edit-dialog>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { POSITION } from 'vue-toastification';
import mSimCards from '../../../mixins/customer/mSimCards';

const MAX = 20;

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 8,
  timeout: 10000,
};

export default {
  name: 'SimComment',
  mixins: [ mSimCards],
  props: {
    sim_imsi: {
      type: String,
      default: '',
      required: true
    },
    comment: {
      type: String,
      default: '',
      required: true
    },
  },
  data() {
    return {
      updating: false,
      newComment: '',
      updatedComment: undefined,
      max: MAX,
    };
  },
  validations: {
    newComment: {},
  },
  created() {
    this.reset();
  },
  watch: {
    newComment(newValue) {
      if (newValue === null) {
        this.newComment = '';
      }
    },
  },
  methods: {
    reset() {
      this.newComment = typeof this.updatedComment !== 'undefined'
        ? this.updatedComment
        : this.comment && this.comment.toLowerCase() !== 'unknown'
        ? this.comment
        : '';
    },
    save() {
      const onError = () => {
        this.newComment = this.comment;
        this.$toast(this.$t('text.sl_sim_comment_not_updated'), {
          ...toastOptions,
          type: 'error',
        });
      };

      // Save new value if it is validated.
      if (this.$v.newComment.$error) {
        onError();
      }

      this.updating = true;
      this.setCustomerSimCardComment(this.sim_imsi, this.newComment)
        .then(() => (this.updatedComment = this.newComment))
        .catch(onError)
        .finally(() => this.updating = false);
    },
  },
};
</script>
