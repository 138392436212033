<template>
  <v-card
    :loading="elementLoading"
  >
    <v-card-text class="b-item__inner">
      <div class="b-item__title">
        <div class="_no-wrap">
          <small class="ml-2">{{ item.port_index }}</small>
        </div>
        <div class="_no-wrap">
          <GsmPortSignal v-bind="{signal: item['port_signal'], sim_imsi: item.sim['imsi'], small: true}" />
          <small class="ml-1">{{ item.port_signal }}</small>
        </div>
        <div>
          <MobileOperatorIcon
            v-if="item.sim['mobile_operator_name']"
            :icon-name="item.sim['mobile_operator_name']"
            :width="86"
            :height="38"
            class="b-operator-icon"
          />
        </div>
      </div>
      <div
        v-if="showVoipState"
        class="b-item__second"
        :style="portVoipState.style"
      >
        <div style="justify-content: unset;">
        <div class="b-item__port-voip-state">
          <v-icon
            small
            :color="portVoipState.color || 'gray'"
          >
            mdi-{{ portVoipState.icon }}
          </v-icon>
        </div>
        <div class="b-item__port-voip-state">
          <v-icon v-if="typeof item.sim !== 'undefined' && item.sim['locked_for_sms']"
            small
            color="red"
          >
            mdi-email-lock
          </v-icon>
        </div>
        </div>
        <div class="b-item__port-display-num">
          {{ item.port_display_num }}
        </div>
      </div>
      <div
        v-else
        class="b-item__second"
      />
      <v-divider class="mt-2" />
      <div class="b-item__meta">
        <!--
        <div>
          <v-icon small v-if="item.sim && item.sim['imsi'] !== ''">mdi-sim</v-icon>
          <v-icon small v-else>mdi-sim-off</v-icon>
        </div>
        -->
        <!-- fill="#90ee90" -->
        <IndicatorsIcons
          icon-name="voip"
          :fill="iconStyleVoipStatus"
        />
        <IndicatorsIcons
          icon-name="sms"
          :fill="iconStyleSMS"
        />
        <IndicatorsIcons
          icon-name="smb"
          :fill="iconStyleSMB"
        />
        <div>
          <template v-if="!item.sim.balance">
            NaN
          </template>
          <template v-else>
            {{ item.sim.balance }} {{ item.sim['balance_currency'] }}
          </template>
        </div>
      </div>
    </v-card-text>

    <div v-if="showMoveBtn">
      <v-btn
        v-if="!loading"
        class="b-item__button"
        absolute
        dark
        fab
        top
        right
        x-small
        @click="toggle"
      >
        <v-icon
          v-if="active"
          color="red"
        >
          mdi-minus-thick
        </v-icon>
        <v-icon
          v-else
          color="success"
        >
          mdi-plus-thick
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import GsmPortSignal from './GsmPortSignal';
  import IndicatorsIcons from './IndicatorsIcons';
  import MobileOperatorIcon from './MobileOperatorIcon'
  import imagesPath from '../../mixins/imagesPath';
  import { operators } from '../../data/operators';

  export default {
    components: {GsmPortSignal, IndicatorsIcons, MobileOperatorIcon},
    mixins: [
      imagesPath,
    ],
    props: {
      // eslint-disable-next-line vue/require-default-prop
      item: Object,
      active: Boolean,
      showVoipState: {
        type: Boolean,
        default: false
      },
      showMoveBtn: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        elementLoading: false,
      };
    },
    computed: {
      ...mapState('groups', {
        status: state => state.status,
      }),
      loading() {
        return this.status === 'loading';
      },
      operator() {
        if (this.item.sim) {
          const name = this.item.sim['mobile_operator_name']
              ? this.item.sim['mobile_operator_name'].toLowerCase().replace(/\s/g, '')
              : undefined;
          const obj = operators[name];
          if (!obj) {
            return false;
          }
          return {
            ...obj,
            icon: process.env.VUE_APP_ROOT_DIR + obj.icon,
          };
        }
        return false;
      },
      iconStyleSMS() {
        let color = 'gray';
        if (this.item.sim) {
          if (this.item.sim['imsi'] && this.item.sim['imsi'] !== '' && this.item.sms_login) {
            color = 'green';
          } else if (this.item.sim['imsi'] && this.item.sim['imsi'] !== '' && !this.item.sms_login) {
            color = 'red';
          }
        }
        return color;
      },
      iconStyleSMB() {
        return this.item.smb_login ? 'green' : 'gray';
      },
      iconStyleVoipStatus() {
        let color = 'gray';
        if (this.item.port_voip_status === 'LOGIN') {
          color = 'green';
        }
        return color;
      },
      portVoipState() {
        let bg;
        let icon;
        let color;
        const {
          port_gsm_status,
          port_voip_state,
          sim,
        } = this.item;
        switch (true) {
          case port_gsm_status === 'LOADING':
            bg = '#C8E6C9';
            icon = 'timer-sand';
          case typeof sim === 'undefined' || !sim.imsi || sim.imsi === '':
            bg = '#dfdfdf';
            icon = 'phone-hangup-outline';
            break;
          case port_voip_state === 'LOADING':
            bg = '#ffffe0';
            icon = 'reload';
            break;
          case port_voip_state === 'DIALING':
            bg = '#add8e6';
            icon = 'phone-settings';
            break;
          case port_voip_state === 'ALERTING':
            bg = '#87cefa';
            icon = 'phone-outgoing';
            break;
          case port_voip_state === 'CONNECTED':
            bg = '#e63244';
            icon = 'phone-in-talk';
            break;
          case port_voip_state === 'ACTIVE':
            bg = '#876c99';
            icon = 'phone-alert';
            break;
          case port_voip_state === 'INCOMING':
            bg = '#ffa161';
            icon = 'phone-incoming';
            break;
          case port_voip_state === 'IDLE':
            bg = '#90ee90';
            icon = 'phone-hangup';
            break;
          default:
            bg = '#808080';
            icon = 'phone-hangup-outline';
            break;
        }

        if (typeof sim !== 'undefined' && sim['locked_for_calls']) {
          icon = 'phone-lock';
          color = 'red';
        }

        return {
          style: {
            backgroundColor: bg,
          },
          icon,
          color,
        };
      },
    },
    methods: {
      ...mapActions('groups', [
        'togglePort',
      ]),
      async toggle() {
        this.elementLoading = true;
        try {
          await this.togglePort({
            port_index: this.item.port_index,
            active: this.active,
            gateway: this.item.gateway,
          });
        } catch (e) {

        }
        this.elementLoading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .b-item__inner {
    min-height: 124px;
    display: flex;
    flex-direction: column;
  }

  .b-item__title {
    display: flex;
    align-items: flex-start;
    min-height: 32px;
    margin-bottom: 8px;

    span {
      font-family: monospace;
      font-size: 20px;
      margin-left: 8px;
    }

    > * {
      display: flex;
      align-items: center;
      min-height: 25px;

      span {
        transform: translateY(-2px);
      }

      &:last-child {
        flex: 1 1 auto;
        margin-left: 8px;
      }
    }
  }

  .b-item__second {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 4px;
    border-radius: 3px;
  }

  .b-item__port-voip-state {
    margin-right: 4px;
    display:inline-block
  }

  .b-item__port-display-num {
    font-size: 12px;
  }

  .b-item__meta {
    display: flex;
    align-items: center;
    min-height: 48px;
    flex-wrap: wrap;
    margin: -8px -1px -18px;

    > * {
      // margin: 1px;

      &:last-child {
        flex: 1 1 auto;
        text-align: right;
        padding-top: 2px;
      }
    }
  }

  .b-operator-icon {
    position: absolute;
    right: 0;
    margin-right: 14px;
    margin-top: 6px;
  }

  .v-card__text, .v-card__title {
    padding: 10px;
  }
</style>
