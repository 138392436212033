<template>
  <div class="ussd-response-notify">
    <h4>{{ title }}</h4>
    <span v-html="rawHtml"></span>
  </div>
</template>

<script>
    export default {
        name: "UssdResponseNotify",
        props: {
            title: {
                type: String,
                default: ""
            },
            text: {
                type: Array,
                default: () => []
            }
        },
      computed: {
        // Путь расположения SVG-файла
        rawHtml() {
          let html = ``;
          if (Array.isArray(this.text)) {
            this.text.forEach(function(value, i, array) {
              if (i !== array.length - 1) { // не последний элемент массива
                html += value + `<br><br>`
              } else {
                html += value
              }
            })
          } else {
            html = this.text;
          }
          return html
        },
      }
    }
</script>

<style scoped lang="scss">
  .ussd-response-notify {
    text-align: left;
  }
</style>