<template>
  <v-layout
    row
    wrap
    align-center
  >
    <v-card
      class="mb-3"
      width="100%"
    >
      <v-data-table
        :headers="columns"
        :items="gateways"
        item-key="username"
        :sort-by="['alias']"
        :hide-default-footer="true"
        dense
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <!-- add new gsm gateway dialog -->
            <v-dialog
              v-model="dialogNew"
              max-width="500px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  {{ $t('text.Add') }} {{ $t('text.GSM_Gateway') }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">
                    {{ $t('text.gw_settings_title') }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="gateway.alias"
                          :label="$t('text.gw_alias')"
                          prepend-icon="mdi-pencil"
                          type="text"
                          :error-messages="verr.alias"
                          @input="clear_AliasErr"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model.trim="gateway.username"
                          :label="$t('text.gw_login')"
                          prepend-icon="person"
                          type="text"
                          :error-messages="verr.username"
                          @input="clear_UsernameErr"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model.trim="gateway.password"
                          :label="$t('text.gw_password')"
                          prepend-icon="lock"
                          type="text"
                          :error-messages="verr.password"
                          @input="clear_PasswordErr"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="dialog_NewGsmGateway_close">{{ $t('text.Cancel') }}</v-btn>
                  <v-btn color="blue darken-1" text @click="save">{{ $t('text.Save') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- remove gsm gateway dialog -->
            <v-dialog v-model="dialogDelete" max-width="800px">
              <v-card>
                <v-card-title class="headline">{{ gateway.username }}: {{ $t('text.gw_delete_gw_dialog') }} </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog_DelGsmGateway_close">{{ $t('text.No') }}</v-btn>
                  <v-btn color="blue darken-1" text @click="remove">{{ $t('text.Yes') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.alias`]="props">
          <GsmGatewayInput
            class="mr-2"
            :item="props.item"
            option="alias"
            editable
          />
        </template>

        <template v-slot:[`item.password`]="{ item }">
          <GsmGatewayInput
            class="mr-2"
            :item="item"
            option="password"
            field-type="password"
            editable
            show-toggle-visibility-icon
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            @click="dialog_DelGsmGateway_open(item)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-snackbar
        v-model="snack"
        :timeout="3000"
        :color="snackColor"
      >
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            small
            fab
            text
            @click="snack = false"
          >
            <v-icon dark>
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
  import GsmGatewayInput from './elements/GsmGateways/GsmGatewayInput';

    export default {
      name: "GsmGateways",
      components: {GsmGatewayInput},
      data: function () {
          return {
            gateway: {},
            editableGateway: {},
            showPasswordComplexity: true,

            dialogNew: false,
            dialogDelete: false,

            verr: {
              alias: '',
              username: '',
              password: '',
              save: '',
            },

            max25chars: v => v.length <= 15 || 'Input too long!',
            snack: false,
            snackColor: '',
            snackText: '',
          }
      },
      // @TODO
      // 1) ограничить минимальную длинну полей username и password
      // 2) запретить ввод символов вида "/", "?", " " и т.д. в полях username и password
      validations: {
        gateway: {
          alias: { required },
          username: { required, minLength: minLength(5) },
          password: { required, minLength: minLength(12) }
        }
      },
      computed: {
        ...mapState("gsm_gateways", {
          gateways: state => state.gateways,
        }),
        columns() {
            return [
                {text: this.$t('text.gw_login'), value: 'username', align: 'left', sortable: false},
                {text: this.$t('text.gw_password'), value: 'password', align: 'left', sortable: false},
                {text: this.$t('text.gw_alias'), value: 'alias', align: 'left', sortable: false},
                {text: this.$t('text.gw_serial_number'), value: 'sn', align: 'left', sortable: false},
                {text: this.$t('text.gw_sip_ip_port'), value: 'voip_net_ip_port', align: 'left', sortable: false},
                {text: this.$t('text.Actions'), value: 'actions', align: 'right', sortable: false},
            ];
        },
        progress () {
            return Math.min(12, this.password.length)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 4)]
        },
      },
      mounted () {
          this.getGsmGateways()
      },
      methods: {
        ...mapActions("gsm_gateways", ["newGsmGateway", "getGsmGateways", "delGsmGateway"]),
        // actions
        async save() {
          if (!this.validFields()) {
            return
          }

          await this.newGsmGateway(this.gateway);
          await this.getGsmGateways();
          this.dialog_NewGsmGateway_close();
        },
        async remove() {
          await this.delGsmGateway(this.gateway);
          await this.getGsmGateways();
          this.dialog_DelGsmGateway_close()
        },
        // dialogs
        dialog_NewGsmGateway_close() {
          this.dialogNew = false;
          this.gateway = {};
        },
        dialog_DelGsmGateway_open(gw) {
          this.gateway = gw;
          this.dialogDelete = true;
        },
        dialog_DelGsmGateway_close() {
          this.dialogDelete = false;
          this.gateway = {};
        },
        // forms validation
        clear_AliasErr() {
          this.verr.save = '';
          this.verr.alias = '';
        },
        clear_UsernameErr() {
          this.verr.save = '';
          this.verr.username = '';
        },
        clear_PasswordErr() {
          this.verr.save = '';
          this.verr.password = '';
        },
        validFields(){
          this.$v.gateway.$touch();
          if (!this.$v.gateway.alias.required) {
            this.verr.alias = this.$t('text.is_required_field');
            return false
          }

          if (!this.$v.gateway.username.required) {
            this.verr.username = this.$t('text.is_required_field');
            return false
          }

          if (!this.$v.gateway.password.required) {
            this.verr.password = this.$t('text.is_required_field');
            return false
          }

          return true
        },
      }
    }
</script>

<style scoped>

</style>
